#formTrainingsGroupBasicInformationBox {
    #formTrainingsGroupBasicInformation {
        #formTrainingsGroupBasicInformationFirstRow {
            display: flex;
            flex-flow: row wrap;
            align-items: flex-end;
            .providerSelect {
                width: 22%;
                margin-right: 20px;
            }
            .termTypeSelect {
                width: 10%;
                margin-right: 20px;
            }
            .dateTrainings {
                display: flex;
                flex-flow: row wrap;
                margin-top: 5px;
                margin-bottom: 5px;
                width: 20%;
                margin-right: 10px;
                .labelBox {
                    width: 100%;
                    color: #1D4370;
                    margin-bottom: .5rem;
                }
                .dateBox {
                    width: 100%;
                    display: flex;
                    flex-flow: row nowrap;
                }
            }
            .dateSingleTrainings {
                display: flex;
                flex-flow: row wrap;
                margin-top: 5px;
                margin-bottom: 5px;
                width: 10%;
                margin-right: 10px;
                .labelBox {
                    width: 100%;
                    color: #1D4370;
                    margin-bottom: .5rem;
                }
                .dateSingleBox {
                    width: 100%;
                    display: flex;
                    flex-flow: row nowrap;
                    .blockDateWithError {
                        .blockDate {
                            padding-left: 0;
                        }
                    }
                }
            }
            .trainingFormSelect {
                width: 10%;
                margin-right: 20px;
            }
            .minPeopleBox {
                width: 215px;
                margin-right: 20px;
                .blockNumberWithError {
                    .blockNumber {
                        padding: 0;
                        margin: 5px 0;
                        .blockNumberWithInfo {
                            width: 70px;
                            input {
                                text-align: right;
                            }
                        }
                    }
                }
            }
            .maxPeopleBox {
                width: 230px;
                margin-right: 20px;
                .blockNumberWithError {
                    .blockNumber {
                        padding: 0;
                        margin: 5px 0;
                        .blockNumberWithInfo {
                            width: 70px;
                            input {
                                text-align: right;
                            }
                        }
                    }
                }
            }
        }
        #formTrainingsGroupBasicInformationSecondRow {
            display: flex;
            flex-flow: row wrap;
            align-items: flex-end;
            .contactCompanySelect {
                width: 20%;
                margin-right: 20px;
            }
            .contactProviderSelect {
                width: 20%;
                margin-right: 20px;
            }
            .settlementTypeSelect {
                width: 15%;
                margin-right: 20px;
            }
            .settlementAmountBox {
                width: 10%;
                display: flex;
                flex-flow: row nowrap;
                align-items: center;
                span {
                    margin-left: 4px;
                    font-weight: 400;
                    color: #555555;
                }
            }
            .placeBox {
                width: 100%;
            }
        }
    }
}

[data-theme='dark'] #formTrainingsGroupBasicInformationBox {
    #formTrainingsGroupBasicInformation {
        #formTrainingsGroupBasicInformationFirstRow {
            .dateTrainings {
                .labelBox {
                    color: #FFF;
                }
            }
            .dateSingleTrainings {
                .labelBox {
                    color: #FFF;
                }
            }
        }
        #formTrainingsGroupBasicInformationSecondRow {
            .settlementAmountBox {
                span {
                    color: #555555;
                }
            }
        }
    }
}