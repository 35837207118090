@import './../../../scss/variableCustom.scss';
.questionYesNoPartlyBox {
    display: flex;
    flex-flow: row;
    border-bottom: 1px solid #EDEDED;
    padding: 4px 10px 4px 20px;
    &:hover {
        background-color: #F1F6F5;
    }
    .questionYesNoPartlyNumber {
        width: 30px;
        text-align: center;
        align-items: center;
        display: flex;
        color: #618299;
    }
    .questionYesNoPartlyName {
        display: flex;
        flex-grow: 1;
        align-items: center;
        color: #618299;
    }
    .questionYesNoPartlyAnswerBox {
        display: flex;
        flex-flow: row nowrap;
        .status {
            margin: 0 2px;
            display: flex;
            flex-flow: row wrap;
            align-items: flex-end;
            width: 55px;
            .labelStatus {
                width: 100%;
                display: flex;
                flex-flow: row nowrap;
                color: #555;
                font-size: 0.75rem;
                justify-content: center;
                margin-bottom: 3px;
                text-align: center;
                flex-grow: 1;
                align-items: center;
                .number {
                    font-weight: 500;
                }
            }
            .boxStatus {
                width: 100%;
                display: flex;
                flex-flow: row nowrap;
                justify-content: center;
                padding-bottom: 4px;
                .circleBox {
                    display: flex;
                    width: 25px;
                    height: 25px;
                    border-radius: 13px;
                    justify-content: center;
                    align-items: center;
                    margin: 0 2px;
                    background-color: #F1F6F5;
                    border: 1px solid #93B7E1;
                    &.active {
                        background-color: #1D4370;
                        border: 1px solid #1D4370;
                    }
                    path {
                        fill: #fff;
                    }
                }
            }
        }
    }
}

.questionYesNoPartlySubstantiationBox {
    padding-left: 30px;
    padding-right: 10px;
    border-bottom: 1px solid #EDEDED;
    .editorBlock {
        .form-label {
            font-weight: 600;
            color: #1D4370;
        }
    }
}