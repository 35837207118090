.editorBlock {
    &.withLabel {
        margin-right: calc(0.65rem + 18px);
        margin-top: 5px;
        margin-bottom: 5px;
        .form-label {
            color: #1D4370;
        }
    }
    &.noInfoPlace {
        margin-right: 0 !important;
    }
    &.error {
        .additionalInfo {
            .alertInfo {
                color: #BF3E3E;
            }
        }
        .tox-tinymce {
            border: 1px solid #BF3E3E;
        }
    }
}

[data-theme='dark'] .editorBlock {
    &.withLabel {
        .form-label {
            color: #fff;
        }
    }
}

.mce-content-body {
    &.shortEditor {
        margin: 0;
        font-size: 0.875rem;
    }
}