#boxContent-previewTrainingGroupBasicInformationBox {
    #previewTrainingGroupBasicInformationContentFirst {
        display: flex;
        flex-flow: row nowrap;
        #previewTrainingGroupBasicInformationContentFirstColOne {
            width: 50%;
            padding-left: 10px;
        }
        #previewTrainingGroupBasicInformationContentFirstColTwo {
            width: 30%;
        }
        #previewTrainingGroupBasicInformationContentFirstColThree {
            width: 20%;
            display: flex;
            flex-flow: column;
            justify-content: center;
            align-items: center;
            margin-top: 0.75rem;
            #previewTrainingGroupBasicInformationContentFirstColThreeLabel {
                font-weight: 600;
                color: #1D4370;
            }
            #previewTrainingGroupBasicInformationContentFirstColThreeData {
                color: #555555;
                font-size: 1.5rem;
                span {
                    font-size: 48px;
                    font-weight: 700;
                    color: #1D4370;
                }
            }
        }
    }
    #previewTrainingGroupBasicInformationContentSecond {
        #previewTrainingGroupBasicInformationContentSecondDiv {
            padding-left: 10px;
            margin-bottom: 10px;
        }
    }
}

[data-theme='dark'] #boxContent-previewTrainingGroupBasicInformationBox {
    #previewTrainingGroupBasicInformationContentFirst {
        #previewTrainingGroupBasicInformationContentFirstColThree {
            #previewTrainingGroupBasicInformationContentFirstColThreeLabel {
                color: #A0A0C2;
            }
            #previewTrainingGroupBasicInformationContentFirstColThreeData {
                color: #555555;
                span {
                    color: #1D4370;
                }
            }
        }
    }
}