.submitBlock {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    padding: 1.188rem 0;
    button {
        margin-right: 5px;
        margin-left: 5px;
    }
}

[data-theme='dark'] .submitBlock {
    background-color: #2C2D44;
}