#formGoalRating {
    #formGoalRatingBox {
        .boxContent {
            padding: 0 0 0 20px;
        }
    }
}

[data-theme='dark'] #formGoalRating {
    #formGoalRatingBox {}
}