#questionnaireSummaryBox {
    .loaderwrapper {
        #questionnaireHeader {
            display: flex;
            flex-flow: row wrap;
            padding: 10px 20px;
            color: #555;
            .basicInfoBox {
                flex-grow: 1;
                #questionnaireHeaderLabel {
                    width: 100%;
                }
                #questionnaireHeaderValue {
                    font-size: 1.5rem;
                    color: #FF8B02;
                    width: 100%;
                }
            }
            .additionalInfoBox {
                display: flex;
                flex-flow: row wrap;
                .aditionalInfoContent {
                    display: flex;
                    flex-flow: row wrap;
                    max-width: 280px;
                    max-height: 100px;
                    margin-left: 10px;
                    margin-right: 10px;
                    &#validationDate {
                        max-width: 200px;
                    }
                    .content {
                        width: 100%;
                        display: flex;
                        flex-flow: column;
                        .title {
                            margin-bottom: 5px;
                        }
                        .additionalInfoDetail {
                            display: flex;
                            flex-flow: row nowrap;
                            div {
                                width: 100%;
                                color: #1D4370;
                                &.data {
                                    color: #1D4370;
                                    font-size: 3rem;
                                    font-weight: 600;
                                }
                                &.icon {
                                    margin-right: 20px;
                                }
                            }
                        }
                    }
                }
            }
        }
        #questionnaireBody {
            #questionnaireBodyHead {
                display: flex;
                flex-flow: row nowrap;
                background-color: #ECF6F3;
                padding: 10px 20px;
                font-weight: 600;
                border-top: 1px solid #DCDCDC;
                border-bottom: 1px solid #DCDCDC;
                #questionnaireBodyHeadRight {
                    flex-grow: 1;
                }
                #questionnaireBodyHeadRight {
                    text-align: right;
                }
            }
            #questionnaireBodyContent {
                .group {
                    margin: 10px 0;
                    .groupLabel {
                        padding-left: 10px;
                        color: #1D4370;
                        font-weight: 600;
                    }
                }
            }
            #questionnaireBodySummary {
                margin-top: 20px;
                #questionnaireBodySummaryCommentsBox {
                    margin: 0 12px;
                    .editorBlock {
                        label {
                            color: #1D4370;
                            font-weight: 600;
                        }
                    }
                }
            }
        }
    }
}

[data-theme='dark'] #questionnaireSummaryBox {
    .loaderwrapper {
        #questionnaireHeader {
            color: #A0A0C2;
            .basicInfoBox {
                #questionnaireHeaderValue {
                    color: #FF8B02;
                }
            }
            .additionalInfoBox {
                .aditionalInfoContent {
                    .content {
                        .additionalInfoDetail {
                            div {
                                width: 100%;
                                color: #1D4370;
                                &.data {
                                    color: #1D4370;
                                    font-size: 3rem;
                                    font-weight: 600;
                                }
                                &.icon {
                                    svg {
                                        path {
                                            fill: #A0A0C2;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        #questionnaireBody {
            #questionnaireBodyHead {
                background-color: #1E202D;
                border-top: 1px solid #494B6D;
                border-bottom: 1px solid #494B6D;
            }
            #questionnaireBodyContent {
                .group {
                    .groupLabel {
                        color: #1D4370;
                    }
                }
            }
            #questionnaireBodySummary {
                #questionnaireBodySummaryCommentsBox {
                    .editorBlock {
                        label {
                            color: #1D4370;
                        }
                    }
                }
            }
        }
    }
}