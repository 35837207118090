.modal-backdrop {
    &.lvl2 {
        z-index: 1051;
    }
    &.lvl3 {
        z-index: 1061;
    }
    &.lvl4 {
        z-index: 1071;
    }
    &.lvl5 {
        z-index: 1081;
    }

}

.modal {
    &.lvl2 {
        z-index: 1060;
    }
    &.lvl3 {
        z-index: 1070;
    }
    &.lvl4 {
        z-index: 1080;
    }
    &.lvl5 {
        z-index: 1090;
    }
}

.modal {
    &.low400 {
        .modal-dialog {
            max-width: 400px;
        }
    }
    &.low {
        .modal-dialog {
            max-width: 500px;
        }
    }
    &.medium {
        .modal-dialog {
            max-width: 760px;
        }
    }
    .modal-dialog {
        max-width: 1000px;
        .modal-content {
            border-radius: 10px 10px 0 0;
            border: 1px solid #93B7E1;
            .closeButton {
                position: absolute;
                right: 16px;
                top: 8px;
                z-index: 2000;
            }
        }
        &[data-theme='dark'] {
            .modal-content {
                border-radius: 10px 10px 0 0;
                border: 1px solid #494B6D;
                background-color: #2C2D44;
            }
        }
    }
}