.showUl {
    margin-top: 0.75rem;
    .label {
        width: 100%;
        color: #1D4370;
        font-weight: 600;
    }
    .data {
        width: 100%;
        color: #1D4370;
    }
    &.inLine {
        display: flex;
        flex-flow: column;
        justify-content: flex-start;
        .data {
            width: 100%;
            color: #1D4370;
            ul {
                margin-top: 0.75rem;
                display: flex;
                margin-left: 0;
                padding-left: 0;
                margin-bottom: 0.625rem;
                li {
                    margin-left: 1rem;
                    margin-right: 1.5625rem;
                    &::marker {
                        color: #FF8B02;
                    }
                }
            }
        }
    }
}

[data-theme='dark'] .showUl {
    .label {
        color: #A0A0C2
    }
    .data {
        color: #A0A0C2;
    }
    &.inLine {
        .data {
            color: #fff;
            ul {
                li {
                    &::marker {
                        color: #FF8B02;
                    }
                }
            }
        }
    }
}