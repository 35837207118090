#EmployeeAppraisalGoalRatingBox {
    #EmployeeAppraisalGoalRatingBoxFirstRow {
        #EmployeeAppraisalGoalRatingBoxFirstRowBox {
            display: flex;
            flex-flow: row nowrap;
            padding: 12px 15px 0;
            #EmployeeAppraisalGoalRatingBoxFirstRowFirstColumnBox {
                display: flex;
                flex-flow: row nowrap;
                flex-grow: 1;
                #EmployeeAppraisalGoalRatingBoxFirstRowBoxIcon {
                    margin-right: 20px;
                    justify-content: center;
                    align-items: center;
                    display: flex;
                    svg {
                        width: 60px !important;
                        height: 60px !important;
                        path {
                            fill: #7FA3D0;
                        }
                    }
                }
                #EmployeeAppraisalGoalRatingBoxFirstRowBoxInfo {
                    #EmployeeAppraisalGoalRatingBoxFirstRowBoxInfoLabel {
                        font-size: 1.125rem;
                        font-weight: 700;
                        margin-bottom: 6px
                    }
                }
            }
            #EmployeeAppraisalGoalRatingBoxFirstRowSecondColumnBox {
                display: flex;
                flex-flow: row nowrap;
                .ratingGoal {
                    max-width: 221px;
                    padding-bottom: 10px;
                    margin-bottom: 10px;
                    &#EmployeeAppraisalGoalRatingBoxFirstRowSecondColumnRatingEmployeeBox {
                        padding-right: 20px;
                        border-right: 1px solid #DCDCDC;
                        margin-right: 20px;
                    }
                    &#EmployeeAppraisalGoalRatingBoxFirstRowSecondColumnRatingManagerBox {
                        padding-right: 20px;
                        border-right: 1px solid #DCDCDC;
                        margin-right: 20px;
                    }
                    .label {
                        font-weight: 600;
                        font-size: 1.125rem;
                        color: #1D4370;
                        margin-bottom: 6px;
                    }
                    .data {
                        .dataEdit {
                            display: flex;
                            .buttonAction {
                                margin-right: 10px;
                                min-width: 32px;
                            }
                        }
                    }
                }
            }
        }
    }
    #EmployeeAppraisalGoalRatingBoxSecondRow {
        padding: 20px 10px;
        #EmployeeAppraisalGoalRatingAdd {
            margin-right: 5px;
        }
    }
}

[data-theme='dark'] #EmployeeAppraisalGoalRatingBox {
    #EmployeeAppraisalGoalRatingBoxFirstRow {
        #EmployeeAppraisalGoalRatingBoxFirstRowBox {
            #EmployeeAppraisalGoalRatingBoxFirstRowBoxIcon {
                svg {
                    path {
                        fill: #A0A0C2;
                    }
                }
            }
            #EmployeeAppraisalGoalRatingBoxFirstRowBoxInfo {
                color: #A0A0C2;
            }
        }
    }
    #EmployeeAppraisalGoalRatingBoxSecondRow {
        padding: 20px 10px;
        #EmployeeAppraisalGoalRatingAdd {
            margin-right: 5px;
        }
    }
}

#approveIDP {
    text-align: center;
    padding: 20px 0;
}