@import './../../../../scss/variableCustom.scss';
.tableCellBlockTypeTraining {
    &.toLeft {
        * {
            text-align: left;
        }
    }
    &.center {
        * {
            text-align: center;
        }
    }
    &.toRight {
        * {
            text-align: right;
        }
    }
    .contactName {
        font-weight: $fontBold;
    }
}