#IDPEmployeeAreaBox {
    display: flex;
    flex-flow: column;
    padding-bottom: 10px;
    .IDPEmployeeAreaBoxItem {
        display: flex;
        flex-flow: row;
        align-items: flex-start;
        justify-content: flex-start;
        margin-top: 10px;
        margin-left: 10px;
        margin-right: 20px;
        .IDPEmployeeAreaBoxItemNumber {
            width: 5%;
            color: #1D4370;
            font-size: 3rem;
            display: flex;
            align-items: flex-start;
            align-items: center;
            min-width: 55px;
            flex-flow: column;
        }
        .IDPEmployeeAreaBoxItemBlock {
            display: flex;
            flex-flow: row wrap;
            flex-grow: 1;
            border-radius: 10px;
            border: 1px solid #DCDCDC;
            .IDPEmployeeAreaBoxItemBlockTitle {
                display: flex;
                flex-flow: row;
                background-color: #ECF6F3;
                width: 100%;
                border-radius: 10px 10px 0 0;
                border-bottom: 1px solid #DCDCDC;
                align-items: center;
                .IDPEmployeeAreaBoxItemBlockTitleArea {
                    width: 20%;
                    font-weight: 700;
                    color: #1D4370;
                    padding: 12px 10px;
                    line-height: 1.05rem;
                    min-width: 80px;
                }
                .IDPEmployeeAreaBoxItemBlockTitleAdditional {
                    display: flex;
                    flex-flow: row;
                    width: 100%;
                    font-weight: 700;
                    color: #1D4370;
                    padding: 12px 0;
                    line-height: 1.05rem;
                    .IDPEmployeeAreaBoxItemBlockTitleActivities {
                        width: 45%;
                        padding: 0 5px;
                        min-width: 155px;
                    }
                    .IDPEmployeeAreaBoxItemBlockTitleTerm {
                        width: 10%;
                        padding: 0 5px;
                        min-width: 75px;
                    }
                    .IDPEmployeeAreaBoxItemBlockTitleMeasure {
                        width: 20%;
                        padding: 0 5px;
                    }
                    .IDPEmployeeAreaBoxItemBlockTitleProgress {
                        width: 15%;
                        padding: 0 5px;
                        min-width: 86px;
                    }
                    .IDPEmployeeAreaBoxItemBlockTitleComment {
                        width: 10%;
                        padding: 0 5px;
                        min-width: 80px;
                    }
                }
            }
            .IDPEmployeeAreaBoxItemBlockData {
                display: flex;
                flex-flow: row;
                width: 100%;
                border-radius: 0 0 10px 10px;
                .IDPEmployeeAreaBoxItemBlockDataArea {
                    width: 20%;
                    color: #555;
                    padding: 12px 10px;
                    line-height: 1.05rem;
                    align-items: center;
                    display: flex;
                }
                .IDPEmployeeAreaBoxItemBlockDataAdditional {
                    display: flex;
                    flex-flow: column;
                    width: 100%;
                    .IDPEmployeeAreaBoxItemBlockDataAdditionalRow {
                        display: flex;
                        flex-flow: row;
                        color: #555;
                        line-height: 1.05rem;
                        border-bottom: 1px solid #DCDCDC;
                        width: 100%;
                        padding-left: 5px;
                        &.noBorder {
                            border-bottom: none;
                        }
                        .IDPEmployeeAreaBoxItemBlockDataActivities {
                            width: 45%;
                            .IDPEmployeeAreaBoxItemBlockDataActivitiesItem {
                                display: flex;
                                flex-flow: row;
                                .IDPEmployeeAreaBoxItemBlockDataActivitiesItemIcon {
                                    width: 40px;
                                    height: 40px;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    background-color: green;
                                    color: #fff;
                                    font-weight: 700;
                                    border-radius: 10px;
                                    margin: 2px 12px 2px 0;
                                    &.IDPEmployeeAreaBoxItemBlockDataActivities70Icon {
                                        background-color: #FF8B02;
                                    }
                                    &.IDPEmployeeAreaBoxItemBlockDataActivities20Icon {
                                        background-color: #1D4370;
                                    }
                                    &.IDPEmployeeAreaBoxItemBlockDataActivities10Icon {
                                        background-color: #93B7E1;
                                    }
                                }
                                .IDPEmployeeAreaBoxItemBlockDataActivitiesItemData {
                                    display: flex;
                                    align-items: center;
                                    width: 100%;
                                    padding-right: 5px;
                                    p {
                                        margin: 0;
                                    }
                                }
                            }
                        }
                        .IDPEmployeeAreaBoxItemBlockDataTerm {
                            width: 10%;
                            display: flex;
                            flex-flow: row;
                            align-items: center;
                            font-weight: 700;
                            padding: 0 5px;
                            min-width: 75px;
                        }
                        .IDPEmployeeAreaBoxItemBlockDataMeasure {
                            width: 20%;
                            display: flex;
                            flex-flow: row;
                            align-items: center;
                            padding: 0 5px;
                        }
                        .IDPEmployeeAreaBoxItemBlockDataProgress {
                            width: 15%;
                            display: flex;
                            flex-flow: row wrap;
                            align-items: center;
                            padding: 0 5px;
                            .IDPEmployeeAreaBoxItemBlockDataProgressBlock {
                                display: flex;
                                flex-flow: row wrap;
                                align-items: flex-start;
                                width: 100%;
                                .IDPEmployeeAreaBoxItemBlockDataProgressLabel {
                                    width: 100%;
                                    padding-bottom: 3px;
                                }
                                .CustomAnimatedPrograssBarLine {
                                    width: 100%;
                                    .progress {
                                        background-color: #F1F6F5;
                                        border: none;
                                    }
                                }
                                &.IDPEmployeeAreaBoxItemBlockDataProgress70Block {
                                    .CustomAnimatedPrograssBarLine {
                                        width: 100%;
                                        .progress {
                                            .progress-bar {
                                                background-color: #FF8B02;
                                            }
                                        }
                                    }
                                }
                                &.IDPEmployeeAreaBoxItemBlockDataProgress20Block {
                                    .CustomAnimatedPrograssBarLine {
                                        width: 100%;
                                        .progress {
                                            .progress-bar {
                                                background-color: #1D4370;
                                            }
                                        }
                                    }
                                }
                                &.IDPEmployeeAreaBoxItemBlockDataProgress10Block {
                                    .CustomAnimatedPrograssBarLine {
                                        width: 100%;
                                        .progress {
                                            .progress-bar {
                                                background-color: #93B7E1;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        .IDPEmployeeAreaBoxItemBlockDataComment {
                            width: 10%;
                            display: flex;
                            flex-flow: row wrap;
                            align-items: center;
                            justify-content: center;
                            padding: 0 5px;
                            min-width: 80px;
                        }
                    }
                }
            }
        }
    }
}

[data-theme='dark'] #IDPEmployeeAreaBox {
    .IDPEmployeeAreaBoxItem {
        .IDPEmployeeAreaBoxItemNumber {
            color: #1D4370;
        }
        .IDPEmployeeAreaBoxItemBlock {
            border: 1px solid #494B6D;
            .IDPEmployeeAreaBoxItemBlockTitle {
                background-color: #1E202D;
                border-bottom: 1px solid #494B6D;
                .IDPEmployeeAreaBoxItemBlockTitleArea {
                    color: #A0A0C2;
                }
                .IDPEmployeeAreaBoxItemBlockTitleAdditional {
                    color: #A0A0C2;
                }
            }
            .IDPEmployeeAreaBoxItemBlockData {
                .IDPEmployeeAreaBoxItemBlockDataArea {
                    color: #D6D6F1;
                }
                .IDPEmployeeAreaBoxItemBlockDataAdditional {
                    .IDPEmployeeAreaBoxItemBlockDataAdditionalRow {
                        color: #D6D6F1;
                        border-bottom: 1px solid #494B6D;
                        &.noBorder {
                            border-bottom: none;
                        }
                        .IDPEmployeeAreaBoxItemBlockDataActivities {
                            .IDPEmployeeAreaBoxItemBlockDataActivitiesItem {
                                .IDPEmployeeAreaBoxItemBlockDataActivitiesItemIcon {
                                    background-color: green;
                                    color: #fff;
                                    &.IDPEmployeeAreaBoxItemBlockDataActivities70Icon {
                                        background-color: #FF8B02 !important;
                                    }
                                    &.IDPEmployeeAreaBoxItemBlockDataActivities20Icon {
                                        background-color: #1D4370 !important;
                                    }
                                    &.IDPEmployeeAreaBoxItemBlockDataActivities10Icon {
                                        background-color: #93B7E1 !important;
                                    }
                                }
                            }
                        }
                        .IDPEmployeeAreaBoxItemBlockDataProgress {
                            .IDPEmployeeAreaBoxItemBlockDataProgressBlock {
                                .CustomAnimatedPrograssBarLine {
                                    .progress {
                                        background-color: #1E202D;
                                        border: none;
                                    }
                                }
                                &.IDPEmployeeAreaBoxItemBlockDataProgress70Block {
                                    .CustomAnimatedPrograssBarLine {
                                        .progress {
                                            .progress-bar {
                                                background: linear-gradient(90deg, #FF8B02 1.76%, #FF8B02 144.71%);
                                            }
                                        }
                                    }
                                }
                                &.IDPEmployeeAreaBoxItemBlockDataProgress20Block {
                                    .CustomAnimatedPrograssBarLine {
                                        .progress {
                                            .progress-bar {
                                                background: linear-gradient(90deg, #004290 1.76%, #1D4370 144.71%);
                                            }
                                        }
                                    }
                                }
                                &.IDPEmployeeAreaBoxItemBlockDataProgress10Block {
                                    .CustomAnimatedPrograssBarLine {
                                        .progress {
                                            .progress-bar {
                                                background: linear-gradient(90deg, #93B7E1 1.76%, #93B7E1 144.71%);
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}