.showShortName {
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    margin-top: 0.75rem;
    &.bold {
        .data {
            font-weight: 600;
        }
    }
    &.fontMedium {
        .data {
            font-size: 1.5rem;
        }
    }
    .label {
        width: 100%;
        color: #1D4370
    }
    .data {
        padding-left: 0.625rem;
        width: 100%;
        font-size: 1.125rem;
        color: #FF8B02;
    }
}