#formEmployeeAppraisalSubordinatesGoalBox {
    .boxContent {
        .customContainer {
            #formEmployeeAppraisalSubordinatesGoalBasicInfo {
                border-bottom: 1px solid #DCDCDC;
                #goalFromTheSupervisorLabel {
                    color: #FF8B02;
                    font-weight: 600;
                    margin: 10px 20px;
                }
                #goalFromTheSupervisorData {
                    margin-top: 0;
                    .data {
                        padding: 0 20px 10px;
                        color: #618299;
                    }
                }
            }
            .formEmployeeAppraisalSubordinatesGoal {
                margin-top: 10px;
                .editorBlock {
                    margin: 0 20px;
                }
            }
        }
    }
}