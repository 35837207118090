@import './../../scss/variableCustom.scss';
// Use variable

/*
$label-colour: #bbb;
$disabled-colour: #ddd;
$toggle-colour: #2f855a;
$white: #fff;
$focus-color: #ff0;
$colorNo: #fff;
$colorNoDark: #fff;
$backgroudColorNo: #bf3e3e;
$backgroundColorNoDark: #bf3e3e;

*/

.tabsHookBox {
    display: flex;
    flex-flow: row;
    width: 100%;
    margin-bottom: 8px;
    padding: 0 10px;
    &.marginTop {
        margin-top: 10px;
    }
    .tabsBoxItem {
        min-width: 180px;
        margin-right: 5px;
        min-height: 30px;
        background-color: #93B7E1;
        color: rgba(7, 36, 75, 0.5);
        border-radius: 20px 20px 0px 0px;
        display: flex;
        flex-flow: row;
        font-weight: 600;
        justify-content: center;
        align-items: center;
        font-size: 13px;
        padding: 0 14px;
        &.active {
            background-color: #FF8B02;
            color: #fff;
            svg {
                path {
                    fill: #fff;
                }
            }
        }
        svg {
            margin-right: 10px;
            path {
                fill: rgba(7, 36, 75, 0.5);
            }
        }
    }
}

[data-theme='dark'] .tabsHookBox {
    .tabsBoxItem {
        background-color: #333952;
        color: #A0A0C2;
        &.active {
            background-color: #FF8B02;
            color: #fff;
            svg {
                path {
                    fill: #fff;
                }
            }
        }
        svg {
            path {
                fill: #A0A0C2;
            }
        }
    }
}