#formIDPEmployeeBasicInformationBox {
    #formIDPEmployeeBasicInformation {
        #formIDPEmployeeBasicInformationFirstRow {
            #formIDPEmployeeBasicInformationFirstRowBox {
                display: flex;
                flex-flow: row nowrap;
                padding: 12px 15px 0;
                #formIDPEmployeeBasicInformationFirstRowBoxIcon {
                    margin-right: 20px;
                    justify-content: center;
                    align-items: center;
                    display: flex;
                }
                #formIDPEmployeeBasicInformationFirstRowBoxInfo {
                    #formIDPEmployeeBasicInformationFirstRowBoxInfoLabel {
                        font-size: 1.125rem;
                        font-weight: 700;
                        margin-bottom: 6px
                    }
                }
            }
        }
        #formIDPEmployeeBasicInformationSecondRow {
            .editorBlock {
                margin-bottom: 15px;
                margin-left: 15px;
            }
        }
        #commentSuperiorAndEmployee {
            #boxContent-commentSuperiorAndEmployee {
                padding: 10px 20px;
            }
        }
        #mobility {
            #boxContent-mobility {
                padding: 10px 20px;
                display: flex;
                flex-flow: row wrap;
            }
        }
    }
}

[data-theme='dark'] #formIDPEmployeeBasicInformationBox {
    #formIDPEmployeeBasicInformation {
        #formIDPEmployeeBasicInformationFirstRow {
            #formIDPEmployeeBasicInformationFirstRowBox {
                #formIDPEmployeeBasicInformationFirstRowBoxIcon {
                    svg {
                        path {
                            fill: #A0A0C1
                        }
                    }
                }
                #formIDPEmployeeBasicInformationFirstRowBoxInfo {
                    color: #A0A0C2;
                }
            }
        }
    }
}