#IDPEmployeeAreaBox {
    .noData {
        padding: 20px;
        font-size: 1rem;
        text-align: center;
    }
}

#approveIDP {
    text-align: center;
    padding: 20px 0;
}