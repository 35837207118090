#trainingGroupUserTrainingsSelectedGroupBox {
    #trainingGroupsUserSelectTrainingHeaderBox {
        padding: 10px 20px;
        .labelTrainings {
            width: 100%;
        }
        .valueTrainings {
            width: 100%;
            color: #FF8B02;
            font-size: 1.5rem;
            ;
        }
    }
    #trainingGroupsUserSelectTrainingBox {
        .trainingGroupsUserSelectTrainingItemsBox {
            padding: 10px;
            display: flex;
            flex-flow: row wrap;
        }
        .trainingGroupsUserSelectTrainingItemsBoxNoRecord {
            padding: 10px;
            display: flex;
            flex-flow: row wrap;
        }
    }
}