    #sideMenu {
        display: none;
        width: 24px;
        height: 24px;
        margin: 28px 25px;
        div {
            /* Styling of overlay */
            .bm-overlay {
                background: rgba(0, 0, 0, 0.3) !important;
                left: 0;
                top: 0;
            }
            div {
                .bm-burger-button {
                    position: relative;
                    width: 24px;
                    height: 24px;
                    span {
                        display: flex;
                        flex-flow: column;
                        /* Color/shape of burger icon bars */
                        .bm-burger-bars {
                            background: #FFFFFF;
                            height: 5px;
                            border-radius: 10px;
                        }
                        /* Color/shape of burger icon bars on hover*/
                        .bm-burger-bars-hover {
                            background: #000;
                        }
                    }
                }
            }
            /* Position and sizing of clickable cross button */
            .bm-cross-button {
                height: 24px;
                width: 24px;
                top: 28px !important;
                right: 20px !important;
            }
            /* Color/shape of close button cross */
            .bm-cross {
                background: none;
            }
            /*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
            .bm-menu-wrap {
                position: fixed;
                height: 100%;
                width: 55% !important;
                left: 0;
                top: 0;
                @media (max-width: 768px) {
                    width: 100% !important;
                }
                /* General sidebar styles */
                .bm-menu {
                    background: #1D4370;
                    font-size: 1.15em;
                    /* Wrapper for item list */
                    .bm-item-list {
                        color: #b8b7ad;
                        display: flex;
                        flex-flow: column;
                        align-items: flex-start;
                        #slideMenuItemLogo {
                            background-color: #1D4370;
                            display: flex !important;
                            flex-flow: row;
                            align-items: center;
                            padding: 0 20px;
                            min-height: 80px;
                            width: 100%;
                            svg {
                                path {
                                    fill: #FEFEFE;
                                }
                            }
                        }
                        #slideMenuItemElement {
                            width: 100%;
                            .elementItem {
                                .topMenuElementItemBox {
                                    display: flex;
                                    flex-flow: row nowrap;
                                    height: 60px;
                                    align-items: center;
                                    padding-left: 20px;
                                    background-color: #1D4370;
                                    margin-top: 1px;
                                    .topMenuElementItemSpan {
                                        color: #93B7E1;
                                        svg {
                                            margin-right: 14px;
                                            width: 32px;
                                            height: 32px;
                                            path {
                                                fill: #93B7E1;
                                            }
                                        }
                                    }
                                }
                                &.active {
                                    .topMenuElementItemBox {
                                        background-color: #FF8B02;
                                        .topMenuElementItemSpan {
                                            color: #fff;
                                            svg {
                                                path {
                                                    fill: #fff;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            .linkItemMenu {
                                .itemMenuBlock {
                                    display: flex;
                                    flex-flow: row;
                                    height: 60px;
                                    background-color: #7EA1CA;
                                    margin-top: 1px;
                                    align-items: center;
                                    color: #fff;
                                    text-transform: uppercase;
                                    .accentActive {
                                        width: 5px;
                                        min-width: 5px;
                                        height: 100%;
                                        background-color: #7EA1CA;
                                    }
                                    .iconItemMenu {
                                        svg {
                                            margin-left: 61px;
                                            margin-right: 14px;
                                            path {
                                                fill: #fff;
                                            }
                                        }
                                    }
                                }
                                &.active {
                                    align-items: flex-end;
                                    &+.subMenu {
                                        display: flex;
                                        min-width: 20vw;
                                    }
                                    .itemMenuBlock {
                                        background-color: #020304;
                                        .accentActive {
                                            width: 5px;
                                            min-width: 5px;
                                            border-radius: 0 10px 10px 0;
                                            background-color: #FF8B02;
                                            height: 100%;
                                        }
                                    }
                                }
                            }
                            .subMenu {
                                display: none;
                                flex-flow: row nowrap;
                                z-index: 1;
                                .fill {
                                    display: flex;
                                    flex-grow: 1;
                                    width: 76px;
                                    max-width: 76px;
                                    min-width: 76px;
                                    background-color: #ECF6F3;
                                }
                                .subMenuItemBox {
                                    display: flex;
                                    flex-flow: column;
                                    flex-grow: 1;
                                    background-color: #ECF6F3;
                                    padding-top: 6px;
                                    text-transform: uppercase;
                                    .linkItemSubMenu {
                                        color: #6182AA;
                                        margin-bottom: 7px;
                                        &.active {
                                            color: #ff8b02;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            /* Morph shape necessary with bubble or elastic */
            .bm-morph-shape {
                fill: #373a47;
            }
            /* Individual item */
            .bm-item {
                display: inline-block;
            }
        }
    }
    
    #topMenuElementMobile {
        display: none;
        flex-flow: row nowrap;
        flex-grow: 1;
        height: 100%;
        justify-content: center;
        align-items: center;
        .logo {
            svg {
                path {
                    fill: #FEFEFE;
                }
            }
        }
    }
    
    @media (max-width: 1314px) {
        #sideMenu {
            display: flex;
        }
        #topMenuElementMobile {
            display: flex;
        }
    }
    
    [data-theme='dark'] #sideMenu {
        div {
            /* Styling of overlay */
            .bm-overlay {
                background: rgba(0, 0, 0, 0.3) !important;
            }
            div {
                .bm-burger-button {
                    span {
                        .bm-burger-bars {
                            background: #FFFFFF;
                        }
                        .bm-burger-bars-hover {
                            background: #000;
                        }
                    }
                }
            }
            .bm-menu-wrap {
                .bm-menu {
                    background: #2C2D44;
                    .bm-item-list {
                        color: #b8b7ad;
                        #slideMenuItemLogo {
                            background-color: #2C2D44;
                            svg {
                                path {
                                    fill: #FEFEFE;
                                }
                            }
                        }
                        #slideMenuItemElement {
                            .elementItem {
                                .topMenuElementItemBox {
                                    background-color: #565776;
                                    .topMenuElementItemSpan {
                                        color: #A0A0C2;
                                        svg {
                                            path {
                                                fill: #A0A0C2;
                                            }
                                        }
                                    }
                                }
                                &.active {
                                    .topMenuElementItemBox {
                                        background-color: #FF8B02;
                                        .topMenuElementItemSpan {
                                            color: #fff;
                                            svg {
                                                path {
                                                    fill: #fff;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            .linkItemMenu {
                                .itemMenuBlock {
                                    background-color: #3D3E5A;
                                    color: #fff;
                                    .accentActive {
                                        background-color: #3D3E5A;
                                    }
                                    .iconItemMenu {
                                        svg {
                                            path {
                                                fill: #fff;
                                            }
                                        }
                                    }
                                }
                                &.active {
                                    .itemMenuBlock {
                                        background-color: #020304;
                                        .accentActive {
                                            background-color: #FF8B02;
                                        }
                                    }
                                }
                            }
                            .subMenu {
                                .fill {
                                    background-color: #2C2D44;
                                }
                                .subMenuItemBox {
                                    background-color: #2C2D44;
                                    .linkItemSubMenu {
                                        color: #A0A0C2;
                                        &.active {
                                            color: #ff8b02;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .bm-morph-shape {
                fill: #373a47;
            }
        }
    }