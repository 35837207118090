#QuestionYesNo {
    #boxContent-QuestionYesNo {
        .additionalText {
            text-align: center;
            margin: 20px
        }
        .answerBox {
            width: 100%;
            color: #fff;
            text-align: center;
            margin-bottom: 20px;
            .yesAnswer {
                width: 130px;
                background-color: #1D4370;
                margin-right: 10px;
                border-color: #1D4370;
            }
            .noAnswer {
                width: 130px;
                background-color: #BF3E3E;
                border-color: #BF3E3E;
            }
        }
    }
}

[data-theme='dark'] #QuestionYesNo {
    #boxContent-QuestionYesNo {
        .additionalText {
            color: #A0A0C2;
        }
    }
}