@import './../../scss/variableCustom.scss';
// Use variable

/*
$label-colour: #bbb;
$disabled-colour: #ddd;
$toggle-colour: #2f855a;
$white: #fff;
$focus-color: #ff0;
$colorNo: #fff;
$colorNoDark: #fff;
$backgroudColorNo: #bf3e3e;
$backgroundColorNoDark: #bf3e3e;

*/

.box {
    box-shadow: 0px 0px 8px #aebccf;
    border-radius: 10px 10px 0 0;
    background-color: #fff;
    margin-bottom: 10px;
    width: 100%;
    &.noMarginBottom {
        margin-bottom: 0;
    }
    &.noRadius {
        margin-bottom: 0 !important;
        border-radius: 0 !important;
        .boxHeader {
            border-radius: 0 !important;
        }
    }
    &.noPaddingTop {
        .boxContent {
            padding-top: 0px;
        }
    }
    &.noPaddingBottom {
        .boxContent {
            padding-bottom: 0px;
        }
    }
    &.noShadow {
        box-shadow: none;
        border-bottom: 1px solid #DCDCDC;
    }
    &.noContentStyle {
        box-shadow: none;
        background: none;
        display: flex;
        flex-flow: column;
        flex-grow: 1;
        .boxContent {
            display: flex;
            flex-grow: 1;
            padding: 0;
        }
    }
    &.paddingBottom {
        .boxContent {
            padding-bottom: 20px;
        }
    }
    &.allowGrow {
        display: flex;
        flex-flow: column;
        flex-grow: 1;
        align-items: flex-start;
        .boxHeader {
            width: 100%;
        }
        .boxContent {
            display: flex;
            flex-grow: 1;
            padding: 0;
            align-items: flex-start;
            flex-flow: column;
            width: 100%;
        }
    }
    &.noBorderBottom {
        border-bottom: none;
    }
    .boxHeader {
        border-radius: 10px 10px 0 0;
        background: linear-gradient(270deg, #6182AA 0%, #1D4370 100%);
        padding: 8px 15px;
        color: #fff;
        display: flex;
        flex-flow: row nowrap;
        svg {
            height: 25px;
            path {
                fill: #fff;
            }
        }
        &.light {
            background: #ecf6f3;
            color: #1D4370;
            border-top: 1px solid#93B7E1;
            border-bottom: 1px solid #93B7E1;
            svg {
                path {
                    fill: #1D4370;
                }
            }
        }
        &.dark {
            background: linear-gradient(90deg, #000000 0%, #1D4370 100%);
            color: #fff;
            svg {
                path {
                    fill: #fff;
                }
            }
        }
        .leftBlock {
            display: flex;
            flex-flow: nowrap row;
            justify-content: flex-start;
            flex-grow: 1;
            align-items: center;
            svg {
                margin-right: 13px;
            }
        }
        .rightBlock {
            display: flex;
            flex-flow: nowrap row;
            justify-content: flex-end;
            svg {
                margin-right: 0;
                margin-left: 13px;
            }
            #chevron-up {
                path {
                    fill: #93B7E1;
                }
            }
            #chevron-down {
                path {
                    fill: #93B7E1;
                }
            }
        }
    }
    .boxContent {
        padding: 0 20px;
    }
    &.noPadding {
        .boxContent {
            padding-left: 0;
            padding-right: 0;
        }
    }
}

[data-theme='dark'] .box {
    box-shadow: 0px 0px 8px #13151e;
    background-color: #2C2D44;
    &.noShadow {
        box-shadow: none;
        border-bottom: none;
    }
    &.noContentStyle {
        box-shadow: none;
        background: none;
    }
    .boxHeader {
        border-radius: 10px 10px 0 0;
        background: none;
        background-color: #30314C;
        color: #A0A0C2;
        svg {
            path {
                fill: #A0A0C2;
            }
        }
        &.light {
            background: none;
            background-color: #30314C;
            color: #A0A0C2;
            border-top: 1px solid#30314C;
            border-bottom: 1px solid #30314C;
            svg {
                path {
                    fill: #A0A0C2;
                }
            }
        }
        &.dark {
            background: none;
            background-color: #30314C;
            color: #A0A0C2;
            svg {
                path {
                    fill: #A0A0C2;
                }
            }
        }
        .leftBlock {
            svg {
                margin-right: 13px;
            }
        }
        .rightBlock {
            svg {
                margin-right: 0;
                margin-left: 13px;
            }
            #chevron-up {
                path {
                    fill: #93B7E1;
                }
            }
            #chevron-down {
                path {
                    fill: #93B7E1;
                }
            }
        }
    }
}