@import './variableCustom.scss';
.Toastify {
    .Toastify__toast-container {
        .Toastify__toast {
            border-radius: 10px 10px 0px 10px;
            box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.3);
            &.Toastify__toast--success {
                background-color: #00965E;
                border: 1px solid #99D6BF;
                .Toastify__toast-body {
                    color: #99D6BF;
                }
            }
            &.Toastify__toast--info {
                background-color: #56A3E1;
                border: 1px solid #D6EDFF;
                .Toastify__toast-body {
                    color: #D6EDFF, 100%, 100%;
                }
            }
            &.Toastify__toast--warning {
                background-color: #FF8B02;
                border: 1px solid #FFE8C6;
                .Toastify__toast-body {
                    color: #FFE8C6;
                }
            }
            &.Toastify__toast--error {
                background-color: #BF3E3E;
                border: 1px solid #FFAFAF;
                .Toastify__toast-body {
                    color: #FFAFAF;
                }
            }
            .Toastify__toast-body {
                margin: 0;
                padding: 0;
                .alertMsgBox {
                    display: flex;
                    flex-flow: row wrap;
                    .alertTitle {
                        font-weight: 600;
                        font-size: 1rem;
                        margin-bottom: 10px;
                        width: 100%;
                    }
                    .alertIconAndMsg {
                        display: flex;
                        flex-flow: row;
                        width: 100%;
                        .alertIcon {
                            margin-right: 17px;
                        }
                        .alertMsg {
                            align-items: center;
                            display: flex;
                        }
                    }
                }
            }
            .Toastify__close-button {
                position: absolute;
                right: 10px;
                svg {
                    width: 14px;
                    height: 14px;
                }
            }
        }
    }
}