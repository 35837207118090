@import './../../../../scss/variableCustom.scss';
.tableCellLoyaltyStatus {
    display: flex;
    &.toLeft {
        justify-content: flex-start;
    }
    &.center {
        justify-content: center;
    }
    &.toRight {
        justify-content: flex-end;
    }
    .boxStatus {
        width: 100%;
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        .circleBox {
            display: flex;
            width: 32px;
            height: 32px;
            border-radius: 16px;
            justify-content: center;
            align-items: center;
            margin: 0 2px;
            margin: auto;
            &.trueStatus {
                background-color: #1D4370;
            }
            &.falseStatus {
                background-color: #BF3E3E;
            }
            &.noneStatus {
                background-color: #C4C4C4;
            }
            path {
                fill: #fff;
            }
        }
    }
}