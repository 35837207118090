@font-face {
    font-family: 'Exo 2.0';
    font-style: normal;
    font-weight: 400;
    src: url('./font/Exo2.0-Regular.woff') format('woff');
}

@font-face {
    font-family: 'Exo 2.0';
    font-style: italic;
    font-weight: 400;
    src: url('./font/Exo2.0-Italic.woff') format('woff');
}

@font-face {
    font-family: 'Exo 2.0';
    font-style: normal;
    font-weight: 200;
    src: url('./font/Exo2.0-ExtraLight.woff') format('woff');
}

@font-face {
    font-family: 'Exo 2.0';
    font-style: normal;
    font-weight: 100;
    src: url('./font/Exo2.0-Thin.woff') format('woff');
}

@font-face {
    font-family: 'Exo 2.0';
    font-style: italic;
    font-weight: 200;
    src: url('./font/Exo2.0-ExtraLightItalic.woff') format('woff');
}

@font-face {
    font-family: 'Exo 2.0';
    font-style: italic;
    font-weight: 100;
    src: url('./font/Exo2.0-ThinItalic.woff') format('woff');
}

@font-face {
    font-family: 'Exo 2.0';
    font-style: normal;
    font-weight: 300;
    src: url('./font/Exo2.0-Light.woff') format('woff');
}

@font-face {
    font-family: 'Exo 2.0';
    font-style: italic;
    font-weight: 300;
    src: url('./font/Exo2.0-LightItalic.woff') format('woff');
}

@font-face {
    font-family: 'Exo 2.0';
    font-style: normal;
    font-weight: 500;
    src: url('./font/Exo2.0-Medium.woff') format('woff');
}

@font-face {
    font-family: 'Exo 2.0';
    font-style: italic;
    font-weight: 500;
    src: url('./font/Exo2.0-MediumItalic.woff') format('woff');
}

@font-face {
    font-family: 'Exo 2.0';
    font-style: normal;
    font-weight: 600;
    src: url('./font/Exo2.0-SemiBold.woff') format('woff');
}

@font-face {
    font-family: 'Exo 2.0';
    font-style: italic;
    font-weight: 600;
    src: url('./font/Exo2.0-SemiBoldItalic.woff') format('woff');
}

@font-face {
    font-family: 'Exo 2.0';
    font-style: normal;
    font-weight: 700;
    src: url('./font/Exo2.0-Bold.woff') format('woff');
}

@font-face {
    font-family: 'Exo 2.0';
    font-style: italic;
    font-weight: 700;
    src: url('./font/Exo2.0-BoldItalic.woff') format('woff');
}

@font-face {
    font-family: 'Exo 2.0';
    font-style: normal;
    font-weight: 800;
    src: url('./font/Exo2.0-ExtraBold.woff') format('woff');
}

@font-face {
    font-family: 'Exo 2.0';
    font-style: italic;
    font-weight: 800;
    src: url('./font/Exo2.0-ExtraBoldItalic.woff') format('woff');
}

@font-face {
    font-family: 'Exo 2.0';
    font-style: normal;
    font-weight: 900;
    src: url('./font/Exo2.0-Black.woff') format('woff');
}

@font-face {
    font-family: 'Exo 2.0';
    font-style: italic;
    font-weight: 900;
    src: url('./font/Exo2.0-BlackItalic.woff') format('woff');
}