.CatalogsPlansItemIDP {
    border: 1px solid #93B7E1;
    box-sizing: border-box;
    box-shadow: 0px 0px 8px #C3CDDB;
    border-radius: 0px 0px 10px 10px;
    display: flex;
    flex-flow: column;
    margin: 0.625rem 0.375rem;
    width: 280px;
    min-height: 280px;
    background-color: #fff;
    &:hover {
        .accent {
            background-color: #93B7E1;
        }
    }
    &.noActive {
        border: 1px solid #C4C4C4;
        .accent {
            background-color: #C4C4C4;
        }
       
        
        .dateOfDataEntry {
            .dateOfDataEntryDates{
                .startDateOfDataEntryDatesBox {
                    color: #959595;
                }
                .endDateOfDataEntryDatesBox {
                    div {
                        color: #959595;
                    }
                }
            }
        }
        .dateOfSemiAnnualVerification {
            .dateOfSemiAnnualVerificationDates{
                .startDateOfSemiAnnualVerificationDatesBox {
                    color: #959595;
                }
                .endDateOfSemiAnnualVerificationDatesBox {
                    div {
                        color: #959595;
                    }
                }
            }
        }
        .annualVerificationDate {
            .annualVerificationDateDates{
                .startAnnualVerificationDateBox {
                    color: #959595;
                }
                .endAnnualVerificationDateBox {
                    div {
                        color: #959595;
                    }
                }
            }
        }
        .actionIDP {
            margin:20px auto;
                
        }
    }
    .accent {
        width: 100%;
        height: 5px;
        border-radius: 0 0 10px 10px;
        background-color: #FF8B02;
    }
    
    .dateOfDataEntry {
        display: flex;
        flex-flow: column;
        border-bottom: 1px solid #DCDCDC;
        margin: 12px 15px 0 15px;
        padding: 0 15px 10px 10px;
        .dateOfDataEntryLabel {
            width: 100%;
            color: #555555;
        }
        .dateOfDataEntryDates {
            display: flex;
            flex-flow: row nowrap;
            div {
                // color: #618299;
                span {
                    font-weight: 600;
                }
            }
            .startDateOfDataEntryDatesBox {
                width: 50%;
                text-align: left
            }
            .endDateOfDataEntryDatesBox {
                width: 50%;
                text-align: right;
            }
        }
    }
    .dateOfSemiAnnualVerification {
        display: flex;
        flex-flow: column;
        border-bottom: 1px solid #DCDCDC;
        margin: 12px 15px 0 15px;
        padding: 0 15px 10px 10px;
        .dateOfSemiAnnualVerificationLabel {
            width: 100%;
            color: #555555;
        }
        .dateOfSemiAnnualVerificationDates {
            display: flex;
            flex-flow: row nowrap;
            div {
                color: #618299;
                span {
                    font-weight: 600;
                }
            }
            .startDateOfSemiAnnualVerificationDatesBox {
                width: 50%;
                text-align: left
            }
            .endDateOfSemiAnnualVerificationDatesBox {
                width: 50%;
                text-align: right;
            }
        }
    }
    .annualVerificationDate {
        display: flex;
        flex-flow: column;
        border-bottom: 1px solid #DCDCDC;
        margin: 12px 15px 0 15px;
        padding: 0 15px 10px 10px;
        .annualVerificationDateLabel {
            width: 100%;
            color: #555555;
        }
        .annualVerificationDateDates {
            display: flex;
            flex-flow: row nowrap;
            div {
                color: #618299;
                span {
                    font-weight: 600;
                }
            }
            .startAnnualVerificationDateBox {
                width: 50%;
                text-align: left
            }
            .endAnnualVerificationDateBox {
                width: 50%;
                text-align: right;
            }
        }
    }
    .actionIDP {
        margin:20px auto;
        
    }
}

[data-theme='dark'] .CatalogsPlansItemIDP {
    border: 1px solid #13151e;
    box-shadow: 0px 0px 8px #13151e;
    background-color: #2c2d44;
    &:hover {
        .accent {
            background-color: #49BACA;
        }
    }
    &.noActive {
        border: 1px solid #13151e;
        .accent {
            background-color: #414059;
        }
        
        .registrationProcess {
            .registrationProcessLabel {
                color: #4d4e68;
            }
            .registrationProcessDates {
                div {
                    color: #4d4e68;
                }
            }
        }
        .action {
            
               
                .btn {
                    color: #4d4e68;
                }
            
        }
    }
    .accent {
        background-color: #FF8B02;
    }
   
    .registrationProcess {
        border-bottom: 1px solid #494B6D;
        .registrationProcessLabel {
            color: #A0A0C2;
        }
        .registrationProcessDates {
            div {
                color: #49BACA;
            }
        }
    }
    .action {
            .btn {
                color: #A0A0C2;
            }
    }
}