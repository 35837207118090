@import './../../../scss/variableCustom.scss';
.questionRadioSummaryBox {
    display: flex;
    flex-flow: row;
    border-bottom: 1px solid #EDEDED;
    padding: 17px 20px 17px 20px;
    &:hover {
        background-color: #F1F6F5;
    }
    &.additionalClass {
        background-color: #ECF6F3;
        border-top: 1px solid #93B7E1;
        border-bottom: 1px solid #93B7E1;
        padding: 4px 10px;
        .questionRadioName {
            color: #1D4370;
            font-weight: 600;
        }
    }
    .questionRadioSummaryNumber {
        width: 30px;
        text-align: center;
        align-items: center;
        display: flex;
        color: #618299;
    }
    .questionRadioSummaryName {
        display: flex;
        flex-grow: 1;
        align-items: center;
        color: #618299;
    }
    .questionRadioSummaryAnswerBox {
        display: flex;
        flex-flow: row nowrap;
        color: #1D4370;
        font-weight: 600;
    }
}