.shortEditorBox {
    &.withLabel {
        margin-right: calc(0.65rem + 18px);
        margin-top: 5px;
        margin-bottom: 5px;
        .form-label {
            color: #1D4370;
        }
    }
    &.noInfoPlace {
        margin-right: 0 !important;
    }
    .tox-tinymce {
        border: 1px solid #93B7E1;
        border-radius: 5px;
        &.tox-tinymce--disabled {
            border: none;
        }
    }
    &.required {
        .tox-tinymce {
            border: 1px solid #93B7E1;
            &.tox-tinymce--disabled {
                border: none;
            }
        }
    }
    &.error {
        .tox-tinymce {
            border: 1px solid #BF3E3E;
        }
        .additionalInfo {
            .alertInfo {
                color: #BF3E3E;
            }
        }
    }
}

[data-theme='dark'] .shortEditorBox {
    &.withLabel {
        .form-label {
            color: #fff;
        }
    }
    .tox-tinymce {
        border: 1px solid #6A6A91;
        &.tox-tinymce--disabled {
            border: none;
        }
    }
    &.required {
        .tox-tinymce {
            border: 1px solid #9A9CD4;
            &.tox-tinymce--disabled {
                border: none;
            }
        }
    }
    &.error {
        .tox-tinymce {
            border: 1px solid #BF3E3E;
        }
        .additionalInfo {
            .alertInfo {
                color: #BF3E3E;
            }
        }
    }
}

.mce-content-body {
    &.shortEditor {
        margin: 0;
        font-size: 0.875rem;
    }
}