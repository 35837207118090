@import './../../../scss/variableCustom.scss';
.recordDefault {
    width: 1.125rem;
    height: 1.125rem;
    border-radius: 0.188rem;
    background-color: #1D4370;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
        path {
            fill: #fff;
        }
    }
}

[data-theme='dark'] .recordDefault {}