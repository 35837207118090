@import './../../../../scss/variableCustom.scss';
.tableCellTrainingsStatus {
    display: flex;
    &.toLeft {
        justify-content: flex-start;
    }
    &.center {
        justify-content: center;
    }
    &.toRight {
        justify-content: flex-end;
    }
    .status {
        margin: 0 2px;
        .labelStatus {
            width: 100%;
            display: flex;
            flex-flow: row nowrap;
            color: #555;
            font-size: 0.625rem;
            justify-content: center;
            margin-bottom: 3px;
        }
        .boxStatus {
            width: 100%;
            display: flex;
            flex-flow: row nowrap;
            justify-content: center;
            .circleBox {
                display: flex;
                width: 32px;
                height: 32px;
                border-radius: 16px;
                justify-content: center;
                align-items: center;
                margin: 0 2px;
                margin: auto;
                &.ACCEPTED {
                    background-color: #1D4370;
                }
                &.REJECTED {
                    background-color: #BF3E3E;
                }
                &.VERIFY {
                    background-color: #C4C4C4;
                }
                &.NONE {
                    background-color: none;
                }
                path {
                    fill: #fff;
                }
            }
        }
    }
}