#IDPEmployeeBox {
    .boxContent {
        #IDPEmployeeTabs {
            margin-bottom: 0;
            margin-top: 9px;
        }
        #IDPEmployeeContentBox {
            box-shadow: 0px -12px 20px rgba(7, 75, 50, 0.15);
        }
    }
}

[data-theme='dark'] #IDPEmployeeBox {
    .boxContent {
        #IDPEmployeeContentBox {
            box-shadow: 0px -12px 20px rgba(18, 20, 33, 0.15);
        }
    }
}