@import './../../../../scss/variableCustom.scss';
.tableCellBlockSwitch {
    display: flex;
    &.toLeft {
        justify-content: flex-start;
    }
    &.center {
        justify-content: center;
    }
    &.toRight {
        justify-content: flex-end;
    }
}