#trainingsGroupSettlementContainer {
    #trainingsGroupSettlementBox {
        display: flex;
        flex-flow: row;
        #inputBox {
            display: flex;
            flex-flow: row;
            align-items: center;
            .blockNumberWithError {
                .blockNumber {
                    .blockNumberWithInfo {
                        input {
                            margin-right: 0;
                        }
                    }
                }
            }
            span {
                margin-left: 5px;
                margin-right: 20px;
            }
        }
        #buttonBox {
            display: flex;
            flex-flow: row;
            align-items: center;
        }
    }
}