#TrainingsAndCompetenceTables {
    #trainingsBoxWrap {
        width: 60%;
    }
    #competencesBoxWrap {
        width: 39%;
    }
    @media (max-width: 1200px) {
        #trainingsBoxWrap {
            width: 100%;
        }
        #competencesBoxWrap {
            width: 100%;
        }
    }
}

#previewTrainingsSltTrainingsBox {
    #previewTrainingsSltTrainingsBoxWrap {
        margin-top: 1.0625rem;
        margin-bottom: 1.4375rem;
        color: #1D4370;
        #previewTrainingsSltTrainingsBoxLabel {
            font-weight: 600;
        }
        #previewTrainingsSltTrainingsBoxData {
            margin-left: 5px;
            &.yes {
                color: #1D4370;
            }
            &.no {
                color: #BF3E3E;
            }
        }
    }
}

[data-theme='dark'] #previewTrainingsSltTrainingsBox {
    #previewTrainingsSltTrainingsBoxWrap {
        color: #A0A0C2;
        #previewTrainingsSltTrainingsBoxData {
            &.yes {
                color: #1D4370;
            }
            &.no {
                color: #BF3E3E;
            }
        }
    }
}