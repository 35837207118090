@import './../../../scss/variableCustom.scss';
.questionRadioBox {
    display: flex;
    flex-flow: row;
    border-bottom: 1px solid #EDEDED;
    padding: 4px 10px 4px 20px;
    &:hover {
        background-color: #F1F6F5;
    }
    &.additionalClass {
        background-color: #ECF6F3;
        border-top: 1px solid #93B7E1;
        border-bottom: 1px solid #93B7E1;
        padding: 4px 10px;
        .questionRadioName {
            color: #1D4370;
            font-weight: 600;
        }
    }
    .questionRadioNumber {
        width: 30px;
        text-align: center;
        align-items: center;
        display: flex;
        color: #618299;
    }
    .questionRadioName {
        display: flex;
        flex-grow: 1;
        align-items: center;
        color: #618299;
    }
    .questionRadioAnswerBox {
        display: flex;
        flex-flow: row nowrap;
        .status {
            margin: 0 2px;
            display: flex;
            flex-flow: row wrap;
            align-items: flex-end;
            width: 55px;
            .labelStatus {
                width: 100%;
                display: flex;
                flex-flow: row nowrap;
                color: #555;
                font-size: 0.75rem;
                justify-content: center;
                margin-bottom: 3px;
                text-align: center;
                flex-grow: 1;
                align-items: center;
                .number {
                    font-weight: 600;
                }
            }
            .boxStatus {
                width: 100%;
                display: flex;
                flex-flow: row nowrap;
                justify-content: center;
                padding-bottom: 4px;
                .circleBox {
                    display: flex;
                    width: 25px;
                    height: 25px;
                    border-radius: 13px;
                    justify-content: center;
                    align-items: center;
                    margin: 0 2px;
                    background-color: #F1F6F5;
                    border: 1px solid #93B7E1;
                    &.active {
                        background-color: #1D4370;
                        border: 1px solid #1D4370;
                    }
                    path {
                        fill: #fff;
                    }
                }
            }
        }
    }
}