.customTooltip {
    opacity: 1;
    .rc-tooltip-content {
        .rc-tooltip-arrow {
            border-right-color: #93B7E1;
        }
        .rc-tooltip-inner {
            color: #1D4370;
            background: #DCF2EA;
            border: 1px solid #93B7E1;
            box-shadow: 0px 0px 10px rgba(7, 75, 50, 0.3);
            border-radius: 10px;
            padding: 10px 20px 15px;
            max-width: 300px;
            .labelCustomTooltip {
                font-weight: 600;
            }
            .listCustomTooltip {
                .listName {
                    font-weight: 600;
                    margin-top: 10px;
                }
            }
        }
    }
}

.customTooltipBox {
    margin: 0 5px;
    &.red{
        svg{
            path{
                fill:red;
            }
        }
    }
    &.green{
        svg{
            path{
                fill:green;
            }
        }
    }
}