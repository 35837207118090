#showIDPEmployeeFeedbackBox {
  #showIDPEmployeeFeedbackFirstRow {
    #showIDPEmployeeFeedbackFirstRowBox {
      display: flex;
      flex-flow: row nowrap;
      padding: 12px 15px 0;
      #showIDPEmployeeFeedbackFirstRowBoxIcon {
        margin-right: 20px;
        justify-content: center;
        align-items: center;
        display: flex;
      }
      #showIDPEmployeeFeedbackFirstRowBoxInfo {
        #showIDPEmployeeFeedbackFirstRowBoxInfoLabel {
          font-size: 1.125rem;
          font-weight: 700;
          margin-bottom: 6px;
        }
      }
    }
  }
  #IDPFeedbackBox {
    margin-bottom: 5px;
    #boxContent-IDPFeedbackBox {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-around;
      .feedbackBoxItem {
        max-width: 400px;
        display: flex;
        flex-flow: column;
        background: #ffffff;
        border: 1px solid #93B7E1;
        box-shadow: 0px 0px 8px #aec6cf;
        border-radius: 0px 0px 10px 10px;
        margin: 10px 20px;
        &:hover {
          .boxAccent {
            background-color: #ff8b02;
          }
        }
        .boxAccent {
          height: 5px;
          border-radius: 0 0 10px 10px;
          background-color: #93B7E1;
        }
        .boxTitleBox {
          padding: 5px 20px 10px;
          border-bottom: 1px solid #dcdcdc;
          min-height: 83px;
          .boxTitle {
            font-size: 1.5rem;
            font-weight: 700;
            color: #1D4370;
          }
          .boxSubTitle {
            color: #618299;
          }
        }
        .boxAdditionalInfoBox {
          padding: 10px 20px;
          display: flex;
          flex-flow: column;
          flex-grow: 1;
          .boxAdditionalInfoBoxItem {
            display: flex;
            flex-flow: row;
            align-items: flex-start;
            .boxAdditionalInfoBoxItemNumber {
              font-size: 1.5rem;
              color: #004290;
              margin-right: 10px;
              min-width: 25px;
            }
            .boxAdditionalInfoBoxItemText {
              color: #555;
              margin-bottom: 15px;
            }
          }
        }
        .boxFormConsistentBehaviour {
          .showTextarea {
            margin: 10px;
            label {
              color: #004290;
              font-weight: 600;
            }
          }
        }
        .boxFormInconsistentBehaviour {
          .showTextarea {
            margin: 10px;
            label {
              color: #bf3e3e;
              font-weight: 600;
            }
          }
        }
      }
    }
  }
}

[data-theme='dark'] #showIDPEmployeeFeedbackBox {
  #showIDPEmployeeFeedbackFirstRow {
    #showIDPEmployeeFeedbackFirstRowBox {
      color: #a0a0c2;
      #showIDPEmployeeFeedbackFirstRowBoxIcon {
        svg {
          path {
            fill: #a0a0c2;
          }
        }
      }
    }
  }
  #IDPFeedbackBox {
    #boxContent-IDPFeedbackBox {
      .feedbackBoxItem {
        background: #2c2d44;
        border: 1px solid #13151e;
        box-shadow: 0px 0px 8px #13151e;
        &:hover {
          .boxAccent {
            background-color: #ff8b02;
          }
        }
        .boxAccent {
          background-color: #a0a0c2;
        }
        .boxTitleBox {
          border-bottom: 1px solid #494b6d;
          .boxTitle {
            color: #fff;
          }
          .boxSubTitle {
            color: #a0a0c2;
          }
        }
        .boxAdditionalInfoBox {
          .boxAdditionalInfoBoxItem {
            .boxAdditionalInfoBoxItemNumber {
              color: #004290;
            }
            .boxAdditionalInfoBoxItemText {
              color: #a0a0c2;
            }
          }
        }
        .boxFormConsistentBehaviour {
          .editorBlock {
            label {
              color: #fff;
            }
          }
        }
        .boxFormInconsistentBehaviour {
          .editorBlock {
            label {
              color: #bf3e3e;
            }
          }
        }
      }
    }
  }
}
