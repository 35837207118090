#formEmployeeAppraisalFeedbackBox {
  #formEmployeeAppraisalFeedback {
    #formEmployeeAppraisalFeedbackFirstRow {
      #formEmployeeAppraisalFeedbackFirstRowBox {
        display: flex;
        flex-flow: row wrap;
        padding: 12px 15px 0;
        #formEmployeeAppraisalFeedbackFirstRowBoxIcon {
          margin-right: 20px;
          justify-content: center;
          align-items: center;
          display: flex;
        }
        #formEmployeeAppraisalFeedbackFirstRowBoxInfo {
          #formEmployeeAppraisalFeedbackFirstRowBoxInfoLabel {
            font-size: 1.125rem;
            font-weight: 700;
            margin-bottom: 6px;
          }
        }
      }
    }
    #IDPFeedbackBox {
      #boxContent-IDPFeedbackBox {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-around;
        .feedbackBoxItem {
          max-width: 350px;
          display: flex;
          flex-flow: column;
          background: #ffffff;
          border: 1px solid #93B7E1;
          box-shadow: 0px 0px 8px #aec6cf;
          border-radius: 0px 0px 10px 10px;
          margin: 10px 20px;
          min-width: 350px;
          &:hover {
            .boxAccent {
              background-color: #ff8b02;
            }
          }
          .boxAccent {
            height: 5px;
            border-radius: 0 0 10px 10px;
            background-color: #93B7E1;
          }
          .boxTitleBox {
            padding: 5px 20px 10px;
            border-bottom: 1px solid #dcdcdc;
            min-height: 83px;
            .boxTitle {
              font-size: 1.5rem;
              font-weight: 700;
              color: #1D4370;
            }
            .boxSubTitle {
              color: #618299;
            }
          }
          .boxForm {
            .editorBlock {
              margin: 10px;
              label {
                color: #004290;
                font-weight: 600;
              }
            }
          }
        }
      }
    }
  }
}

[data-theme='dark'] #formEmployeeAppraisalFeedbackBox {
  #formEmployeeAppraisalFeedback {
    #formEmployeeAppraisalFeedbackFirstRow {
      #formEmployeeAppraisalFeedbackFirstRowBox {
        color: #a0a0c2;
        #formEmployeeAppraisalFeedbackFirstRowBoxIcon {
          svg {
            path {
              fill: #a0a0c2;
            }
          }
        }
      }
    }
    #IDPFeedbackBox {
      #boxContent-IDPFeedbackBox {
        .feedbackBoxItem {
          background: #2c2d44;
          border: 1px solid #13151e;
          box-shadow: 0px 0px 8px #13151e;
          &:hover {
            .boxAccent {
              background-color: #ff8b02;
            }
          }
          .boxAccent {
            background-color: #a0a0c2;
          }
          .boxTitleBox {
            border-bottom: 1px solid #494b6d;
            .boxTitle {
              color: #fff;
            }
            .boxSubTitle {
              color: #a0a0c2;
            }
          }
          .boxForm {
            .editorBlock {
              label {
                color: #fff;
              }
            }
          }
        }
      }
    }
  }
}
