.blockCustomSwitch {
    display: flex;
    flex-flow: row;
    justify-content: flex-end;
    align-items: center;
    padding: 0.65rem 0;
    &.withLabel {
        margin-right: calc(0.65rem + 18px);
        margin-top: 5px;
        margin-bottom: 5px;
        .form-check-label {
            color: #1D4370;
        }
    }
    &.disabled {
        padding: 0;
    }
    .form-check-label {
        margin-right: 10px;
    }
    .form-check-input {
        margin-right: calc(0.65rem + 18px);
        width: 1.125rem;
        height: 1.125rem;
    }
}

[data-theme='dark'] .blockCustomSwitch {
    &.withLabel {
        .form-check-label {
            color: #fff;
        }
    }
}