@import './../../../scss/variableCustom.scss';
// Use variable

/*
$label-colour: #bbb;
$disabled-colour: #ddd;
$toggle-colour: #2f855a;
$white: #fff;
$focus-color: #ff0;
$colorNo: #fff;
$colorNoDark: #fff;
$backgroudColorNo: #bf3e3e;
$backgroundColorNoDark: #bf3e3e;

*/

.tablePaginationJson {
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    background-color: #ECF6F2;
    padding: 0.625rem 1.25rem;
    .tablePaginationLeft {
        flex-grow: 1;
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
        align-items: center;
    }
    .tablePaginationRight {
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-end;
        align-items: stretch;
    }
    .paginationBlock {
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-end;
        align-items: center;
        font-weight: 600;
        color: #959595;
        min-width: 110px;
        .pagination {
            margin-right: 0.625rem;
            margin-left: 0.625rem;
            svg {
                path {
                    fill: #1D4370;
                }
            }
            &.first {
                cursor: default;
                svg {
                    path {
                        fill: #E5E5E5;
                    }
                }
            }
            &.last {
                cursor: default;
                svg {
                    path {
                        fill: #E5E5E5;
                    }
                }
            }
        }
        .inputPaginationNumberPage {
            display: inline-grid;
            border: 1px solid #93B7E1;
            border-radius: 5px 5px 0px 0px;
            margin-right: 4px;
            background-color: #fff;
            input {
                background: none;
                border: none;
                width: auto;
                min-width: 20px;
                resize: none;
                appearance: none;
                -webkit-appearance: none;
                text-align: center;
                color: #FF8B02;
                margin: 0;
                padding: 0;
                grid-area: 1/2;
            }
        }
    }
    .rowInPage {
        width: 150px;
        margin-right: 10px;
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-end;
        align-items: center;
        .selectPagination {
            min-width: 55px;
        }
    }
}

[data-theme='dark'] .box {}