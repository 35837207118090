#formEmployeeAppraisal {
    #formEmployeeAppraisalBox {
        .boxContent {
            padding: 0;
            #formEmployeeAppraisalFirstRow {
                display: flex;
                flex-flow: row;
                padding: 10px 20px;
                border-bottom: 1px solid #DCDCDC;
                #formEmployeeAppraisalFirstRowIcon {
                    margin-right: 20px;
                    justify-content: center;
                    align-items: center;
                    display: flex;
                }
                #formEmployeeAppraisalFirstRowData {
                    width: 100%;
                    display: flex;
                    flex-flow: row;
                    #typeSelectBlock {
                        width: 210px;
                        margin-top: 2px;
                    }
                    #name-contentbox {
                        width: 470px;
                        margin-top: 2px;
                        margin-bottom: 5px;
                        margin-right: 30px;
                        .blockInput {
                            padding: 0;
                            .blockInputWithInfo {
                                .form-control {
                                    margin-right: 0;
                                }
                            }
                        }
                    }
                    #weightSelectBlock {
                        width: 140px;
                        margin-top: 2px;
                    }
                }
            }
            #formEmployeeAppraisalSecondRow {
                padding-left: 20px;
                display: flex;
                flex-flow: row;
                #measure-contentbox {
                    width: 280px;
                    margin-top: 5px;
                    margin-bottom: 5px;
                    margin-right: 30px;
                    .blockInput {
                        padding: 0;
                        .blockInputWithInfo {
                            .form-control {
                                margin-right: 0;
                            }
                        }
                    }
                }
                #realizationDateBlockDate {
                    margin-top: 5px;
                    margin-bottom: 5px;
                    margin-right: 30px;
                    width: 130px;
                    .blockDate {
                        padding: 0;
                        .blockDateWithInfo {
                            .react-datepicker-wrapper {
                                margin: 0;
                                .react-datepicker__input-container {
                                    #realizationDate {
                                        width: 100%;
                                    }
                                }
                            }
                        }
                    }
                }
                #statusSelectBlock {
                    width: 150px;
                }
                #progressCustomSliderBlock {
                    width: 310px;
                    margin-top: 5px;
                    margin-bottom: 5px;
                    margin-right: 30px;
                }
            }
            #formEmployeeAppraisalThirdRow {
                #commentEditorBlock {
                    margin-left: 20px;
                    margin-bottom: 20px;
                }
            }
            #formEmployeeAppraisalRatingGoalSixthRow {
                border-top: 1px solid #DCDCDC;
                padding: 10px 20px;
                display: flex;
                flex-flow: row;
                border-bottom: 1px solid #dcdcdc;
                #ratingSelectBlock {
                  min-width: 250px;
                }
                .editorBlock {
                  min-width: 670px;
                }
              }
        }
    }
}

[data-theme='dark'] #formIDPEmployeeArea {
    #formIDPEmployeeAreaBox {
        #boxContent-formIDPEmployeeAreaBox {
            #formIDPEmployeeAreaFirstRow {
                border-bottom: 1px solid #3D3E58;
                #formIDPEmployeeAreaFirstRowIcon {
                    svg {
                        path {
                            fill: #d6d6f1;
                        }
                    }
                }
            }
            #formIDPEmployeeAreaBlock {
                #formIDPEmployeeAreaBlockLabel {
                    color: #A0A0C2;
                }
                .formIDPEmployeeAreaBlockContent {
                    border-bottom: 1px solid #3D3E58;
                    .formIDPEmployeeAreaBlockPerecent {
                        background-color: #FF8B02;
                        .CustomAnimatedPrograssBar {
                            svg {
                                .CircularProgressbar-trail {
                                    stroke: #FF8B02;
                                }
                                .CircularProgressbar-path {
                                    stroke: #ffa841;
                                }
                                .CircularProgressbar-text {
                                    fill: #fff;
                                }
                            }
                        }
                        &#formIDPEmployeeAreaBlock20Percent {
                            background-color: #00965E;
                            .CustomAnimatedPrograssBar {
                                svg {
                                    .CircularProgressbar-trail {
                                        stroke: #00965E;
                                    }
                                    .CircularProgressbar-path {
                                        stroke: #40b086;
                                    }
                                    .CircularProgressbar-text {
                                        fill: #fff;
                                    }
                                }
                            }
                        }
                        &#formIDPEmployeeAreaBlock10Percent {
                            background-color: #99D6BF;
                            .CustomAnimatedPrograssBar {
                                svg {
                                    .CircularProgressbar-trail {
                                        stroke: #99D6BF;
                                    }
                                    .CircularProgressbar-path {
                                        stroke: #b3e0cf;
                                    }
                                    .CircularProgressbar-text {
                                        fill: #fff;
                                    }
                                }
                            }
                        }
                    }
                    .formIDPEmployeeAreaBlockAdditional {
                        display: flex;
                        flex-flow: column;
                        margin-left: 15px;
                        .formIDPEmployeeAreaBlockAdditionalDateAndMeasure {
                            display: flex;
                            flex-flow: row;
                        }
                        .formIDPEmployeeAreaBlockAdditionalProgressData {
                            padding: 0 20px 0 0;
                        }
                        .formIDPEmployeeAreaBlockAdditionalProgress {
                            .customSliderBlock {
                                padding: 0 40px 0 0;
                            }
                            &#formIDPEmployeeAreaBlock20AdditionalProgress {
                                .customSliderBlock {
                                    .blockInputWithInfo {
                                        .rc-slider {
                                            .rc-slider-track {
                                                background-color: #00965E;
                                            }
                                            .rc-slider-handle {
                                                &:hover {
                                                    border-color: #00965E;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            &#formIDPEmployeeAreaBlock10AdditionalProgress {
                                .customSliderBlock {
                                    .blockInputWithInfo {
                                        .rc-slider {
                                            .rc-slider-track {
                                                background-color: #99D6BF;
                                            }
                                            .rc-slider-handle {
                                                &:hover {
                                                    border-color: #99D6BF;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .formIDPEmployeeAreaBlockComment {
                        .formIDPEmployeeAreaBlockCommentLabel {
                            color: #fff;
                        }
                    }
                }
            }
        }
    }
}