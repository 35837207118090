.formPlansTrainings {
    #limitBox {
        margin-top: 10px;
        .limitBoxContainer {
            .titleLimitBox {
                width: 100%;
            }
            .itemLimitBox {
                display: flex;
                flex-flow: row;
            }
        }
    }
    #catalogsTrainingsBox {
        display: flex;
        flex-flow: row;
        justify-content: center;
        padding-bottom: 20px;
        .selectBlock {
            margin-top: 20px;
            max-width: 500px;
            width: 100%;
        }
    }
}