#formIDPEmployeeFeedbackBox {
    #formIDPEmployeeFeedback {
        #formIDPEmployeeFeedbackFirstRow {
            #formIDPEmployeeFeedbackFirstRowBox {
                display: flex;
                flex-flow: row nowrap;
                padding: 12px 15px 0;
                #formIDPEmployeeFeedbackFirstRowBoxIcon {
                    margin-right: 20px;
                    justify-content: center;
                    align-items: center;
                    display: flex;
                }
                #formIDPEmployeeFeedbackFirstRowBoxInfo {
                    #formIDPEmployeeFeedbackFirstRowBoxInfoLabel {
                        font-size: 1.125rem;
                        font-weight: 700;
                        margin-bottom: 6px
                    }
                }
            }
        }
        #IDPFeedbackBox {
            #boxContent-IDPFeedbackBox {
                display: flex;
                flex-flow: row wrap;
                justify-content: space-around;
                .feedbackBoxItem {
                    max-width: 400px;
                    display: flex;
                    flex-flow: column;
                    background: #FFFFFF;
                    border: 1px solid #93B7E1;
                    box-shadow: 0px 0px 8px #aec6cf;
                    border-radius: 0px 0px 10px 10px;
                    margin: 10px 20px;
                    &:hover {
                        .boxAccent {
                            background-color: #FF8B02;
                        }
                    }
                    .boxAccent {
                        height: 5px;
                        border-radius: 0 0 10px 10px;
                        background-color: #93B7E1;
                    }
                    .boxTitleBox {
                        padding: 5px 20px 10px;
                        border-bottom: 1px solid #DCDCDC;
                        min-height: 83px;
                        .boxTitle {
                            font-size: 1.5rem;
                            font-weight: 700;
                            color: #1D4370;
                        }
                        .boxSubTitle {
                            color: #618299;
                        }
                    }
                    .boxAdditionalInfoBox {
                        padding: 10px 20px;
                        display: flex;
                        flex-flow: column;
                        flex-grow: 1;
                        .boxAdditionalInfoBoxItem {
                            display: flex;
                            flex-flow: row;
                            align-items: flex-start;
                            .boxAdditionalInfoBoxItemNumber {
                                font-size: 1.5rem;
                                color: #004290;
                                margin-right: 10px;
                                min-width: 25px;
                            }
                            .boxAdditionalInfoBoxItemText {
                                color: #555;
                                margin-bottom: 15px;
                            }
                        }
                    }
                    .boxFormConsistentBehaviour {
                        .editorBlock {
                            margin: 10px;
                            label {
                                color: #004290;
                                font-weight: 600;
                            }
                        }
                    }
                    .boxFormInconsistentBehaviour {
                        .editorBlock {
                            margin: 10px;
                            label {
                                color: #BF3E3E;
                                font-weight: 600;
                            }
                        }
                    }
                }
            }
        }
    }
}

[data-theme='dark'] #formIDPEmployeeFeedbackBox {
    #formIDPEmployeeFeedback {
        #formIDPEmployeeFeedbackFirstRow {
            #formIDPEmployeeFeedbackFirstRowBox {
                color: #A0A0C2;
                #formIDPEmployeeFeedbackFirstRowBoxIcon {
                    svg {
                        path {
                            fill: #A0A0C2;
                        }
                    }
                }
            }
        }
        #IDPFeedbackBox {
            #boxContent-IDPFeedbackBox {
                .feedbackBoxItem {
                    background: #2c2d44;
                    border: 1px solid #13151e;
                    box-shadow: 0px 0px 8px #13151e;
                    &:hover {
                        .boxAccent {
                            background-color: #FF8B02;
                        }
                    }
                    .boxAccent {
                        background-color: #A0A0C2;
                    }
                    .boxTitleBox {
                        border-bottom: 1px solid #494B6D;
                        .boxTitle {
                            color: #fff;
                        }
                        .boxSubTitle {
                            color: #A0A0C2;
                        }
                    }
                    .boxAdditionalInfoBox {
                        .boxAdditionalInfoBoxItem {
                            .boxAdditionalInfoBoxItemNumber {
                                color: #004290;
                            }
                            .boxAdditionalInfoBoxItemText {
                                color: #A0A0C2;
                            }
                        }
                    }
                    .boxFormConsistentBehaviour {
                        .editorBlock {
                            label {
                                color: #fff;
                            }
                        }
                    }
                    .boxFormInconsistentBehaviour {
                        .editorBlock {
                            label {
                                color: #BF3E3E;
                            }
                        }
                    }
                }
            }
        }
    }
}