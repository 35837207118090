#IDPEmployeeAreaBox {
    .noData {
        padding: 20px;
        font-size: 1rem;
        text-align: center;
    }
}

[data-theme='dark'] #IDPEmployeeAreaBox {
    .noData {
        color: #A0A0C2;
    }
}

#approveIDP {
    text-align: center;
    padding: 20px 0;
}