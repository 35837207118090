#userPlansTrainingsEnrollmentBox {
    #userPlansTrainingsEnrollmentBasicInformation {
        display: flex;
        flex-flow: row wrap;
        padding: 10px 20px;
        .planNameBox {
            flex-grow: 1;
            .titleName {
                width: 100%;
                color: #555;
            }
            .name {
                width: 100%;
                color: #FF8B02;
                font-size: 1.5rem;
                font-weight: 600;
            }
        }
        .dateBox {
            display: flex;
            flex-flow: row wrap;
            .dateContent {
                display: flex;
                flex-flow: row wrap;
                max-width: 280px;
                max-height: 100px;
                &#validationDate {
                    max-width: 200px;
                }
                .title {
                    width: 100%;
                    margin-bottom: 12px;
                    color: #555555;
                }
                .content {
                    width: 100%;
                    display: flex;
                    flex-flow: row nowrap;
                    .icon {
                        margin-right: 20px;
                    }
                    .dateDetail {
                        display: flex;
                        flex-flow: row wrap;
                        div {
                            width: 100%;
                            color: #1D4370;
                            span {
                                font-size: 1.25rem;
                                margin-left: 10px;
                                &.start {
                                    color: #1D4370;
                                }
                            }
                        }
                        &#validationDateBoxDate {
                            #validationDateEnd {
                                padding-top: 10px;
                                span {
                                    margin-left: 0;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    #userPlansTrainingsSelectTrainings {
        text-align: center;
        padding: 10px 0;
    }
    #approveTrainingsPlans {
        text-align: center;
        padding: 20px 0;
    }
}