@import './../../../scss/variableCustom.scss';
.searchBox {
    width: 45px;
    height: 38px;
    .iconBox {
        border-radius: 16px;
        margin: 5px 5px 0;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 32px;
        height: 32px;
    }
    .blockSearch {
        display: none;
        transition-delay: 5s;
    }
    &.activeSearch {
        // background-color: #074B32;
        color: #fff;
        .iconBox {
            background-color: #FF8B02;
            svg {
                path {
                    fill: #fff;
                }
            }
        }
    }
    &:hover,
    &.active {
        background-color: #D3D7D6;
        border-radius: 30px 30px 0 0;
        border-left-width: 1px;
        border-left-style: solid;
        border-left-color: #C1C0C0;
        border-right-width: 1px;
        border-right-style: solid;
        border-right-color: #C1C0C0;
        border-top-width: 1px;
        border-top-style: solid;
        border-top-color: #C1C0C0;
        box-shadow: 0px 3px 8px rgba(7, 75, 50, 0.3);
        .iconBox {
            background-color: #FF8B02;
            svg {
                path {
                    fill: #fff;
                }
            }
        }
        .blockSearch {
            color: #555555;
            display: block;
            position: absolute;
            background-color: #D3D7D6;
            min-width: 220px;
            max-width: 220px;
            transition-delay: 5s;
            text-align: left;
            padding: 7px 10px;
            border: 1px solid #959595;
            border-radius: 10px;
            box-shadow: 0px 4px 10px rgba(7, 75, 50, 0.3);
            font-weight: 400;
            z-index: 1;
            &.left {
                margin-left: calc(-170px + 25px);
            }
            &.center {
                margin-left: calc(-170px / 2 + 10px);
            }
            &.right {
                margin-left: calc(-5px);
            }
            label {
                margin-left: 5px;
                width: 100%;
            }
            input {
                width: 100%;
                border-radius: 5px;
                border: 1px solid #C3CDDB;
                height: 30px;
            }
            .buttonBox {
                margin-top: 12px;
                display: flex;
                flex-flow: row nowrap;
                justify-content: space-between;
                align-items: center;
                .buttonSubmit {
                    background-color: #1D4370;
                    border-radius: 30px;
                    height: 30px;
                    width: 90px;
                    align-items: center;
                    justify-content: center;
                    display: flex;
                    color: #fff;
                }
                .buttonClear {
                    color: #555555;
                    text-decoration: underline;
                }
            }
            .selectSearchCustom {
                .selectSearchCustom__menu {
                    z-index: 10;
                }
                .selectSearchCustom__control {
                    min-height: 30px;
                    border: 1px solid #93B7E1;
                    &:hover {
                        border: 1px solid #93B7E1;
                        box-shadow: 0px 0px 8px #CBD4DF;
                    }
                    &.selectSearchCustom__control--is-focused {
                        border: 1px solid #7c96be;
                        box-shadow: 0px 0px 8px #CBD4DF;
                        .selectSearchCustom__indicators {
                            .selectSearchCustom__indicator {
                                svg {
                                    path {
                                        fill: #7c96be;
                                    }
                                }
                            }
                        }
                    }
                    .selectSearchCustom__indicators {
                        .selectSearchCustom__indicator-separator {
                            background: none;
                        }
                        .selectSearchCustom__indicator {
                            padding: 4px;
                            svg {
                                path {
                                    fill: #93B7E1;
                                }
                            }
                        }
                    }
                    .selectSearchCustom__value-container {
                        input {
                            height: auto;
                        }
                    }
                }
                .selectSearchCustom__menu {
                    background-color: #fff;
                    .selectSearchCustom__menu-list {
                        .selectSearchCustom__option {
                            color: #618299;
                            &.selectSearchCustom__option--is-focused {
                                background-color: #F1F6F5;
                                color: #000;
                            }
                            &.selectSearchCustom__option--is-selected {
                                background-color: #1D4370 !important;
                                color: #fff;
                            }
                        }
                    }
                }
            }
            .multiSelectSearchCustom {
                .multiSelectSearchCustom__control {
                    background: #FFFFFF;
                    border: 1px solid #93B7E1;
                    box-sizing: border-box;
                    border-radius: 5px;
                    min-height: 30px;
                    &:hover {
                        border: 1px solid #93B7E1;
                        box-shadow: 0px 0px 8px #CBD4DF;
                    }
                    &.multiSelectSearchCustom__control--is-focused {
                        border: 1px solid #7c96be !important;
                        box-shadow: 0px 0px 8px #CBD4DF;
                        .multiSelectSearchCustom__indicators {
                            .multiSelectSearchCustom__indicator {
                                svg {
                                    path {
                                        fill: #7c96be;
                                    }
                                }
                            }
                        }
                    }
                    .multiSelectSearchCustom__value-container {
                        padding: 0px 8px;
                        .multiSelectSearchCustom__multi-value {
                            background-color: #FFFFFF;
                            border: 1px solid #93B7E1;
                            border-radius: 5px;
                            margin: 0 2px;
                            .multiSelectSearchCustom__multi-value__label {
                                color: #555;
                                font-size: 0.875rem;
                                padding: 1px 7px;
                            }
                            .multiSelectSearchCustom__multi-value__remove {
                                svg {
                                    fill: #93B7E1;
                                }
                                &:hover {
                                    background-color: #7CBEA5;
                                    svg {
                                        fill: #000;
                                    }
                                }
                            }
                        }
                        input {
                            height: 20px;
                            color: #fff !important;
                            box-shadow: none !important;
                        }
                    }
                    .multiSelectSearchCustom__indicators {
                        .multiSelectSearchCustom__indicator-separator {
                            background-color: #93B7E1;
                        }
                        .multiSelectSearchCustom__dropdown-indicator {
                            padding: 4px;
                            svg {
                                fill: #93B7E1;
                            }
                        }
                        .multiSelectSearchCustom__clear-indicator {
                            padding: 4px;
                            svg {
                                fill: #93B7E1;
                            }
                        }
                    }
                }
            }
        }
    }
}

[data-theme='dark'] .searchBox {
    .iconBox {
        svg {
            path {
                fill: rgba(255, 255, 255, 0.5);
            }
        }
    }
    .blockSearch {
        display: none;
        transition-delay: 5s;
    }
    &.activeSearch {
        // background-color: #074B32;
        color: #fff;
        .iconBox {
            background-color: #FF8B02;
            svg {
                path {
                    fill: #fff;
                }
            }
        }
    }
    &:hover,
    &.active {
        background-color: #494B6D;
        border-left-color: #C1C0C0;
        border-right-color: #C1C0C0;
        border-top-color: #C1C0C0;
        box-shadow: 0px 3px 8px rgba(7, 75, 50, 0.3);
        .iconBox {
            background-color: #FF8B02;
            svg {
                path {
                    fill: #fff;
                }
            }
        }
        .blockSearch {
            color: #D6D6F1;
            display: block;
            position: absolute;
            background-color: #494B6D;
            min-width: 220px;
            max-width: 220px;
            transition-delay: 5s;
            text-align: left;
            padding: 7px 10px;
            border: 1px solid #959595;
            border-radius: 10px;
            box-shadow: 0px 4px 10px rgba(7, 75, 50, 0.3);
            font-weight: 400;
            z-index: 1;
            &.left {
                margin-left: calc(-170px + 25px);
            }
            &.center {
                margin-left: calc(-170px / 2 + 10px);
            }
            &.right {
                margin-left: calc(-5px);
            }
            label {
                margin-left: 5px;
                width: 100%;
            }
            input {
                border: 1px solid #6A6A90;
                background-color: #2C2D44;
                color: #A0A0C2;
                &:hover {
                    box-shadow: 0px 0px 8px rgba(255, 255, 255, 0.25);
                    border: 1px solid #6A6A90;
                }
                &:focus {
                    color: #C2C2E2;
                    box-shadow: 0px 0px 8px rgba(255, 255, 255, 0.25);
                    border: 1px solid #8787AC;
                }
            }
            .selectSearchCustom {
                .selectSearchCustom__control {
                    background: #2C2D44;
                    border: 1px solid #6A6A91;
                    &:hover {
                        box-shadow: 0px 0px 8px rgba(255, 255, 255, 0.25);
                        border-color: #6A6A90 !important;
                    }
                    &.selectSearchCustom__control--is-focused {
                        box-shadow: 0px 0px 8px rgba(255, 255, 255, 0.25);
                        border-color: #8787AC !important;
                        .selectSearchCustom__indicators {
                            .selectSearchCustom__indicator {
                                svg {
                                    path {
                                        fill: #8787AC;
                                    }
                                }
                            }
                        }
                        .selectSearchCustom__value-container {
                            .selectSearchCustom__single-value {
                                color: #C2C2E2;
                            }
                        }
                    }
                    .selectSearchCustom__value-container {
                        .selectSearchCustom__single-value {
                            color: #A0A0C2;
                        }
                    }
                    .selectSearchCustom__indicators {
                        .selectSearchCustom__indicator-separator {
                            background: none;
                        }
                        .selectSearchCustom__indicator {
                            svg {
                                path {
                                    fill: #6A6A90;
                                }
                            }
                        }
                    }
                }
                .selectSearchCustom__menu {
                    background-color: #2C2D44;
                    color: #fff;
                    .selectSearchCustom__menu-list {
                        .selectSearchCustom__option {
                            color: #A0A0C2;
                            &.selectSearchCustom__option--is-focused {
                                background-color: #1E202D;
                                color: #fff;
                            }
                            &.selectSearchCustom__option--is-selected {
                                background-color: #1D4370 !important;
                                color: #fff;
                            }
                        }
                    }
                }
            }
            .multiSelectSearchCustom {
                .multiSelectSearchCustom__control {
                    background: #2C2D44;
                    border: 1px solid #6A6A91;
                    &:hover {
                        border: 1px solid #9A9CD4;
                        box-shadow: 0px 0px 8px rgba(255, 255, 255, 0.75);
                    }
                    &.multiSelectSearchCustom__control--is-focused {
                        border: 1px solid #CBCCE5 !important;
                        box-shadow: 0px 0px 8px rgba(255, 255, 255, 0.75);
                        .multiSelectSearchCustom__indicators {
                            .multiSelectSearchCustom__indicator {
                                svg {
                                    path {
                                        fill: #CBCCE5;
                                    }
                                }
                            }
                            .multiSelectSearchCustom__indicator-separator {
                                background-color: #CBCCE5;
                            }
                            .multiSelectSearchCustom__clear-indicator {
                                svg {
                                    fill: #CBCCE5;
                                }
                            }
                        }
                    }
                    .multiSelectSearchCustom__value-container {
                        .multiSelectSearchCustom__multi-value {
                            background-color: #36374F;
                            border: 1px solid #A0A0C2;
                            .multiSelectSearchCustom__multi-value__label {
                                color: #fff;
                            }
                            .multiSelectSearchCustom__multi-value__remove {
                                svg {
                                    fill: #A0A0C2;
                                }
                                &:hover {
                                    background-color: #4c4d6d;
                                    svg {
                                        fill: #CBCCE5;
                                    }
                                }
                            }
                            .multiSelectSearchCustom__placeholder {
                                color: #A0A0C2;
                            }
                        }
                    }
                    .multiSelectSearchCustom__indicators {
                        .multiSelectSearchCustom__indicator-separator {
                            background-color: #A0A0C2;
                        }
                        .multiSelectSearchCustom__dropdown-indicator {
                            padding: 4px;
                            svg {
                                fill: #A0A0C2;
                            }
                        }
                        .multiSelectSearchCustom__clear-indicator {
                            svg {
                                fill: #A0A0C2;
                            }
                        }
                    }
                }
                .multiSelectSearchCustom__menu {
                    background-color: #36374F;
                    .multiSelectSearchCustom__menu-list {
                        .multiSelectSearchCustom__option {
                            color: #fff;
                            &.multiSelectSearchCustom__option--is-focused {
                                background-color: #1E202D;
                            }
                            &.multiSelectSearchCustom__option--is-selected {
                                background-color: #AF5555 !important;
                            }
                        }
                    }
                }
            }
            .buttonBox {
                .buttonSubmit {
                    background-color: #1D4370;
                    color: #fff;
                }
                .buttonClear {
                    color: #fff;
                }
            }
        }
    }
}