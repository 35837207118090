#EmployeeAppraisalBox {
    .boxContent {
        margin-top: 5px;
        #basicInformation {
            padding: 10px 20px;
            border-bottom: 1px solid #DCDCDC;
            margin-bottom: 10px;
            #firstRowBasicInformation {
                display: flex;
                flex-flow: row;
                justify-content: space-between;
            }
            #secondColumn {
                #detailPurposeEmplyee {
                    color: #555;
                    button {
                        margin-left: 5px;
                        min-width: 100px;
                    }
                }
            }
        }
        #dataEmployee {
            color: #FF8B02;
            font-size: 1.5rem;
            font-weight: 600;
        }
        #goal {
            margin-top: 5px;
            #goalData {
                display: flex;
                flex-flow: row;
                padding: 5px 0;
                #goalText {
                    margin-top: 0;
                    .data {
                        color: #618299;
                        padding: 0 0 0 10px;
                    }
                }
            }
        }
        #EmployeeAppraisalTabs {
            margin-bottom: 0;
        }
        #EmployeeAppraisalContentBox {
            box-shadow: 0px -12px 20px rgba(7, 75, 50, 0.15);
        }
    }
}

[data-theme='dark'] #EmployeeAppraisalBox {
    .boxContent {
        #basicInformation {
            border-bottom: 1px solid #494B6D;
            #secondColumn {
                #detailPurposeEmplyee {
                    color: #A0A0C2;
                }
            }
        }
        #dataEmployee {
            color: #FF8B02;
        }
        #goal {
            #goalData {
                #goalText {
                    .data {
                        color: #A0A0C2;
                    }
                }
            }
        }
        #EmployeeAppraisalTabs {
            margin-bottom: 0;
        }
        #EmployeeAppraisalContentBox {
            box-shadow: 0px -12px 20px rgba(7, 75, 50, 0.15);
        }
    }
}