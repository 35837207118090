#employeeIDPSelectYear {
  .yearToSelect {
    color: #618299;
    padding: 5px;
    border-bottom: 1px solid #ededed;
    &:hover {
      background-color: #ecf6f3;
    }
  }
  .noIDPList {
    padding: 5px;
    text-align: center;
  }
  padding-bottom: 5px;
}
