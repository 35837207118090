.selectPagination {
    margin-left: 7px;
    .selectPagination__control {
        background: #FFFFFF;
        border: 1px solid #93B7E1;
        box-sizing: border-box;
        border-radius: 5px;
        min-height: 30px;
        /* &:hover {
            border-color: #00427d;
        }
        &.selectPagination__control--is-focus {
            box-shadow: 0 0 0 0.2rem rgba(0, 125, 78, 0.5);
        }
        &.selectPagination__control--menu-is-open {
            border-color: #00427d;
        } */
        .selectPagination__value-container {
            padding: 2px 18px 2px 8px;
        }
        .selectPagination__indicators {
            .selectPagination__indicator-separator {
                background-color: #fff;
                width: 0;
            }
            .selectPagination__dropdown-indicator {
                padding: 0.313rem 0.313rem 0.313rem 0;
                svg {
                    path {
                        fill: #93B7E1;
                    }
                }
            }
        }
    }
    .selectPagination__menu {
        .selectPagination__menu-list {
            .selectPagination__option {
                text-align: center;
            }
        }
    }
}

[data-theme='dark'] .selectPagination {
    .selectPagination__control {
        background: #4D4E68;
        border: 1px solid #4D4E68;
        /* &:hover {
            border-color: #00427d;
        }
        &.selectPagination__control--is-focus {
            box-shadow: 0 0 0 0.2rem rgba(0, 125, 78, 0.5);
        }
        &.selectPagination__control--menu-is-open {
            border-color: #00427d;
        } */
        &.selectPagination__control--is-focused {
            box-shadow: 0 0 0 1px #1E202D;
        }
        .selectPagination__indicators {
            .selectPagination__indicator-separator {
                background-color: #fff;
                width: 0;
            }
            .selectPagination__dropdown-indicator {
                padding: 0.313rem 0.313rem 0.313rem 0;
                svg {
                    path {
                        fill: #A0A0C2;
                    }
                }
            }
        }
        .selectPagination__value-container {
            .selectPagination__single-value {
                color: #D6D6F1;
            }
        }
    }
    .selectPagination__menu {
        background-color: #4D4E68;
        .selectPagination__menu-list {
            .selectPagination__option {
                text-align: center;
                &.selectPagination__option--is-focused {
                    background-color: #1E202D;
                }
                &.selectPagination__option--is-selected {
                    background-color: #1D4370 !important;
                }
            }
        }
    }
}

.selectBlock {
    &.withLabel {
        margin-right: calc(0.65rem + 18px);
        margin-top: 5px;
        margin-bottom: 5px;
        .form-label {
            color: #1D4370;
        }
    }
    &.noInfo {
        margin-right: 0;
    }
    &.required {
        .blockInputWithInfo {
            .selectCustom {
                .selectCustom__control {
                    background-color: #F1F6F5;
                    border-color: #93B7E1;
                    &.selectCustom__control--is-focused {
                        border: 1px solid #5f80a5;
                        box-shadow: 0px 0px 8px rgba(0, 94, 150, 0.5);
                        .selectCustom__indicators {
                            .selectCustom__dropdown-indicator {
                                svg {
                                    path {
                                        fill: #5f80a5;
                                    }
                                }
                            }
                        }
                    }
                    .selectCustom__value-container {
                        .selectCustom__placeholder {
                            color: #555;
                        }
                    }
                    .selectCustom__indicators {
                        .selectCustom__indicator-separator {
                            background-color: #F1F6F5;
                            width: 0;
                        }
                        .selectCustom__dropdown-indicator {
                            padding: 0.313rem 0.313rem 0.313rem 0;
                            svg {
                                path {
                                    fill: #93B7E1;
                                }
                            }
                        }
                    }
                }
                .selectCustom__menu {
                    background-color: #F1F6F5;
                    .selectCustom__menu-list {
                        .selectCustom__option {
                            color: #618299;
                            &.selectCustom__option--is-focused {
                                background-color: #e5e9e8;
                                color: #000;
                            }
                            &.selectCustom__option--is-selected {
                                background-color: #1D4370 !important;
                                color: #fff;
                            }
                        }
                    }
                }
            }
            .disabledSelect {
                color: #555;
                padding: 0.375rem 0.75rem;
            }
        }
    }
    &.error {
        .blockInputWithInfo {
            .selectCustom {
                .selectCustom__control {
                    border-color: #BF3E3E;
                }
            }
        }
        .additionalInfo {
            .alertInfo {
                color: #BF3E3E;
                margin-bottom: 0;
            }
        }
    }
    &.height33 {
        .blockInputWithInfo {
            .selectCustom {
                .selectCustom__control {
                    min-height: 33px;
                }
            }
        }
    }
    .blockInputWithInfo {
        .selectCustom {
            .selectCustom__menu {
                z-index: 10;
            }
            .selectCustom__control {
                min-height: 30px;
                border: 1px solid #93B7E1;
                height: 31px;
                &:hover {
                    border: 1px solid #93B7E1;
                    box-shadow: 0px 0px 8px #CBD4DF;
                }
                &.selectCustom__control--is-focused {
                    border: 1px solid #7c96be;
                    box-shadow: 0px 0px 8px #CBD4DF;
                    .selectCustom__indicators {
                        .selectCustom__indicator {
                            svg {
                                path {
                                    fill: #7c96be;
                                }
                            }
                        }
                    }
                }
                .selectCustom__indicators {
                    .selectCustom__indicator-separator {
                        background: none;
                    }
                    .selectCustom__indicator {
                        padding: 4px;
                        svg {
                            path {
                                fill: #93B7E1;
                            }
                        }
                    }
                }
            }
            .selectCustom__menu {
                background-color: #fff;
                .selectCustom__menu-list {
                    .selectCustom__option {
                        color: #618299;
                        &.selectCustom__option--is-focused {
                            background-color: #F1F6F5;
                            color: #000;
                        }
                        &.selectCustom__option--is-selected {
                            background-color: #1D4370 !important;
                            color: #fff;
                        }
                    }
                }
            }
        }
    }
}

[data-theme='dark'] .selectBlock {
    .form-label {
        color: #fff;
    }
    &.required {
        .blockInputWithInfo {
            .selectCustom {
                .selectCustom__control {
                    background-color: #4c4d6d;
                    border-color: #9a9cd4;
                    .selectCustom__value-container {
                        .selectCustom__placeholder {
                            color: #9a9cd4;
                        }
                    }
                    .selectCustom__indicators {
                        .selectCustom__indicator-separator {
                            background-color: #4D4E68;
                            width: 0;
                        }
                        .selectCustom__dropdown-indicator {
                            svg {
                                path {
                                    fill: #D6D6F1;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .blockInputWithInfo {
        .selectCustom {
            .selectCustom__control {
                background: #2C2D44;
                border: 1px solid #6A6A91;
                &:hover {
                    box-shadow: 0px 0px 8px rgba(255, 255, 255, 0.75);
                    border: 1px solid #9A9CD4;
                }
                &.selectCustom__control--is-focused {
                    box-shadow: 0px 0px 8px rgba(255, 255, 255, 0.75);
                    border: 1px solid #CBCCE5;
                }
                .selectCustom__indicators {
                    .selectCustom__indicator-separator {
                        background-color: #2C2D44;
                    }
                    .selectCustom__dropdown-indicator {
                        svg {
                            path {
                                fill: #6A6A90;
                            }
                        }
                    }
                }
                .selectCustom__value-container {
                    .selectCustom__single-value {
                        color: #D6D6F1;
                    }
                    .selectCustom__placeholder {
                        color: #D6D6F1;
                    }
                }
            }
            .selectCustom__menu {
                background-color: #2C2D44;
                .selectCustom__menu-list {
                    .selectCustom__option {
                        color: #fff;
                        &.selectCustom__option--is-focused {
                            background-color: #1E202D;
                            color: #fff;
                        }
                        &.selectCustom__option--is-selected {
                            background-color: #1D4370 !important;
                            color: #fff;
                        }
                    }
                }
            }
        }
    }
}