@import './../../scss/variableCustom.scss';
.root {
    min-height: 100vh;
    display: flex;
    flex-flow: column nowrap;
    width: 100%;
    z-index: 0;
    .topMenuFull {
        height: 80px;
        min-height: 80px;
        width: 100%;
        display: flex;
        flex-flow: row nowrap;
        background-color: $topMenuBackgroundColor;
        color: $topMenuColor;
        .topMenuElement {
            display: flex;
            flex-flow: row nowrap;
            flex-grow: 1;
            height: 100%;
            .topMenuElementItem {
                color: $topMenuColor;
                fill: $topMenuColor;
                text-align: center;
                display: flex;
                flex-flow: row nowrap;
                cursor: pointer;
                text-decoration: none;
                * {
                    transition: 0.5s;
                }
                &:first-child {
                    &.active {
                        .beforeAccent {
                            display: none;
                        }
                    }
                }
                &:hover {
                    color: $topMenuColorActive;
                    .topMenuElementItemBox {
                        .topMenuElementItemSpan {
                            svg {
                                path {
                                    fill: $topMenuColorActive;
                                }
                            }
                        }
                    }
                }
                .topMenuElementItemBox {
                    flex-grow: 1;
                    display: flex;
                    flex-flow: column nowrap;
                    .topMenuElementItemSpan {
                        margin: auto 1vw !important;
                        display: flex;
                        flex-flow: row nowrap;
                        svg {
                            margin-right: 10px;
                            height: 20px;
                        }
                    }
                    .topMenuElementItemAccent {
                        height: 5px;
                        border-radius: 0 0 10px 10px;
                    }
                }
                .beforeAccent {
                    display: none;
                }
                .afterAccent {
                    margin-right: -10px;
                    display: none;
                }
                &.active {
                    background-color: $topMenuItemActiveBackground;
                    color: $topMenuColorActive;
                    .beforeAccent {
                        display: flex;
                        margin-left: -10px;
                        svg {
                            path {
                                fill: $topMenuItemActiveBackground;
                            }
                        }
                    }
                    .afterAccent {
                        display: flex;
                        margin-right: -10px;
                        svg {
                            path {
                                fill: $topMenuItemActiveBackground;
                            }
                        }
                    }
                    .topMenuElementItemBox {
                        .topMenuElementItemAccent {
                            background-color: $topMenuAccent;
                        }
                        .topMenuElementItemSpan {
                            color: $topMenuColorActive;
                            svg {
                                path {
                                    fill: $topMenuColorActive;
                                }
                            }
                        }
                    }
                }
            }
        }
        @media (max-width: 1314px) {
            .topMenuElement {
                display: none;
            }
        }
        .topMenuUser {
            display: flex;
            height: 100%;
            background-color: $userMenuBackgroundColor;
            color: $userMenuTextColor;
            fill: $userMenuTextColor;
            flex-flow: row nowrap;
            align-items: center;
            .topMenuUserBlock {
                display: flex;
                flex-grow: 1;
                margin: auto;
                svg {
                    margin: 28px 10px;
                    height: 20px;
                }
                div {
                    display: flex;
                    margin-right: 10px;
                    flex-flow: column;
                    align-items: center;
                    justify-content: center;
                }
                @media (max-width: 1314px) {
                    svg {
                        margin: 28px 28px;
                    }
                    div {
                        display: none;
                    }
                }
            }
        }
        .topMenuLogout {
            display: flex;
            height: 100%;
            background-color: $logoutMenuBackgroundColor;
            color: $logoutMenuTextColor;
            min-width: 80px;
            width: 80px;
            .topMenuLogoutBlock {
                display: flex;
                flex-grow: 1;
                margin: auto;
                height: 100%;
                cursor: pointer;
                svg {
                    margin: auto;
                    height: 24px;
                    path {
                        fill: $logoutMenuTextColor;
                    }
                }
            }
        }
    }
}

[data-theme='dark'] .root {
    .topMenuFull {
        background-color: $topMenuBackgroundColorDark;
        color: $topMenuColorDark;
        .topMenuElement {
            .topMenuElementItem {
                color: $topMenuColorDark;
                .topMenuElementItemBox {
                    .topMenuElementItemSpan {
                        svg {
                            path {
                                fill: $topMenuColorDark;
                            }
                        }
                    }
                }
                &:hover {
                    color: $topMenuColorActiveDark;
                    .topMenuElementItemBox {
                        .topMenuElementItemSpan {
                            svg {
                                path {
                                    fill: $topMenuColorActiveDark;
                                }
                            }
                        }
                    }
                }
                &.active {
                    background-color: $topMenuItemActiveBackgroundDark;
                    color: $topMenuColorActiveDark;
                    .beforeAccent {
                        svg {
                            path {
                                fill: $topMenuItemActiveBackgroundDark;
                            }
                        }
                    }
                    .afterAccent {
                        svg {
                            path {
                                fill: $topMenuItemActiveBackgroundDark;
                            }
                        }
                    }
                    .topMenuElementItemBox {
                        .topMenuElementItemAccent {
                            background-color: $topMenuAccentDark;
                        }
                        .topMenuElementItemSpan {
                            color: $topMenuColorActiveDark;
                            svg {
                                path {
                                    fill: $topMenuColorActiveDark;
                                }
                            }
                        }
                    }
                }
            }
        }
        .topMenuUser {
            background-color: $userMenuBackgroundColorDark;
            color: $userMenuTextColorDark;
            fill: $userMenuTextColorDark;
        }
        .topMenuLogout {
            background-color: $logoutMenuBackgroundColorDark;
            color: $logoutMenuTextColorDark;
            .topMenuLogoutBlock {
                svg {
                    path {
                        fill: $logoutMenuTextColorDark;
                    }
                }
            }
        }
    }
    .topBreadcrumb {
        background-color: $blockBackgroundColorDark;
        color: $topBreadcrumbColorDark;
        fill: $topBreadcrumbColorDark;
        .topBreadcrumbBlock {
            color: #fff;
            .topBreadcrumbElement {
                span {
                    a {
                        color: #fff;
                    }
                }
                .topBreadcrumbElementActive {
                    color: $topBreadcrumbColorActiveDark;
                }
            }
        }
    }
    .contentFull {
        background-color: $blockBackgroundColorDark;
        color: $blockTextColorDark;
    }
    .footer {
        background-color: $footerBackgroundColorDark;
        color: $footerTextColorDark;
        .logoFooter {
            svg {
                path {
                    fill: $footerTextColorDark;
                }
            }
        }
    }
}

.topBreadcrumb {
    display: flex;
    background-color: $blockBackgroundColor;
    color: $topBreadcrumbColor;
    fill: $topBreadcrumbColor;
    padding: 0 21px;
    color: #1D4370;
    .topBreadcrumbBlock {
        display: flex;
        flex-grow: 1;
        margin: 3vh 0;
        .topBreadcrumbElement {
            margin: 0;
            span {
                a {
                    color: #1D4370;
                    font-weight: 600;
                }
            }
        }
        .topBreadcrumbElementActive {
            color: $topBreadcrumbColorActive;
            font-weight: 600;
        }
    }
}

.contentFull {
    display: flex;
    flex-flow: column nowrap;
    flex-grow: 1;
    overflow-x: auto;
    background-color: $blockBackgroundColor;
    color: $blockTextColor;
    .contentBlock {
        display: flex;
        flex-grow: 1;
        padding: 0 23px;
    }
}

.footer {
    height: 10vh;
    min-height: 50px;
    max-height: 50px;
    display: flex;
    flex-flow: row nowrap;
    background-color: $footerBackgroundColor;
    color: $footerTextColor;
    flex-grow: 1;
    justify-content: flex-end;
    align-items: center;
    .logoFooter {
        margin: 0 27px 0 19px;
        svg {
            path {
                fill: $footerTextColor;
            }
        }
    }
}