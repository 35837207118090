@import './../../../../scss/variableCustom.scss';
.tableCellImport {
    display: flex;
    align-items: center;
    &.toLeft {
        justify-content: flex-start;
    }
    &.center {
        justify-content: center;
    }
    &.toRight {
        justify-content: flex-end;
    }
    .buttonAction {
        display: flex;
        background-color: rgba(153, 191, 214, 0.6);
        width: 32px;
        height: 32px;
        border-radius: 16px;
        justify-content: center;
        align-items: center;
        margin: 0 2px;
        path {
            fill: #1D4370;
        }
        &:hover {
            background-color: #1D4370;
            path {
                fill: #FFFFFF;
            }
        }
    }
}

[data-theme='dark'] .tableCellImport {
    .buttonAction {
        background-color: #7EA1CA;
        path {
            fill: #CBD4DF;
        }
        &:hover {
            background-color: #1D4370;
            path {
                fill: #FFFFFF;
            }
        }
    }
}