@import './../../scss/variableCustom.scss';
.menuFull {
    @media (min-width: 0) {
        display: none;
    }
    @media (min-width: 1315px) {
        display: flex;
        width: 1vw;
        min-width: 80px;
        max-width: 320px;
        flex-flow: column nowrap;
        background-color: $background;
        transition: width 0.5s;
        transition-delay: 0.4s;
        * {
            transition: width 0.5s;
            transition-delay: 0.4s;
        }
        &:hover {
            width: 20vw;
            max-width: 320px;
            //transition: width 0.5s;
        }
        &.show {
            width: 20vw;
        }
        .menuTop {
            display: flex;
            height: 100vh;
            max-height: 80px;
            max-width: 320px;
            width: 100%;
            background-color: $background;
            overflow: hidden;
            flex-flow: row nowrap;
            .logoIconBox {
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: $logoIconBox;
                width: 80px;
                min-width: 80px;
                height: 80px;
                color: $fontColor;
                path {
                    fill: #fff;
                }
            }
            .logoBox {
                display: flex;
                flex-grow: 1;
                align-items: center;
                max-width: 240px;
                height: 80px;
                z-index: 1;
                .logo {
                    display: flex;
                    margin-left: 0.75vw;
                    cursor: pointer;
                    flex-grow: 1;
                    path {
                        fill: #fff;
                    }
                }
                .pinBox {
                    display: flex;
                    align-self: flex-start;
                    padding: 9px;
                    cursor: pointer;
                    .pinActive {
                        fill: $pin;
                    }
                    .pinUnactive {
                        stroke: $pin;
                    }
                }
            }
        }
        .menu {
            background-color: $background;
            display: flex;
            max-width: 320px;
            width: 100%;
            flex-grow: 1;
            overflow-y: auto;
            overflow-x: hidden;
            flex-flow: column;
            .linkItemMenu {
                display: flex;
                align-items: center;
                max-width: 320px;
                width: 100%;
                min-height: 80px;
                flex-flow: column nowrap;
                text-decoration: none;
                z-index: 1;
                cursor: pointer;
                &.active {
                    // background-color: $iconMenuActiveBackground;
                    align-items: flex-end;
                    &+.subMenu {
                        display: flex;
                        min-width: 20vw;
                    }
                    .accentBefore {
                        display: flex;
                        margin-top: -10px;
                        position: absolute;
                        margin-right: -1px;
                        path {
                            fill: $menuActiveBackground;
                        }
                    }
                    .accentAfter {
                        display: flex;
                        margin-bottom: -10px;
                        margin-right: -1px;
                        z-index: 0;
                        path {
                            fill: $menuActiveBackground;
                        }
                    }
                    .itemMenuBlock {
                        background-color: $iconMenuActiveBackground;
                        .accentActive {
                            width: 5px;
                            min-width: 5px;
                            border-radius: 0 10px 10px 0;
                            background-color: $menuActiveAccentColor;
                            height: 100%;
                        }
                        .iconItemMenu {
                            background-color: $iconMenuActiveBackground;
                            color: $iconMenuActiveColor;
                            path {
                                fill: $iconMenuActiveColor;
                            }
                        }
                        .itemMenu {
                            background-color: $menuActiveBackground;
                            color: $menuActiveColor;
                        }
                    }
                }
                .accentBefore {
                    display: none;
                }
                .accentAfter {
                    display: none;
                }
                .itemMenuBlock {
                    display: flex;
                    flex-flow: row nowrap;
                    max-width: 320px;
                    flex-grow: 1;
                    width: 100%;
                    .accentActive {
                        width: 5px;
                        min-width: 5px;
                        height: 100%;
                        background-color: $column;
                    }
                    .iconItemMenu {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        background-color: $column;
                        width: 75px;
                        min-width: 75px;
                        min-height: 80px;
                        svg {
                            margin-left: -5px;
                            path {
                                fill: #fff;
                            }
                        }
                    }
                    .itemMenu {
                        display: flex;
                        flex-grow: 1;
                        align-items: center;
                        max-width: 240px;
                        min-width: calc(20vw - 80px);
                        min-height: 80px;
                        padding-left: 0.75vw;
                        text-transform: uppercase;
                        color: $menuItem;
                        font-weight: 600;
                        line-height: 17px;
                        padding-right: 5px;
                    }
                }
            }
            .subMenu {
                display: none;
                flex-flow: row nowrap;
                z-index: 1;
                .fill {
                    display: flex;
                    flex-grow: 1;
                    width: 80px;
                    max-width: 80px;
                    min-width: 80px;
                    background-color: $column;
                }
                .subMenuItemBox {
                    display: flex;
                    flex-flow: column;
                    flex-grow: 1;
                    background-color: $menuActiveBackground;
                    padding-top: 6px;
                    .linkItemSubMenu {
                        margin-left: 1.76vw;
                        color: #6182AA;
                        margin-bottom: 7px;
                        &.active {
                            color: #ff8b02;
                        }
                    }
                }
            }
            .fill {
                display: flex;
                flex-grow: 1;
                width: 80px;
                max-width: 80px;
                min-width: 80px;
                background-color: $column;
            }
        }
        .menuBottom {
            display: flex;
            bottom: 0;
            min-height: 80px;
            width: 100%;
            max-width: 320px;
            background-color: $background;
            .wavesBox {
                display: flex;
                position: fixed;
                bottom: 0;
                left: 0;
                svg {
                    g {
                        path {
                            fill: $wavesColor;
                        }
                    }
                }
            }
            @media (min-width: 1385px) {
                .subIconBox {
                    display: flex;
                    position: fixed;
                    bottom: 85px;
                    left: 80px;
                    max-width: 240px;
                    justify-content: center;
                    width: 12vw;
                    svg {
                        path {
                            fill: $subIconColor;
                        }
                    }
                }
                .subLogoBox {
                    display: flex;
                    width: 100%;
                    bottom: 85px;
                    left: 100px;
                    align-items: flex-end;
                    justify-content: center;
                    flex-grow: 1;
                    z-index: 0;
                    padding-bottom: 32px;
                    svg {
                        path {
                            fill: $subLogoColor;
                            &.accent {
                                fill: $subLogoAccent;
                            }
                        }
                    }
                }
            }
            @media (max-width: 1385px) {
                .subIconBox {
                    display: none;
                }
                .subLogoBox {
                    display: none;
                }
            }
            .configBlock {
                display: flex;
                flex-flow: column nowrap;
                align-self: flex-end;
                width: 80px;
                min-height: 80px;
                background-color: $column;
                .configIcon {
                    display: flex;
                    justify-content: center;
                    width: 80px;
                    min-width: 80px;
                    height: 80px;
                    align-items: center;
                    z-index: 1;
                    &:hover {
                        background-color: $bacgroundIconActive;
                    }
                    &.active {
                        background-color: $bacgroundIconActive;
                    }
                    .icon {
                        cursor: pointer;
                        color: $fontColor;
                    }
                    .iconPath {
                        color: $fontColor;
                    }
                }
                .configBox {
                    width: 80px;
                    min-width: 80px;
                    background-color: $configBackground;
                    border-radius: 0px 10px 0px 0px;
                    padding: 0 5px;
                    z-index: 2;
                    p {
                        margin: 6px 0 0 0;
                        text-align: center;
                        color: $configFontColor;
                        font-weight: 400;
                    }
                    .configBoxIconBox {
                        display: flex;
                        flex-flow: row nowrap;
                        align-content: center;
                        justify-content: space-evenly;
                        align-items: baseline;
                        padding: 5px 10px 10px;
                    }
                    .configBoxIconBoxIcon {
                        cursor: pointer;
                        path {
                            fill: $configIconColorLight;
                        }
                        &.active {
                            path {
                                fill: $configIconColorDark;
                            }
                        }
                    }
                    .configBoxLine {
                        border-bottom: 1px solid $configBorder;
                    }
                }
            }
        }
    }
}

[data-theme='dark'] .menuFull {
    background-color: $backgroundDark;
    .menuTop {
        background-color: $backgroundDark;
        .logoIconBox {
            background-color: $logoIconBoxDark;
            color: $fontColorDark;
            path {
                fill: #fff;
            }
        }
        .logoBox {
            .pinBox {
                .pinActive {
                    fill: $pinDark;
                }
                .pinUnactive {
                    stroke: $pinDark;
                }
            }
        }
    }
    .menu {
        background-color: $backgroundDark;
        .linkItemMenu {
            &.active {
                .accentBefore {
                    path {
                        fill: $menuActiveBackgroundDark;
                    }
                }
                .accentAfter {
                    path {
                        fill: $menuActiveBackgroundDark;
                    }
                }
                .itemMenuBlock {
                    background-color: $iconMenuActiveBackgroundDark;
                    .accentActive {
                        background-color: $menuActiveAccentColorDark;
                    }
                    .iconItemMenu {
                        background-color: $iconMenuActiveBackgroundDark;
                        color: $iconMenuActiveColorDark;
                        path {
                            fill: $iconMenuActiveColorDark;
                        }
                    }
                    .itemMenu {
                        background-color: $menuActiveBackgroundDark;
                        color: $menuActiveColorDark;
                    }
                }
            }
            .itemMenuBlock {
                .accentActive {
                    background-color: $columnDark;
                }
                .iconItemMenu {
                    background-color: $columnDark;
                }
            }
        }
        .subMenu {
            .fill {
                background-color: $columnDark;
            }
            .subMenuItemBox {
                background-color: $menuActiveBackgroundDark;
                .linkItemSubMenu {
                    color: #fff;
                    margin-bottom: 7px;
                    &.active {
                        color: $accentDark;
                    }
                }
            }
        }
        .fill {
            background-color: $columnDark;
        }
    }
    .menuBottom {
        background-color: $backgroundDark;
        .wavesBox {
            fill: $wavesColorDark;
            svg {
                g {
                    path {
                        fill: $wavesColorDark;
                    }
                }
            }
        }
        .subIconBox {
            svg {
                path {
                    fill: $subIconColorDark;
                }
            }
        }
        .subLogoBox {
            svg {
                path {
                    fill: $subLogoColorDark;
                    &.accent {
                        fill: $subLogoAccentDark;
                    }
                }
            }
        }
        .configBlock {
            background-color: $columnDark;
            .configIcon {
                &:hover {
                    background-color: $bacgroundIconActiveDark;
                }
                &.active {
                    background-color: $bacgroundIconActiveDark;
                }
                .icon {
                    color: $fontColorDark;
                }
                .iconPath {
                    color: $fontColorDark;
                }
            }
            .configBox {
                background-color: $configBackgroundDark;
                p {
                    color: $configFontColorDark;
                }
                .configBoxIconBoxIcon {
                    cursor: pointer;
                    path {
                        fill: $configIconColorLightDark;
                    }
                    &.active {
                        path {
                            fill: $configIconColorDarkDark;
                        }
                    }
                }
                .configBoxLine {
                    border-bottom: 1px solid $configBorderDark;
                }
            }
        }
    }
}