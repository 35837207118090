@import './../../../../scss/variableCustom.scss';
.buttonAction {
    display: flex;
    background-color: #7EA1CA;
    width: 32px;
    height: 32px;
    border-radius: 16px;
    justify-content: center;
    align-items: center;
    margin: 0 2px;
    path {
        fill: #CBD4DF;
    }
    &:hover {
        background-color: #1D4370;
        path {
            fill: #FFFFFF;
        }
    }
}