.CustomAnimatedPrograssBar {
    .CircularProgressbar {
        .CircularProgressbar-trail {
            stroke: #fff;
        }
        .CircularProgressbar-path {
            stroke: #DCF2EA;
        }
        .CircularProgressbar-text {
            fill: #93B7E1;
            font-size: 30px;
            font-weight: 600;
            &:last-child {
                color: red;
            }
        }
    }
}

[data-theme='dark'] .CustomAnimatedPrograssBar {
    .CircularProgressbar {
        .CircularProgressbar-trail {
            stroke: #2c2d44;
        }
        .CircularProgressbar-path {
            stroke: #6B6B83;
        }
        .CircularProgressbar-text {
            fill: #49BACA;
            font-size: 30px;
            font-weight: 600;
        }
    }
}