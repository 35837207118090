.blockInputWithError {
    display: flex;
    flex-flow: column;
    align-items: flex-end;
    &.required {
        .form-label {
            font-weight: 600;
        }
        .form-control {
            background-color: #F1F6F5;
            border: 1px solid #93B7E1;
            color: #555555;
            &:hover {
                border: 1px solid #93B7E1;
                box-shadow: 0px 0px 8px rgba(0, 150, 94, 0.5);
            }
            &:focus {
                border: 1px solid #5FA58A;
                box-shadow: 0px 0px 8px rgba(0, 150, 94, 0.5);
                color: #1D4370;
            }
        }
    }
    &.withInfo {
        .blockInput {
            .blockInputWithInfo {
                .form-control {
                    margin-right: 0.65rem;
                }
            }
        }
    }
    &.disabled {
        .blockInput {
            padding: 0;
        }
    }
    &.fullWidth {
        .blockInput {
            justify-content: flex-start;
            flex-flow: column;
            .form-label {
                width: 100%;
                text-align: left;
                margin-bottom: 0.5rem;
            }
            .blockInputWithInfo {
                width: 100%;
            }
        }
        .additionalInfo {
            width: 100%;
        }
    }
    &.error {
        .blockInput {
            padding-bottom: 0;
            .blockInputWithInfo {
                .form-control {
                    border-color: #BF3E3E;
                }
            }
        }
    }
    &.toLeft {
        .blockInput {
            justify-content: flex-start;
            .form-label {
                text-align: left;
                // margin-bottom: 5px;
            }
            .blockInputWithInfo {
                width: auto;
                flex-grow: 1;
            }
        }
    }
    &.smallRadius {
        .blockInput {
            .blockInputWithInfo {
                input {
                    border-radius: 5px;
                }
            }
        }
    }
    .blockInput {
        width: 100%;
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-end;
        align-items: center;
        padding: 0.65rem 0;
        .form-label {
            padding-right: 0.65rem;
            //width: 40%;
            text-align: right;
            font-weight: 400;
            margin: 0;
            color: #1D4370;
        }
        .blockInputWithInfo {
            width: 60%;
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            .form-control {
                flex-grow: 1;
                margin-right: calc(0.65rem + 18px);
                border-radius: 5px;
                border: 1px solid #93B7E1;
                color: #555555;
                height: 31px;
                &:hover {
                    border: 1px solid #93B7E1;
                    box-shadow: 0px 0px 8px #CBD4DF;
                }
                &:focus {
                    border: 1px solid #7c8bbe;
                    box-shadow: 0px 0px 8px #CBD4DF;
                }
                &:disabled {
                    background: none;
                    border: none;
                    &:hover {
                        border: none;
                        box-shadow: none;
                    }
                }
                &:focus-visible {
                    text-shadow: none;
                }
            }
        }
    }
    .additionalInfo {
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: flex-start;
        width: 60%;
        .alertInfo {
            color: #BF3E3E;
            margin: 0;
        }
    }
}

[data-theme='dark'] .blockInputWithError {
    .form-control {
        background-color: #2C2D44;
        border: 1px solid #6A6A91;
    }
    &.required {
        .blockInput {
            .blockInputWithInfo {
                .form-control {
                    background-color: #4C4D6D;
                    border: 1px solid #9A9CD4;
                    color: #C2C2E2;
                    &:hover {
                        border: 1px solid #9A9CD4;
                        box-shadow: 0px 0px 8px rgba(255, 255, 255, 0.75);
                    }
                    &:focus {
                        border: 1px solid #CBCCE5;
                        box-shadow: 0px 0px 8px rgba(255, 255, 255, 0.75);
                        color: #FFFFFF;
                    }
                }
            }
        }
    }
    &.error {
        .blockInput {
            .blockInputWithInfo {
                .form-control {
                    border-color: #BF3E3E;
                }
            }
        }
    }
    .blockInput {
        .form-label {
            color: #fff;
        }
        .blockInputWithInfo {
            .form-control {
                background-color: #2C2D44;
                color: #A0A0C2;
                border-color: #6A6A91;
                &:hover {
                    border: 1px solid #6A6A90;
                    box-shadow: 0px 0px 8px rgba(255, 255, 255, 0.25);
                }
                &:focus {
                    color: #C2C2E2;
                    border: 1px solid #8787AC;
                    box-shadow: 0px 0px 8px rgba(255, 255, 255, 0.25);
                }
            }
            svg {
                path {
                    fill: #9a9cd4;
                }
            }
        }
    }
    .additionalInfo {
        .alertInfo {
            color: #BF3E3E;
        }
    }
}