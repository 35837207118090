@import './bootstrap.scss';
@import './table.scss';
@import './button.scss';
@import './alert.scss';
@import './font.scss';
html,
body {
    font-family: 'Exo 2.0', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    font-size: 0.875rem;
    font-weight: 400;
}

* {
    outline: 0;
}

a {
    text-decoration: none;
    outline: 0;
    &:hover {
        text-decoration: none;
    }
}

.form-control {
    border-radius: calc(1.5em + 0.75rem + 2px);
    border: 1px solid #93B7E1;
}

.hidden {
    display: none;
}

.center {
    text-align: Center;
}