#ShowIDPDetail {
    #shortTerm {
        margin-top: 8px;
        .boxContent {
            .showTextarea {
                .data {
                    padding-left: 50px;
                }
            }
        }
    }
    #longTerm {
        .boxContent {
            .showTextarea {
                .data {
                    padding-left: 50px;
                }
            }
        }
    }
    #comment {
        .boxContent {
            .showTextarea {
                .label {
                    padding-left: 50px;
                }
                .data {
                    padding-left: 50px;
                }
            }
            #ShowIDPDetailSuperiorComment {
                border-bottom: 1px solid #DCDCDC;
            }
        }
    }
    #mobility {
        .boxContent {
            display: flex;
            flex-flow: row wrap;
            padding: 10px 10px 20px 50px;
            .yesNoBox {
                font-weight: 600;
                .yes {
                    color: #00965E;
                    margin-left: 5px;
                }
                .no {
                    color: #BF3E3E;
                    margin-left: 5px;
                }
            }
            #mobilityWithinCountryBox {
                margin-right: 30px;
            }
        }
    }
}

[data-theme='dark'] #ShowIDPDetail {
    #comment {
        .boxContent {
            #ShowIDPDetailSuperiorComment {
                border-bottom: 1px solid #494B6D;
            }
        }
    }
    #mobility {
        .boxContent {
            color: #A0A0C2;
            .yes {
                color: #00965E;
                margin-left: 5px;
            }
            .no {
                color: #BF3E3E;
                margin-left: 5px;
            }
        }
        #mobilityWithinCountryBox {
            margin-right: 30px;
        }
    }
}