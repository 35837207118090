.table {
    margin-bottom: 0;
    &.table-hover {
        thead {
            tr {
                th {
                    color: #1D4370;
                    border-bottom: none;
                    padding: 0.75rem 0.75rem 0.75rem 0;
                    &.firstCellAction {
                        width: 85px;
                    }
                }
            }
        }
        tbody {
            tr {
                border-color: #EDEDED;
                &:hover {
                    --bs-table-accent-bg: #F1F6F5;
                    background-color: --bs-table-accent-bg;
                    td {
                        color: #212529;
                        &:first-child {
                            .firstCell {
                                .firstCellAccent {
                                    background-color: #7EA1CA;
                                }
                            }
                        }
                    }
                }
                td {
                    padding: 0;
                    vertical-align: middle;
                    color: #618299;
                    padding: 0 0.75rem 0 0;
                    .firstCell {
                        padding: 2px 10px 2px 0;
                        display: flex;
                        flex-flow: row nowrap;
                        align-items: stretch;
                        justify-content: space-between;
                        .firstCellAccent {
                            width: 5px;
                            min-width: 5px;
                            border-radius: 0 10px 10px 0;
                            min-height: 100%;
                            display: flex;
                        }
                        .sequence {
                            display: flex;
                            flex-grow: 1;
                            align-items: center;
                            justify-content: flex-end;
                        }
                    }
                    &:first-child {
                        padding: 0;
                    }
                    &:last-child {
                        padding-right: 13px;
                    }
                    &.react-bs-table-no-data {
                        padding: 20px
                    }
                }
            }
        }
    }
}

.actionBottomTable {
    text-align: center;
    padding-bottom: 20px;
}

[data-theme='dark'] .table {
    &.table-hover {
        thead {
            tr {
                th {
                    color: #FFFFFF;
                    border-top: 1px solid #494B6D;
                    border-bottom: 1px solid #494B6D;
                }
            }
        }
        tbody {
            tr {
                border-color: #3D3E58;
                &:hover {
                    --bs-table-accent-bg: #1E202D;
                    background-color: --bs-table-accent-bg;
                    td {
                        color: #49BACA;
                        &:first-child {
                            .firstCell {
                                .firstCellAccent {
                                    background-color: #49BACA;
                                }
                            }
                        }
                    }
                }
                td {
                    color: #D6D6F1;
                }
            }
        }
    }
}