#questionnaireBox {
    #boxContent-questionnaireBox {
        .loaderwrapper {
            #questionnaireHeader {
                display: flex;
                flex-flow: row wrap;
                padding: 10px 20px;
                color: #555;
                #questionnaireHeaderLabel {
                    width: 100%;
                }
                #questionnaireHeaderValue {
                    font-size: 1.5rem;
                    color: #FF8B02;
                    width: 100%;
                }
            }
            #questionnaireBody {
                #questionnaireBodyHead {
                    display: flex;
                    flex-flow: row nowrap;
                    background-color: #ECF6F3;
                    padding: 10px 20px;
                    font-weight: 600;
                    border-top: 1px solid #DCDCDC;
                    border-bottom: 1px solid #DCDCDC;
                    #questionnaireBodyHeadRight {
                        flex-grow: 1;
                    }
                    #questionnaireBodyHeadRight {
                        text-align: right;
                    }
                }
                #questionnaireBodyContent {
                    .group {
                        margin: 10px 0;
                        .groupLabel {
                            padding-left: 10px;
                            color: #1D4370;
                            font-weight: 600;
                        }
                    }
                }
                #questionnaireBodySummary {
                    margin-top: 20px;
                    #questionnaireBodySummaryCommentsBox {
                        margin: 0 12px;
                        .editorBlock {
                            label {
                                color: #1D4370;
                                font-weight: 600;
                            }
                        }
                    }
                }
            }
        }
    }
}

[data-theme='dark'] #questionnaireBox {
    #boxContent-questionnaireBox {
        .loaderwrapper {
            #questionnaireHeader {
                color: #A0A0C2;
                #questionnaireHeaderValue {
                    font-size: 1.5rem;
                    color: #FF8B02;
                    width: 100%;
                }
            }
            #questionnaireBody {
                #questionnaireBodyHead {
                    background-color: #1E202D;
                    border-top: 1px solid #494B6D;
                    border-bottom: 1px solid #494B6D;
                }
                #questionnaireBodyContent {
                    .group {
                        .groupLabel {
                            color: #1D4370;
                        }
                    }
                }
                #questionnaireBodySummary {
                    #questionnaireBodySummaryCommentsBox {
                        .editorBlock {
                            label {
                                color: #1D4370;
                            }
                        }
                    }
                }
            }
        }
    }
}