.blockApps {
  display: flex;
  flex-flow: row nowrap;
}

.highContrast {
  filter: invert(100%);
}

.fontSize2 {
  font-size: 125%;
}
.fontSize3 {
  font-size: 150%;
}
