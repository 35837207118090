.trainingGroupsUserSelectTrainingSelectGroupItem {
    border: 1px solid #93B7E1;
    box-sizing: border-box;
    box-shadow: 0px 0px 8px #C3CDDB;
    border-radius: 0px 0px 10px 10px;
    display: flex;
    flex-flow: column;
    margin: 0.625rem 0.375rem;
    width: 280px;
    min-height: 280px;
    background-color: #fff;
    &:hover {
        .accent {
            background-color: #93B7E1;
        }
    }
    &.noActive {
        border: 1px solid #DCDCDC;
        &:hover {
            .accent {
                background-color: #C4C4C4;
            }
        }
        .accent {
            background-color: #C4C4C4;
        }
        .name {
            color: #959595;
        }
        .datesPlan {
            border: 1px solid #C4C4C4;
            .icon {
                svg {
                    path {
                        fill: #DCDCDC;
                    }
                }
            }
            .dates {
                div {
                    color: #959595;
                }
                .startDateBox {
                    .startDate {
                        color: #959595;
                    }
                }
                .endDateBox {
                    .endDate {
                        color: #959595;
                    }
                }
            }
            .singleDate {
                .singleDateBox {
                    color: #959595;
                }
            }
        }
        .typeTrainingsBox {
            border-bottom: 1px solid #DCDCDC;
            border-top: 1px solid #DCDCDC;
            .typeTrainingsLabel {
                color: #555555;
            }
            .typeTrainingsValue {
                color: #959595;
            }
        }
        .freeSpaceBox {
            border-bottom: 1px solid #DCDCDC;
            .freeSpaceLabel {
                color: #555555;
            }
            .freeSpaceValue {
                color: #959595;
            }
        }
    }
    .accent {
        width: 100%;
        height: 5px;
        border-radius: 0 0 10px 10px;
        background-color: #FF8B02;
    }
    .name {
        margin: 5px 10px;
        text-align: center;
        color: #1D4370;
        font-weight: 600;
        font-size: 1rem;
        flex-grow: 1;
    }
    .datesPlan {
        border: 1px solid #C3CDDB;
        border-radius: 20px;
        margin: 0 15px;
        display: flex;
        flex-flow: row nowrap;
        .icon {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 30%;
            svg {
                margin: 13px 10px;
            }
        }
        .dates {
            display: flex;
            flex-flow: row wrap;
            width: 70%;
            align-items: flex-start;
            div {
                width: 100%;
                padding-left: 6px;
                color: #1D4370;
            }
            .startDateBox {
                margin-top: 11px;
                .startDate {
                    margin-left: 13px;
                    font-size: 1.25rem;
                    color: #7EA1CA;
                }
            }
            .endDateBox {
                .endDate {
                    margin-left: 13px;
                    font-size: 1.25rem;
                    color: #000;
                }
            }
        }
        .singleDate {
            display: flex;
            flex-flow: row;
            width: 70%;
            align-items: center;
            div {
                width: 100%;
                padding-left: 6px;
            }
            .singleDateBox {
                font-size: 1.25rem;
                color: #7EA1CA;
                text-align: center;
            }
        }
    }
    .typeTrainingsBox {
        display: flex;
        flex-flow: row nowrap;
        border-bottom: 1px solid #DCDCDC;
        border-top: 1px solid #DCDCDC;
        margin: 8px 15px 0;
        padding: 4px 15px 6px 10px;
        .typeTrainingsLabel {
            color: #555555;
        }
        .typeTrainingsValue {
            font-weight: 500;
            color: #618299;
            text-align: right;
            flex-grow: 1;
        }
    }
    .freeSpaceBox {
        display: flex;
        flex-flow: row nowrap;
        border-bottom: 1px solid #DCDCDC;
        margin: 0 15px;
        padding: 4px 15px 6px 10px;
        .freeSpaceLabel {
            color: #555555;
        }
        .freeSpaceValue {
            font-weight: 500;
            color: #618299;
            text-align: right;
            flex-grow: 1;
        }
    }
    .action {
        margin: 13px 15px 15px;
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        .buttonBox {
            display: flex;
            flex-flow: column;
        }
        .actionInFolder {
            align-items: center
        }
    }
}

[data-theme='dark'] .trainingGroupsUserSelectTrainingSelectGroupItem {
    border: 1px solid #13151e;
    box-shadow: 0px 0px 8px #13151e;
    background-color: #2c2d44;
    &:hover {
        .accent {
            background-color: #49BACA;
        }
    }
    &.noActive {
        border: 1px solid #13151e;
        &:hover {
            .accent {
                background-color: #414059;
            }
        }
        .accent {
            background-color: #414059;
        }
        .name {
            color: #68698b;
        }
        .datesPlan {
            border: 1px solid #494B6D;
            .icon {
                svg {
                    path {
                        fill: #4d4e68;
                    }
                }
            }
            .dates {
                div {
                    color: #4d4e68;
                }
                .startDateBox {
                    .startDate {
                        color: #4d4e68;
                    }
                }
                .endDateBox {
                    .endDate {
                        color: #4d4e68;
                    }
                }
            }
            .singleDate {
                .singleDateBox {
                    color: #4d4e68;
                }
            }
        }
        .typeTrainingsBox {
            border-bottom: 1px solid #4d4e68;
            border-top: 1px solid #4d4e68;
            .typeTrainingsLabel {
                color: #4d4e68;
            }
            .typeTrainingsValue {
                color: #4d4e68;
            }
        }
        .freeSpaceBox {
            border-bottom: 1px solid #4d4e68;
            .freeSpaceLabel {
                color: #4d4e68;
            }
            .freeSpaceValue {
                color: #4d4e68;
            }
        }
    }
    .accent {
        background-color: #FF8B02;
    }
    .name {
        color: #fff;
    }
    .datesPlan {
        border: 1px solid #494B6D;
        .icon {
            svg {
                path {
                    fill: #d6d6f1;
                    opacity: 0.5;
                }
            }
        }
        .dates {
            div {
                color: #49BACA;
            }
            .startDateBox {
                .startDate {
                    color: #49BACA;
                }
            }
            .endDateBox {
                .endDate {
                    color: #316e7b;
                }
            }
        }
        .singleDate {
            .singleDateBox {
                color: #49BACA;
            }
        }
    }
    .typeTrainingsBox {
        border-bottom: 1px solid #494B6D;
        border-top: 1px solid #494B6D;
        .typeTrainingsLabel {
            color: #A0A0C2;
        }
        .typeTrainingsValue {
            font-weight: 500;
            color: #49BACA;
            text-align: right;
            flex-grow: 1;
        }
    }
    .freeSpaceBox {
        display: flex;
        flex-flow: row nowrap;
        border-bottom: 1px solid #494B6D;
        margin: 0 15px;
        padding: 4px 15px 6px 10px;
        .freeSpaceLabel {
            color: #A0A0C2;
        }
        .freeSpaceValue {
            font-weight: 500;
            color: #49BACA;
            text-align: right;
            flex-grow: 1;
        }
    }
    .action {
        margin: 13px 15px 15px;
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        .buttonBox {
            display: flex;
            flex-flow: column;
        }
        .actionInFolder {
            align-items: center
        }
    }
}