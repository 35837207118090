#EmployeeAppraisalGoalBox {
    #EmployeeAppraisalGoalBoxFirstRow {
        #EmployeeAppraisalGoalBoxFirstRowBox {
            display: flex;
            flex-flow: row nowrap;
            padding: 12px 15px 0;
            #EmployeeAppraisalGoalBoxFirstRowBoxIcon {
                margin-right: 20px;
                justify-content: center;
                align-items: center;
                display: flex;
                svg {
                    width: 60px !important;
                    height: 60px !important;
                    path {
                        fill: #CBD4DF;
                    }
                }
            }
            #EmployeeAppraisalGoalBoxFirstRowBoxInfo {
                #EmployeeAppraisalGoalBoxFirstRowBoxInfoLabel {
                    font-size: 1.125rem;
                    font-weight: 700;
                    margin-bottom: 6px
                }
            }
        }
    }
    #EmployeeAppraisalGoalBoxSecondRow {
        display: flex;
        padding: 0;
        #EmployeeAppraisalGoalAddArea {
            margin-right: 5px;
        }
        #EmployeeAppraisalGoalBoxSecondRowFirstColumn {
            padding: 20px 10px;
        }
        #EmployeeAppraisalGoalBoxSecondRowSecondColumn {
            display: flex;
            flex-grow: 1;
            line-height: 1;
            justify-content: flex-end;
            #countGoal {
                padding: 5px 20px;
                #countGoalLabel {
                    text-align: center;
                }
                #countGoalData {
                    font-size: 3rem;
                    color: #1D4370;
                    font-weight: 600;
                    text-align: center;
                    span {
                        color: #C4C4C4;
                        font-weight: 400;
                    }
                }
            }
        }
    }
    #noGoalInAppraisal {
        display: flex;
        justify-content: center;
        padding: 20px;
        border-top: 1px solid #dcdcdc;
    }
}

[data-theme='dark'] #EmployeeAppraisalGoalBox {
    #EmployeeAppraisalGoalBoxFirstRow {
        #EmployeeAppraisalGoalBoxFirstRowBox {
            #EmployeeAppraisalGoalBoxFirstRowBoxIcon {
                svg {
                    path {
                        fill: #A0A0C2;
                    }
                }
            }
            #EmployeeAppraisalGoalBoxFirstRowBoxInfo {
                color: #A0A0C2;
            }
        }
    }
    #EmployeeAppraisalGoalBoxSecondRow {
        #EmployeeAppraisalGoalAddArea {
            margin-right: 5px;
        }
    }
}

#approveIDP {
    text-align: center;
    padding: 20px 0;
}