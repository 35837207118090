.tableCellAppraisalGoalFinal {
    display: flex;
    .CustomInfoBlockBox {
        display: flex;
        align-items: center;
    }
    .tableCellAppraisalGoalFinalRating {
        text-align: right;
        margin-right: 10px;
    }
}