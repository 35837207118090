@import './../../../scss/variableCustom.scss';
.boxTableRowAction {
    width: 32px;
    min-height: 32px;
    border-radius: 32px;
    background-color: #D9E1E0;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    svg {
        margin-left: 8px;
    }
}

.activeMenu {
    background-color: #D3D7D6;
    position: absolute;
    margin-left: 32px;
    display: flex;
    flex-flow: row nowrap;
    padding: 6px 6px 6px 0;
    border-radius: 0 22px 22px 0;
    margin-top: -38px;
    // top: 1px;
    // width: 0;
    visibility: hidden;
    /* -webkit-transition: width, 0.2s linear;
    -moz-transition: width, 0.2s linear;
    -ms-transition: width, 0.2s linear;
    -o-transition: width, 0.2s linear;
    transition: width, 0.2s linear; */
}

.boxAction {
    // display: flex;
    // flex-flow: row nowrap;
    width: 40px;
    padding: 6px;
    margin-bottom: 1px;
    &:hover {
        background-color: #D3D7D6;
        border-radius: 22px 0 0 22px;
        .boxTableRowAction {
            background-color: #FF8B02;
            path {
                fill: #fff;
            }
        }
        .activeMenu {
            visibility: visible;
            /* -webkit-transition: width, 0.2s linear;
            -moz-transition: width, 0.2s linear;
            -ms-transition: width, 0.2s linear;
            -o-transition: width, 0.2s linear;
            transition: width, 0.2s linear; */
        }
    }
}

.ag-react-container {
    align-items: center;
    height: 100%;
}

.ag-pinned-left-cols-container {
    z-index: 1;
}

[data-theme='dark'] .boxTableRowAction {
    background-color: #494B6D;
    svg {
        path {
            fill: #A0A0C2;
        }
    }
}

[data-theme='dark'] .activeMenu {
    background-color: #494B6D;
}

[data-theme='dark'] .boxAction {
    &:hover {
        background-color: #494B6D;
        .boxTableRowAction {
            background-color: #FF8B02;
            path {
                fill: #fff;
            }
        }
    }
}