@import './../../scss/variableCustom.scss';
// Use variable

/*
$label-colour: #bbb;
$disabled-colour: #ddd;
$toggle-colour: #2f855a;
$white: #fff;
$focus-color: #ff0;
$colorNo: #fff;
$colorNoDark: #fff;
$backgroudColorNo: #bf3e3e;
$backgroundColorNoDark: #bf3e3e;

*/

.loaderwrapper {
    width: 100%;
    &.allowGrow {
        display: flex;
        flex-grow: 1;
        flex-flow: row nowrap;
    }
    .loaderoverlay {
        background-color: rgba(7.0, 50.0, 75.0, 0.7);
    }
    &.radiusTop {
        .loaderoverlay {
            border-radius: 10px 10px 0 0;
        }
    }
    &.noRadiusTop {
        .loaderoverlay {
            border-radius: 0;
        }
    }
}

[data-theme='dark'] .loaderwrapper {
    .loaderoverlay {
        background-color: rgba(0, 0, 0, 0.7);
    }
}