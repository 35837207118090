#trainingsGroupEditBox {
    .boxContent {
        padding-top: 9px;
        .tabsBox {
            margin-bottom: 0;
        }
        #trainingsGroupEditContentBox {
            box-shadow: 0px -12px 20px rgba(7, 75, 50, 0.15);
        }
    }
}

[data-theme='dark'] #trainingsGroupEditBox {
    .boxContent {
        #trainingsGroupEditContentBox {
            box-shadow: 0px -12px 20px rgba(18, 20, 33, 0.15);
        }
    }
}