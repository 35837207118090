@import './../../scss/variableCustom.scss';
.customContainer {
    width: 100%;
    &.allowGrow {
        display: flex;
        flex-grow: 1;
        flex-flow: row nowrap;
    }
    &.paddingSides {
        padding-left: 10px;
        padding-right: 10px;
    }
    &.column {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between
    }
}

[data-theme='dark'] .customContainer {}