.contentBox {
    width: 100%;
    padding: 0 0.625rem;
    &.bottomBorder {
        padding-bottom: 1rem;
        border-bottom: 1px solid #dcdcdc;
    }
    &.noPadding {
        padding: 0;
    }
    &.allowGrow {
        display: flex;
        flex-flow: row wrap;
        flex-grow: 1;
    }
    .titleBox {
        background-color: #ECF6F3;
        color: #1D4370;
        font-weight: 600;
        padding: 10px 20px;
        border-top: 1px solid #DCDCDC;
        border-bottom: 1px solid #DCDCDC;
    }
}

[data-theme='dark'] .contentBox {
    &.bottomBorder {
        border-bottom: 1px solid #494B6D;
    }
    .titleBox {
        background-color: #1E202D;
        color: #D6D6F1;
        border-top: 1px solid #1E202D;
        border-bottom: 1px solid #1E202D;
    }
}