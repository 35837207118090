.showInput {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    margin-top: 0.75rem;
    .label {
        color: #1D4370;
        width: 40%;
        text-align: right;
        font-weight: 600;
    }
    .data {
        color: #555555;
        padding-left: 0.625rem;
    }
    &.fullWidth {
        flex-flow: column;
        .label {
            width: 100%;
            text-align: left;
        }
        .data {
            padding-left: 0.625rem;
            width: 100%;
        }
    }
    &.toLeft {
        .label {
            width: auto;
        }
    }
}

[data-theme='dark'] .showInput {
    .label {
        color: #A0A0C2;
    }
    .data {
        color: #fff;
    }
}