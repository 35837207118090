#showPlansTrainingsBox {
    .titleBox {
        margin: 0 10px 20px 20px;
        .showShortName {
            .data {
                font-weight: 600;
                font-size: 1.5rem;
            }
        }
    }
    .dateBox {
        display: flex;
        flex-flow: row wrap;
        width: 100%;
        padding: 0 20px 20px 30px;
        background-color: #ECF6F3;
        border-top: 1px solid #DCDCDC;
        border-bottom: 1px solid #DCDCDC;
        .left {
            display: flex;
            flex-flow: row wrap;
            flex-grow: 1;
        }
        .right {
            display: flex;
            flex-flow: row;
        }
        .dateContent {
            display: flex;
            flex-flow: row wrap;
            max-width: 280px;
            max-height: 100px;
            margin-top: 10px;
            &#validationDate {
                max-width: 200px;
            }
            .title {
                width: 100%;
                margin-bottom: 12px;
                color: #555555;
            }
            .content {
                width: 100%;
                display: flex;
                flex-flow: row nowrap;
                .icon {
                    margin-right: 20px;
                }
                .dateDetail {
                    display: flex;
                    flex-flow: row wrap;
                    div {
                        width: 100%;
                        color: #1D4370;
                        span {
                            font-size: 1.25rem;
                            margin-left: 10px;
                            &.start {
                                color: #1D4370;
                            }
                        }
                    }
                    &#validationDateBoxDate {
                        #validationDateEnd {
                            padding-top: 10px;
                            span {
                                margin-left: 0;
                            }
                        }
                    }
                }
            }
        }
    }
    .limitBox {
        width: 100%;
        display: flex;
        flex-flow: row wrap;
        padding: 10px 20px 20px;
        .limitTitle {
            width: 100%;
            color: #555;
        }
        .limitContent {
            width: 100%;
            display: flex;
            flex-flow: row wrap;
            .detail {
                margin-top: 10px;
                display: flex;
                flex-flow: row nowrap;
                align-items: flex-end;
                margin-right: 5%;
                .label {
                    color: #1D4370;
                    font-size: 1rem;
                }
                .value {
                    padding-left: 5px;
                    font-size: 1.125rem;
                    color: #1D4370;
                }
            }
        }
    }
}

[data-theme='dark'] #showPlansTrainingsBox {
    .titleBox {
        margin: 0 10px 20px 20px;
        .showShortName {
            .data {
                font-weight: 600;
                font-size: 1.5rem;
            }
        }
    }
    .dateBox {
        background-color: #1E202D;
        border-top: 1px solid #494B6D;
        border-bottom: 1px solid #494B6D;
        .dateContent {
            .title {
                color: #A0A0C2;
            }
            .content {
                .icon {
                    svg {
                        path {
                            fill: #d6d6f1;
                            opacity: 0.5;
                        }
                    }
                }
                .dateDetail {
                    div {
                        color: #316e7b;
                        span {
                            &.start {
                                color: #49BACA;
                            }
                        }
                    }
                }
            }
        }
    }
    .limitBox {
        .limitTitle {
            color: #A0A0C2;
        }
        .limitContent {
            .detail {
                .label {
                    color: #316e7b;
                }
                .value {
                    color: #49BACA;
                }
            }
        }
    }
}