.CustomAnimatedPrograssBarLine {
    &.noLimits {
        .progress {
            background-color: #EDEDED;
        }
    }
    .progress {
        height: 0.625rem;
        border-radius: 20px;
        border: 1px solid #93B7E1;
        background-color: #fff;
        .progress-bar {
            background-color: #1D4370;
            border-radius: 20px;
        }
    }
}

[data-theme='dark'] .CustomAnimatedPrograssBarLine {
    &.noLimits {
        .progress {
            background-color: #4d4e68;
        }
    }
    .progress {
        height: 0.625rem;
        border-radius: 20px;
        border: 1px solid #3D3E58;
        background-color: #1E202D;
        .progress-bar {
            background: linear-gradient(90deg, #D68A40 1.76%, #CF4C4C 144.71%);
            border-radius: 20px;
        }
    }
}