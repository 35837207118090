#trainingGroupUserTrainingsSelectedGroupBox {
    #userPlansAcceptedTrainingsBasicInformation {
        display: flex;
        flex-flow: row wrap;
        padding: 10px 20px;
        background-color: #fff;
        border-left: 1px solid #dcdcdc;
        border-right: 1px solid #dcdcdc;
        .employeeNameBox {
            flex-grow: 1;
            .titleName {
                width: 100%;
                color: #555;
            }
            .name {
                width: 100%;
                color: #FF8B02;
                font-size: 1.5rem;
                font-weight: 600;
                .lastName {
                    text-transform: uppercase;
                }
                .firstName {
                    text-transform: capitalize;
                }
            }
            .extension {
                width: 100%;
                color: #555555;
                font-size: 0.875rem;
                font-weight: 400;
                .extensionData {
                    font-weight: 600;
                }
            }
            .superior {
                width: 100%;
                color: #555555;
                font-size: 0.875rem;
                font-weight: 400;
                .superiorData {
                    font-weight: 600;
                    color: #7EA1CA;
                }
            }
        }
        .additionalInfoBox {
            display: flex;
            flex-flow: row wrap;
            .aditionalInfoContent {
                display: flex;
                flex-flow: row wrap;
                max-width: 280px;
                max-height: 100px;
                padding-right: 5px;
                &#validationDate {
                    max-width: 200px;
                }
                .content {
                    width: 100%;
                    display: flex;
                    flex-flow: row nowrap;
                    .icon {
                        margin-right: 20px;
                    }
                    .additionalInfoDetail {
                        display: flex;
                        flex-flow: row wrap;
                        div {
                            width: 100%;
                            color: #1D4370;
                            &.title {
                                color: #7EA1CA;
                                font-size: 1.5rem;
                            }
                            &.data {
                                color: #555;
                            }
                        }
                    }
                }
            }
        }
    }
    #trainingGroupsUserSelectTrainingBox {
        .trainingGroupsUserSelectTrainingItemsBox {
            padding: 10px;
            display: flex;
            flex-flow: row wrap;
        }
        .trainingGroupsUserSelectTrainingItemsBoxNoRecord {
            padding: 10px;
            display: flex;
            flex-flow: row wrap;
        }
    }
}

[data-theme='dark'] #trainingGroupUserTrainingsSelectedGroupBox {
    #userPlansAcceptedTrainingsBasicInformation {
        background-color: #2C2D44;
        border-left: 1px solid #13151e;
        border-right: 1px solid #13151e;
        .employeeNameBox {
            .titleName {
                color: #A0A0C2;
            }
            .name {
                color: #FF8B02;
            }
            .extension {
                color: #A0A0C2;
                .extensionData {
                    color: #fff;
                }
            }
            .superior {
                color: #A0A0C2;
                .superiorData {
                    color: #fff;
                }
            }
        }
        .additionalInfoBox {
            .aditionalInfoContent {
                .content {
                    .icon {
                        svg {
                            path {
                                fill: #A0A0C2;
                            }
                        }
                    }
                    .additionalInfoDetail {
                        div {
                            &.title {
                                color: #A0A0C2;
                                opacity: 0.5;
                            }
                            &.data {
                                color: #fff;
                            }
                        }
                    }
                }
            }
        }
    }
}