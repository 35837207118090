.CatalogsPlansItems {
    width: 100%;
    padding: 0 0.625rem;
    display: flex;
    flex-flow: row wrap;
    margin-top: 0.625rem;
    &.bottomBorder {
        padding-bottom: 1rem;
        border-bottom: 1px solid #dcdcdc;
    }
}