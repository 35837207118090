@import './../../scss/variableCustom.scss';
// Use variable

/*
$label-colour: #bbb;
$disabled-colour: #ddd;
$toggle-colour: #2f855a;
$white: #fff;
$focus-color: #ff0;
$colorNo: #fff;
$colorNoDark: #fff;
$backgroudColorNo: #bf3e3e;
$backgroundColorNoDark: #bf3e3e;

*/

.toggle-switch {
    position: relative;
    width: 50px;
    display: inline-block;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    text-align: left;
    &-checkbox {
        display: none;
    }
    &-label {
        display: block;
        overflow: hidden;
        cursor: pointer;
        border: 0 solid $switchLabelColour;
        border-radius: 20px;
        margin: 0;
        &:focus {
            outline: none;
            >span {
                box-shadow: 0 0 2px 5px $switchFocusColor;
            }
        }
        >span:focus {
            outline: none;
        }
        &.toggle-switch-disabled {
            cursor: not-allowed;
        }
    }
    &-inner {
        display: block;
        width: 200%;
        margin-left: -100%;
        transition: margin 0.3s ease-in 0s;
        &:before,
        &:after {
            display: block;
            float: left;
            width: 50%;
            height: 18px;
            padding: 0;
            line-height: 18px;
            font-size: 10px;
            color: $switchWhite;
            font-weight: 600;
            box-sizing: border-box;
        }
        &:before {
            content: attr(data-yes);
            text-transform: uppercase;
            padding-left: 6px;
            background-color: $switchBackgroundColorYes;
            color: $switchWhite;
            text-align: left;
        }
    }
    /* &-disabled {
        background-color: $switchDisabledColour;
        cursor: not-allowed;
        &:before {
            background-color: $switchDisabledColour;
            cursor: not-allowed;
        }
    } */
    &-inner:after {
        content: attr(data-no);
        text-transform: uppercase;
        padding-right: 6px;
        background-color: $switchBackgroudColorNo;
        color: $switchWhite;
        text-align: right;
    }
    &-switch {
        display: flex;
        width: 24px;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 40px;
        transition: all 0.3s ease-in 0s;
        align-items: baseline;
        padding: 1px;
        left: 0;
        svg {
            rect {
                fill: $switchToogleSwitchColor;
            }
        }
    }
    &-checkbox:checked+&-label {
        .toggle-switch-inner {
            margin-left: 0;
        }
        .toggle-switch-switch {
            right: 0px;
            left: auto;
        }
    }
    &.small-switch {
        width: 40px;
        .toggle-switch-inner {
            &:after,
            &:before {
                content: '';
                height: 20px;
                line-height: 20px;
            }
        }
        .toggle-switch-switch {
            width: 16px;
            right: 20px;
            margin: 2px;
        }
    }
    @media screen and (max-width: 991px) {
        transform: scale(0.9);
    }
    @media screen and (max-width: 767px) {
        transform: scale(0.825);
    }
    @media screen and (max-width: 575px) {
        transform: scale(0.75);
    }
}

[data-theme='dark'] .toggle-switch {
    &-label {
        border: 0 solid $switchLabelColour;
        &:focus {
            >span {
                box-shadow: 0 0 2px 5px $switchFocusColor;
            }
        }
    }
    &-inner {
        &:before,
        &:after {
            color: $switchWhite;
        }
        &:before {
            content: attr(data-yes);
            background-color: #1D4370;
            color: $switchWhite;
        }
    }
    &-disabled {
        // background-color: $switchDisabledColour;
        &:before {
            // background-color: $switchDisabledColour;
        }
    }
    &-inner:after {
        background-color: $switchBackgroudColorNo;
        color: $switchWhite;
    }
    &-switch {
        svg {
            rect {
                fill: $switchToogleSwitchColorDark;
            }
        }
    }
}