.CatalogsPlansItemReportsEmployeeAppraisal {
    border: 1px solid #93B7E1;
    box-sizing: border-box;
    box-shadow: 0px 0px 8px #C3CDDB;
    border-radius: 0px 0px 10px 10px;
    display: flex;
    flex-flow: column;
    margin: 0.625rem 0.375rem;
    width: 280px;
    min-height: 280px;
    background-color: #fff;
    .dateOfDataEntry {
        display: flex;
        flex-flow: column;
        border-bottom: 1px solid #DCDCDC;
        margin: 12px 15px 0 15px;
        padding: 0 15px 10px 10px;
        .dateOfDataEntryLabel {
            width: 100%;
            color: #555555;
        }
        .dateOfDataEntryDates {
            display: flex;
            flex-flow: row nowrap;
            div {
                color: #618299;
                span {
                    font-weight: 600;
                }
            }
            .startdateOfDataEntryDatesBox {
                width: 50%;
                text-align: left
            }
            .enddateOfDataEntryDatesBox {
                width: 50%;
                text-align: right;
            }
        }
    }

    .dateOfSemiAnnualVerification {
        display: flex;
        flex-flow: column;
        border-bottom: 1px solid #DCDCDC;
        margin: 12px 15px 0 15px;
        padding: 0 15px 10px 10px;
        .dateOfSemiAnnualVerificationLabel {
            width: 100%;
            color: #555555;
        }
        .dateOfSemiAnnualVerificationDates {
            display: flex;
            flex-flow: row nowrap;
            div {
                color: #618299;
                span {
                    font-weight: 600;
                }
            }
            .startdateOfSemiAnnualVerificationDatesBox {
                width: 50%;
                text-align: left
            }
            .enddateOfSemiAnnualVerificationDatesBox {
                width: 50%;
                text-align: right;
            }
        }
    }
    
    .dateOfSemiAnnualVerification {
        display: flex;
        flex-flow: column;
        border-bottom: 1px solid #DCDCDC;
        margin: 12px 15px 0 15px;
        padding: 0 15px 10px 10px;
        .dateOfSemiAnnualVerificationLabel {
            width: 100%;
            color: #555555;
        }
        .dateOfSemiAnnualVerificationDates {
            display: flex;
            flex-flow: row nowrap;
            div {
                color: #618299;
                span {
                    font-weight: 600;
                }
            }
            .startdateOfSemiAnnualVerificationDatesBox {
                width: 50%;
                text-align: left
            }
            .enddateOfSemiAnnualVerificationDatesBox {
                width: 50%;
                text-align: right;
            }
        }
    }
    .annualVerificationDate {
        display: flex;
        flex-flow: column;
        border-bottom: 1px solid #DCDCDC;
        margin: 12px 15px 0 15px;
        padding: 0 15px 10px 10px;
        .annualVerificationDateLabel {
            width: 100%;
            color: #555555;
        }
        .annualVerificationDateDates {
            display: flex;
            flex-flow: row nowrap;
            div {
                color: #618299;
                span {
                    font-weight: 600;
                }
            }
            .startannualVerificationDateBox {
                width: 50%;
                text-align: left
            }
            .endannualVerificationDateBox {
                width: 50%;
                text-align: right;
            }
        }
    }
    .actionEmployeeAppraisal{
       margin:20px auto;
    }
}

// zrobić dark

[data-theme='dark'] .CatalogsPlansItem {
    border: 1px solid #13151e;
    box-shadow: 0px 0px 8px #13151e;
    background-color: #2c2d44;
    &:hover {
        .accent {
            background-color: #49BACA;
        }
    }
    &.noActive {
        border: 1px solid #13151e;
        .accent {
            background-color: #414059;
        }
        .name {
            color: #68698b;
        }
        .datesPlan {
            border: 1px solid #494B6D;
            .icon {
                svg {
                    path {
                        fill: #4d4e68;
                    }
                }
            }
            .dates {
                div {
                    color: #4d4e68;
                }
                .startDateBox {
                    .startDate {
                        color: #4d4e68;
                    }
                }
                .endDateBox {
                    .endDate {
                        color: #4d4e68;
                    }
                }
            }
        }
        .registrationProcess {
            .registrationProcessLabel {
                color: #4d4e68;
            }
            .registrationProcessDates {
                div {
                    color: #4d4e68;
                }
            }
        }
        .action {
            .verificationDateBox {
                .verificationDateLabel {
                    color: #4d4e68;
                }
                .verificationDate {
                    color: #4d4e68;
                }
            }
        }
    }
    .accent {
        background-color: #FF8B02;
    }
    .name {
        color: #fff;
    }
    .datesPlan {
        border: 1px solid #494B6D;
        .icon {
            svg {
                path {
                    fill: #d6d6f1;
                    opacity: 0.5;
                }
            }
        }
        .dates {
            div {
                color: #49BACA;
            }
            .startDateBox {
                .startDate {
                    color: #49BACA;
                }
            }
            .endDateBox {
                .endDate {
                    color: #316e7b;
                }
            }
        }
    }
    .registrationProcess {
        border-bottom: 1px solid #494B6D;
        .registrationProcessLabel {
            color: #A0A0C2;
        }
        .registrationProcessDates {
            div {
                color: #49BACA;
            }
        }
    }
    .action {
        .verificationDateBox {
            .verificationDateLabel {
                color: #A0A0C2;
            }
            .verificationDate {
                color: #316e7b;
            }
        }
    }
}