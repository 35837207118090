#trainingGroupListGroupBox {
    #trainingGroupListGroupBasicInformation {
        display: flex;
        flex-flow: row wrap;
        padding: 10px 20px;
        .trainingNameBox {
            flex-grow: 1;
            .titleName {
                width: 100%;
                color: #555;
            }
            .name {
                width: 100%;
                color: #FF8B02;
                font-size: 1.5rem;
                font-weight: 600;
            }
        }
        .additionalInfoBox {
            display: flex;
            flex-flow: row wrap;
            .aditionalInfoContent {
                display: flex;
                flex-flow: row wrap;
                max-width: 280px;
                max-height: 100px;
                .content {
                    width: 100%;
                    display: flex;
                    flex-flow: row wrap;
                    #frameAdditionalInfoBoxDataTitle {
                        width: 100%;
                        color: #555555;
                        margin-bottom: 10px;
                    }
                    #frameAdditionalInfoBoxData {
                        display: flex;
                        flex-flow: row nowrap;
                        .icon {
                            margin-right: 20px;
                        }
                        div {
                            color: #1D4370;
                            display: flex;
                            align-items: center;
                            &.data {
                                color: #1D4370;
                                font-size: 1.125rem;
                                font-weight: 600;
                            }
                            &.dataRegister {
                                color: #1D4370;
                                font-size: 3rem;
                                font-weight: 600;
                            }
                            &.dataNotRegister {
                                color: #BF3E3E;
                                font-size: 3rem;
                                font-weight: 600;
                            }
                        }
                    }
                }
            }
        }
    }
    #trainingGroupsBox {
        .actionBeforeCatalog {
            margin: 10px 0;
        }
        .TrainingsGroupItems {
            width: 100%;
            padding: 0 0.625rem;
            display: flex;
            flex-flow: row wrap;
            margin-top: 0.625rem;
            &.bottomBorder {
                padding-bottom: 1rem;
                border-bottom: 1px solid #dcdcdc;
            }
        }
    }
}

[data-theme='dark'] #trainingGroupListGroupBox {
    #trainingGroupListGroupBasicInformation {
        .trainingNameBox {
            .titleName {
                color: #A0A0C2;
            }
            .name {
                color: #FF8B02;
            }
        }
        .additionalInfoBox {
            .aditionalInfoContent {
                .content {
                    #frameAdditionalInfoBoxDataTitle {
                        color: #A0A0C2;
                    }
                    #frameAdditionalInfoBoxData {
                        .icon {
                            svg {
                                path {
                                    fill: #A0A0C2;
                                }
                            }
                        }
                        div {
                            &.data {
                                color: #fff;
                            }
                            &.dataRegister {
                                color: #1D4370;
                            }
                            &.dataNotRegister {
                                color: #BF3E3E;
                            }
                        }
                    }
                }
            }
        }
    }
    #trainingGroupsBox {
        .TrainingsGroupItems {
            &.bottomBorder {
                border-bottom: 1px solid #dcdcdc;
            }
        }
    }
}