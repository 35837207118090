#formEmployeeAppraisalRatingGoal {
    #formEmployeeAppraisalRatingGoalBox {
        .boxContent {
            padding: 0;
            #formEmployeeAppraisalRatingGoalFirstRow {
                display: flex;
                flex-flow: row;
                padding: 10px 20px;
                border-bottom: 1px solid #DCDCDC;
                #formEmployeeAppraisalRatingGoalFirstRowIcon {
                    margin-right: 20px;
                    justify-content: center;
                    align-items: center;
                    display: flex;
                }
                #formEmployeeAppraisalRatingGoalFirstRowData {
                    display: flex;
                    flex-flow: column;
                    flex-grow: 1;
                    #formEmployeeAppraisalRatingGoalFirstRowDataEmployee {
                        color: #FF8B02;
                        font-size: 1.5rem;
                        font-weight: 600;
                    }
                }
                #formEmployeeAppraisalRatingGoalFirstRowDate {
                    display: flex;
                    #formEmployeeAppraisalRatingGoalFirstRowDateData {
                        display: flex;
                        flex-flow: column;
                        margin-left: 20px;
                        #formEmployeeAppraisalRatingGoalFirstRowDateDataValue {
                            color: #000;
                            font-size: 1.25rem;
                        }
                    }
                }
            }
            #formEmployeeAppraisalRatingGoalSecondRow {
                padding: 10px 20px;
                display: flex;
                flex-flow: row;
                background-color: #ECF6F3;
                border-top: 1px solid #DCDCDC;
                border-bottom: 1px solid #DCDCDC;
                align-items: flex-start;
                #formEmployeeAppraisalRatingGoalSecondRowLabel {
                    color: #1D4370;
                    font-weight: 600;
                    font-size: 1.125rem;
                    margin-right: 10px;
                }
                #formEmployeeAppraisalRatingGoalSecondRowName {
                    display: flex;
                    flex-grow: 1;
                    padding-top: 4px;
                }
                #formEmployeeAppraisalRatingGoalSecondRowWeight {
                    color: #93B7E1;
                    font-weight: 600;
                    font-size: 3rem;
                    display: flex;
                    align-items: center;
                    #formEmployeeAppraisalRatingGoalSecondRowWeightLabel {
                        color: #1D4370;
                        font-weight: 600;
                        font-size: 1.125rem;
                        margin-right: 10px;
                    }
                }
            }
            #formEmployeeAppraisalRatingGoalThirtRow {
                padding: 10px 20px;
                display: flex;
                flex-flow: row;
                border-bottom: 1px solid #DCDCDC;
                #measureLabel {
                    margin-right: 10px;
                    font-weight: 600;
                    color: #1D4370;
                }
            }
            #formEmployeeAppraisalRatingGoalFourthRow {
                padding: 10px 20px;
                display: flex;
                flex-flow: row;
                border-bottom: 1px solid #DCDCDC;
                #commentLabel {
                    font-weight: 600;
                    color: #1D4370;
                }
                #comment {
                    margin-top: 0;
                }
            }
            #formEmployeeAppraisalRatingGoalFifthRow {
                padding: 10px 20px;
                display: flex;
                flex-flow: row;
                border-bottom: 1px solid #DCDCDC;
                #statusSelectBlock {
                    min-width: 250px;
                }
                #progressCustomSliderBlock {
                    min-width: 670px;
                }
            }
            #formEmployeeAppraisalRatingGoalSixthRow {
                padding: 10px 20px;
                display: flex;
                flex-flow: row;
                border-bottom: 1px solid #DCDCDC;
                #ratingSelectBlock {
                    min-width: 250px;
                }
                .editorBlock {
                    min-width: 670px;
                }
            }
        }
    }
}

[data-theme='dark'] #formIDPEmployeeArea {
    #formIDPEmployeeAreaBox {
        #boxContent-formIDPEmployeeAreaBox {
            #formIDPEmployeeAreaFirstRow {
                border-bottom: 1px solid #3D3E58;
                #formIDPEmployeeAreaFirstRowIcon {
                    svg {
                        path {
                            fill: #d6d6f1;
                        }
                    }
                }
            }
            #formIDPEmployeeAreaBlock {
                #formIDPEmployeeAreaBlockLabel {
                    color: #A0A0C2;
                }
                .formIDPEmployeeAreaBlockContent {
                    border-bottom: 1px solid #3D3E58;
                    .formIDPEmployeeAreaBlockPerecent {
                        background-color: #FF8B02;
                        .CustomAnimatedPrograssBar {
                            svg {
                                .CircularProgressbar-trail {
                                    stroke: #FF8B02;
                                }
                                .CircularProgressbar-path {
                                    stroke: #ffa841;
                                }
                                .CircularProgressbar-text {
                                    fill: #fff;
                                }
                            }
                        }
                        &#formIDPEmployeeAreaBlock20Percent {
                            background-color: #00965E;
                            .CustomAnimatedPrograssBar {
                                svg {
                                    .CircularProgressbar-trail {
                                        stroke: #00965E;
                                    }
                                    .CircularProgressbar-path {
                                        stroke: #40b086;
                                    }
                                    .CircularProgressbar-text {
                                        fill: #fff;
                                    }
                                }
                            }
                        }
                        &#formIDPEmployeeAreaBlock10Percent {
                            background-color: #99D6BF;
                            .CustomAnimatedPrograssBar {
                                svg {
                                    .CircularProgressbar-trail {
                                        stroke: #99D6BF;
                                    }
                                    .CircularProgressbar-path {
                                        stroke: #b3e0cf;
                                    }
                                    .CircularProgressbar-text {
                                        fill: #fff;
                                    }
                                }
                            }
                        }
                    }
                    .formIDPEmployeeAreaBlockAdditional {
                        display: flex;
                        flex-flow: column;
                        margin-left: 15px;
                        .formIDPEmployeeAreaBlockAdditionalDateAndMeasure {
                            display: flex;
                            flex-flow: row;
                        }
                        .formIDPEmployeeAreaBlockAdditionalProgressData {
                            padding: 0 20px 0 0;
                        }
                        .formIDPEmployeeAreaBlockAdditionalProgress {
                            .customSliderBlock {
                                padding: 0 40px 0 0;
                            }
                            &#formIDPEmployeeAreaBlock20AdditionalProgress {
                                .customSliderBlock {
                                    .blockInputWithInfo {
                                        .rc-slider {
                                            .rc-slider-track {
                                                background-color: #1D4370;
                                            }
                                            .rc-slider-handle {
                                                &:hover {
                                                    border-color: #1D4370;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            &#formIDPEmployeeAreaBlock10AdditionalProgress {
                                .customSliderBlock {
                                    .blockInputWithInfo {
                                        .rc-slider {
                                            .rc-slider-track {
                                                background-color: #93B7E1;
                                            }
                                            .rc-slider-handle {
                                                &:hover {
                                                    border-color: #93B7E1;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .formIDPEmployeeAreaBlockComment {
                        .formIDPEmployeeAreaBlockCommentLabel {
                            color: #fff;
                        }
                    }
                }
            }
        }
    }
}