.showTextarea {
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    margin-top: 0.75rem;
    &.normalSizeFont {
        .data {
            font-size: 0.875rem;
        }
    }
    .label {
        width: 100%;
        color: #1D4370;
        margin-bottom: 0.9375rem;
        font-weight: 600;
    }
    .data {
        padding-left: 0.938rem;
        padding-right: 0.938rem;
        width: 100%;
        font-size: 0.8125rem;
        color: #555555;
        display: flex;
        flex-grow: 1;
        flex-direction: column;
    }
}

[data-theme='dark'] .showTextarea {
    .label {
        color: #A0A0C2;
    }
    .data {
        color: #fff;
    }
}