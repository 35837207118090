.blockCheckbox {
    display: flex;
    flex-flow: row;
    justify-content: flex-end;
    align-items: center;
    padding: 0.65rem 0;
    &.disabled {
        padding: 0;
    }
    .form-check-label {
        margin-right: calc(0.65rem + 44px);
    }
    .form-check-input {
        margin-right: calc(0.65rem + 18px);
        width: 1.125rem;
        height: 1.125rem;
        &:checked {
            background-color: #1D4370;
            border-color: #1D4370;
        }
        &:focus {
            box-shadow: 0 0 0 .25rem rgba(0, 150, 94, .25);
        }
    }
}

[data-theme='dark'] .blockCheckbox {
    .form-check-label {
        color: #C2C2E2;
    }
    .form-check-input {
        background-color: #2C2D44;
        border: 1px solid #6A6A91;
        &:checked {
            background-color: #1D4370;
            border-color: #1D4370;
        }
        &:focus {
            color: #C2C2E2;
            border: 1px solid #8787AC;
            box-shadow: 0px 0px 8px rgba(255, 255, 255, 0.25);
        }
        &:hover {
            border: 1px solid #6A6A90;
            box-shadow: 0px 0px 8px rgba(255, 255, 255, 0.25);
        }
    }
}