.CatalogsTrainingsItem {
    border: 1px solid #93B7E1;
    box-sizing: border-box;
    box-shadow: 0px 0px 8px #C3CDDB;
    border-radius: 0px 0px 10px 10px;
    display: flex;
    flex-flow: column;
    margin: 0.625rem 0.375rem;
    width: 280px;
    min-height: 280px;
    background-color: #fff;
    &:hover {
        .accent {
            background-color: #FF8B02;
        }
    }
    .accent {
        width: 100%;
        height: 5px;
        border-radius: 0 0 10px 10px;
        background-color: #93B7E1;
    }
    .name {
        margin: 5px 10px;
        text-align: center;
        color: #1D4370;
        font-weight: 600;
        font-size: 1rem;
        flex-grow: 1;
    }
    .avalibleTrainings {
        margin-top: 7px;
        text-align: center;
        color: #618299;
    }
    .animateNumber {
        margin: 15px 79px;
    }
    .action {
        margin: 6px 15px 15px;
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
    }
}

[data-theme='dark'] .CatalogsTrainingsItem {
    border: 1px solid #13151e;
    box-sizing: border-box;
    box-shadow: 0px 0px 8px #13151e;
    border-radius: 0px 0px 10px 10px;
    display: flex;
    flex-flow: column;
    margin: 0.625rem 0.375rem;
    width: 280px;
    min-height: 280px;
    background-color: #2c2d44;
    &:hover {
        .accent {
            background-color: #FF8B02;
        }
    }
    .accent {
        width: 100%;
        height: 5px;
        border-radius: 0 0 10px 10px;
        background-color: #A0A0C2;
    }
    .name {
        margin: 5px 10px;
        text-align: center;
        color: #fff;
        font-weight: 600;
        font-size: 1rem;
        flex-grow: 1;
    }
    .avalibleTrainings {
        margin-top: 7px;
        text-align: center;
        color: #A0A0C2;
    }
    .animateNumber {
        margin: 15px 79px;
    }
    .action {
        margin: 6px 15px 15px;
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
    }
}