@import './../../../scss/variableCustom.scss';
.columnHeaderCell {
    display: flex;
    flex-flow: row nowrap;
    align-items: stretch;
    min-height: 43px;
    &.left {
        justify-content: flex-start;
    }
    &.center {
        justify-content: center;
    }
    &.right {
        justify-content: flex-end;
    }
    &.moveOverAction {
        margin-left: -2.625rem;
    }
    div {
        &.sort {
            margin-right: 0.5rem;
            align-items: center;
            display: flex;
        }
        &.columnName {
            margin-right: 0.25rem;
            align-items: center;
            display: flex;
            min-height: 30px;
            font-weight: 600;
        }
    }
}