#userPlansTrainingsEnrollmentLimits {
    padding: 10px 20px 20px;
    display: flex;
    flex-flow: row wrap;
    .titleLimits {
        width: 100%;
    }
    .dataLimits {
        width: 100%;
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        .limitBlock {
            margin-right: 5%;
            display: flex;
            flex-flow: row wrap;
            flex-grow: 1;
            margin-top: 10px;
            &:last-child {
                margin-right: 0;
            }
            .labelName {
                width: 100%;
                color: #1D4370;
                margin-bottom: 8px;
                span {
                    color: #7EA1CA;
                    font-size: 1.125rem;
                    margin-left: 3px;
                }
            }
            .progressBar {
                width: 100%;
            }
        }
    }
}