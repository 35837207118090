@import './variableCustom.scss';
.btn {
    border-radius: 30px;
    min-width: 130px;
    &.green {
        background-color: #1D4370;
        color: #fff;
        border-color: #00427d;
        &:active {
            background-color: #00427d !important;
            border-color: #00427d !important;
        }
        &:focus {
            box-shadow: 0 0 0 0.2rem rgba(0, 78, 125, 0.5);
        }
    }
    &.red {
        background-color: #BF3E3E;
        color: #fff;
        border-color: #ab3737;
        &:active {
            background-color: #ab3737 !important;
            border-color: #ab3737 !important;
        }
        &:focus {
            box-shadow: 0 0 0 0.2rem rgba(171, 55, 55, 0.5);
        }
    }
    &.noButtonStyle {
        background: none;
        border: none;
        min-width: auto;
        padding: 0;
        &:focus {
            box-shadow: none !important;
        }
        &.active {
            background: none !important;
            border: none !important;
            &:focus {
                box-shadow: none !important;
            }
        }
    }
    &.noActive {
        background-color: #C4C4C4;
        color: #fff;
        border-color: #C4C4C4;
        cursor: context-menu;
        opacity: 1;
        &:active {
            background-color: #C4C4C4 !important;
            border-color: #C4C4C4 !important;
        }
        &:focus {
            box-shadow: none;
        }
    }
}

[data-theme='dark'] .btn {
    border-radius: 30px;
    min-width: 130px;
    &.green {
        background-color: #1D4370;
        color: #fff;
        border-color: #00427d;
        &:active {
            background-color: #00427d !important;
            border-color: #00427d !important;
        }
        &:focus {
            box-shadow: 0 0 0 0.2rem rgba(0, 78, 125, 0.5);
        }
    }
    &.red {
        background-color: #BF3E3E;
        color: #fff;
        border-color: #ab3737;
        &:active {
            background-color: #ab3737 !important;
            border-color: #ab3737 !important;
        }
        &:focus {
            box-shadow: 0 0 0 0.2rem rgba(171, 55, 55, 0.5);
        }
    }
    &.noButtonStyle {
        background: none;
        border: none;
        min-width: auto;
        padding: 0;
        &:focus {
            box-shadow: none !important;
        }
        &.active {
            background: none !important;
            border: none !important;
            &:focus {
                box-shadow: none !important;
            }
        }
    }
    &.noActive {
        background-color: #424257;
        color: #767684;
        border-color: #424257;
        cursor: context-menu;
        opacity: 1;
        &:active {
            background-color: #C4C4C4 !important;
            border-color: #C4C4C4 !important;
        }
        &:focus {
            box-shadow: none;
        }
    }
}