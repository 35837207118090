.showAppraisalGoal {
    display: flex;
    align-items: center;
    .CustomInfoBlockBox {
        display: flex;
    }
    .showAppraisalGoalRating {
        text-align: left;
        margin-right: 10px;
    }
}