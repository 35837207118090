@import './../../../scss/variableCustom.scss';
.questionYesNoPartlySummaryBox {
    display: flex;
    flex-flow: row;
    border-bottom: 1px solid #EDEDED;
    padding: 17px 20px 17px 20px;
    &:hover {
        background-color: #F1F6F5;
    }
    .questionYesNoPartlySummaryNumber {
        width: 30px;
        text-align: center;
        align-items: center;
        display: flex;
        color: #618299;
    }
    .questionYesNoPartlySummaryName {
        display: flex;
        flex-grow: 1;
        align-items: center;
        color: #618299;
    }
    .questionYesNoPartlySummaryAnswerBox {
        display: flex;
        flex-flow: row nowrap;
        color: #1D4370;
        span {
            font-weight: 600;
            margin-left: 5px;
            margin-right: 5px;
        }
    }
}

.questionYesNoPartlySummarySubstantiationBox {
    padding-left: 30px;
    padding-right: 10px;
    border-bottom: 1px solid #EDEDED;
    color: #555555;
    .summaryLabel {
        font-weight: 600;
    }
}