@import './../../../scss/variableCustom.scss';
.menuSlideBottom {
    display: flex;
    bottom: 0;
    min-height: 80px;
    width: 100%;
    max-width: 320px;
    flex-grow: 1;
    .wavesBox {
        display: flex;
        position: fixed;
        bottom: 0;
        left: 0;
        svg {
            g {
                path {
                    fill: $wavesColor;
                }
            }
        }
    }
    @media (min-width: 1385px) {
        .subIconBox {
            display: flex;
            position: fixed;
            bottom: 85px;
            left: 80px;
            max-width: 240px;
            justify-content: center;
            width: 12vw;
            svg {
                path {
                    fill: $subIconColor;
                }
            }
        }
        .subLogoBox {
            display: flex;
            width: 100%;
            bottom: 85px;
            left: 100px;
            align-items: flex-end;
            justify-content: center;
            flex-grow: 1;
            z-index: 0;
            padding-bottom: 32px;
            svg {
                path {
                    fill: $subLogoColor;
                    &.accent {
                        fill: $subLogoAccent;
                    }
                }
            }
        }
    }
    @media (max-width: 1385px) {
        .subIconBox {
            display: none;
        }
        .subLogoBox {
            display: none;
        }
    }
    .configBlock {
        display: flex;
        flex-flow: row nowrap;
        align-self: flex-end;
        width: 80px;
        min-height: 80px;
        background-color: $column;
        .configIcon {
            display: flex;
            justify-content: center;
            width: 80px;
            min-width: 80px;
            height: 80px;
            align-items: center;
            z-index: 1;
            &:hover {
                background-color: $bacgroundIconActive;
            }
            &.active {
                background-color: $bacgroundIconActive;
            }
            .icon {
                cursor: pointer;
                color: $fontColor;
            }
            .iconPath {
                color: $fontColor;
            }
        }
        .configBox {
            height: 80px;
            background-color: $configBackground;
            border-radius: 0px 10px 0px 0px;
            padding: 0 5px;
            z-index: 2;
            display: flex;
            flex-flow: row;
            .configBoxParam {
                display: flex;
                flex-flow: column;
                margin-top: 5px;
                margin-bottom: 5px;
                padding: 0 5px;
                p {
                    margin: 6px 0 6px 0;
                    text-align: center;
                    color: $configFontColor;
                    font-weight: 400;
                }
                .configBoxIconBox {
                    display: flex;
                    flex-flow: row nowrap;
                    align-content: center;
                    justify-content: space-evenly;
                    align-items: baseline;
                    .toggle-switch {
                        margin-top: 4px;
                    }
                }
                .configBoxIconBoxIcon {
                    cursor: pointer;
                    padding: 0;
                    path {
                        fill: $configIconColorLight;
                    }
                    &.active {
                        path {
                            fill: $configIconColorDark;
                        }
                    }
                }
                &.configBoxLine {
                    border-right: 1px solid $configBorder;
                }
            }
        }
    }
}

[data-theme='dark'] .menuSlideBottom {
    .subIconBox {
        svg {
            path {
                fill: $subIconColorDark;
            }
        }
    }
    .subLogoBox {
        svg {
            path {
                fill: $subLogoColorDark;
                &.accent {
                    fill: $subLogoAccentDark;
                }
            }
        }
    }
    .configBlock {
        background-color: #2C2D44;
        .configIcon {
            &:hover {
                background-color: #7E7E9B;
            }
            &.active {
                background-color: #7E7E9B;
            }
            .icon {
                color: #A0A0C2;
            }
            .iconPath {
                color: #A0A0C2;
            }
        }
        .configBox {
            background-color: #4D4E68;
            .configBoxParam {
                p {
                    color: #D6D6F1;
                }
                .configBoxIconBoxIcon {
                    cursor: pointer;
                    padding: 0;
                    path {
                        fill: #2F3145;
                    }
                    &.active {
                        path {
                            fill: #191B27;
                        }
                    }
                }
                &.configBoxLine {
                    border-right: 1px solid #37394A;
                }
            }
        }
    }
}