.customSliderBlock {
    padding: 0 20px 0 0px;
    .rc-slider {
        margin-top: 18px;
        position: relative;
        height: 10px;
        width: 100%;
        border-radius: 6px;
        background-color: #F1F6F5;
        box-sizing: border-box;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }
    .rc-slider * {
        box-sizing: border-box;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }
    .rc-slider-track {
        position: absolute;
        left: 0;
        height: 10px;
        border-radius: 6px;
        background-color: #FF8B02;
        z-index: 1;
    }
    .rc-slider-handle {
        position: absolute;
        margin-top: -4px;
        width: 17px;
        height: 17px;
        cursor: pointer;
        border-radius: 50%;
        border: solid 4px #fff;
        background-color: #C4C4C4;
        z-index: 2;
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
    }
    .rc-slider-handle:hover {
        border-color: #FF8B02;
    }
    .rc-slider-handle-active:active {
        border-color: #57c5f7;
        box-shadow: 0 0 5px #57c5f7;
    }
    .rc-slider-mark {
        position: absolute;
        top: 10px;
        left: 0;
        width: 100%;
        font-size: 12px;
        z-index: 3;
        span {
            transform: translateX(-50%) translateY(-32px) !important;
        }
        span:first-child {
            transform: translateX(-25%) translateY(-32px) !important;
        }
    }
    .rc-slider-mark-text {
        position: absolute;
        display: inline-block;
        vertical-align: middle;
        text-align: center;
        cursor: pointer;
        color: #999;
    }
    .rc-slider-mark-text-active {
        color: #555555;
    }
    .rc-slider-step {
        position: absolute;
        width: 100%;
        height: 10px;
        background: transparent;
        z-index: 1;
    }
    .rc-slider-dot {
        position: absolute;
        width: 0px;
        height: 0px;
        background: none;
        cursor: pointer;
    }
    .rc-slider-dot:first-child {
        margin-left: -4px;
    }
    .rc-slider-dot:last-child {
        margin-left: -4px;
    }
    .rc-slider-dot-active {
        border-color: blue;
    }
    .rc-slider-disabled {
        background-color: #e9e9e9;
    }
    .rc-slider-disabled .rc-slider-track {
        background: linear-gradient(0deg, #FF8B02, #FF8B02), #F1F6F5;
    }
    .rc-slider-disabled .rc-slider-handle,
    .rc-slider-disabled .rc-slider-dot {
        border-color: #ccc;
        background-color: #fff;
        cursor: not-allowed;
    }
    .rc-slider-disabled .rc-slider-mark-text,
    .rc-slider-disabled .rc-slider-dot {
        cursor: not-allowed !important;
    }
    .rc-slider-tooltip-zoom-down-enter,
    .rc-slider-tooltip-zoom-down-appear {
        -webkit-animation-duration: .3s;
        animation-duration: .3s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
        display: block !important;
        -webkit-animation-play-state: paused;
        animation-play-state: paused;
    }
    .rc-slider-tooltip-zoom-down-leave {
        -webkit-animation-duration: .3s;
        animation-duration: .3s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
        display: block !important;
        -webkit-animation-play-state: paused;
        animation-play-state: paused;
    }
    .rc-slider-tooltip-zoom-down-enter.rc-slider-tooltip-zoom-down-enter-active,
    .rc-slider-tooltip-zoom-down-appear.rc-slider-tooltip-zoom-down-appear-active {
        -webkit-animation-name: rcSliderTooltipZoomDownIn;
        animation-name: rcSliderTooltipZoomDownIn;
        -webkit-animation-play-state: running;
        animation-play-state: running;
    }
    .rc-slider-tooltip-zoom-down-leave.rc-slider-tooltip-zoom-down-leave-active {
        -webkit-animation-name: rcSliderTooltipZoomDownOut;
        animation-name: rcSliderTooltipZoomDownOut;
        -webkit-animation-play-state: running;
        animation-play-state: running;
    }
    .rc-slider-tooltip-zoom-down-enter,
    .rc-slider-tooltip-zoom-down-appear {
        -webkit-transform: scale(0, 0);
        transform: scale(0, 0);
        -webkit-animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
        animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
    }
    .rc-slider-tooltip-zoom-down-leave {
        -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    }
    @-webkit-keyframes rcSliderTooltipZoomDownIn {
        0% {
            opacity: 0;
            -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
            -webkit-transform: scale(0, 0);
            transform: scale(0, 0);
        }
        100% {
            -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
            -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
        }
    }
    @keyframes rcSliderTooltipZoomDownIn {
        0% {
            opacity: 0;
            -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
            -webkit-transform: scale(0, 0);
            transform: scale(0, 0);
        }
        100% {
            -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
            -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
        }
    }
    @-webkit-keyframes rcSliderTooltipZoomDownOut {
        0% {
            -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
            -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
        }
        100% {
            opacity: 0;
            -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
            -webkit-transform: scale(0, 0);
            transform: scale(0, 0);
        }
    }
    @keyframes rcSliderTooltipZoomDownOut {
        0% {
            -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
            -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
        }
        100% {
            opacity: 0;
            -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
            -webkit-transform: scale(0, 0);
            transform: scale(0, 0);
        }
    }
    .rc-tooltip {
        position: absolute;
        left: -9999px;
        top: -9999px;
        z-index: 4;
        visibility: visible;
        box-sizing: border-box;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }
    .rc-tooltip * {
        box-sizing: border-box;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }
    .rc-tooltip-hidden {
        display: none;
    }
    .rc-tooltip-placement-top {
        padding: 4px 0 8px 0;
    }
    .rc-tooltip-inner {
        padding: 6px 2px;
        min-width: 24px;
        height: 24px;
        font-size: 12px;
        line-height: 1;
        color: #fff;
        text-align: center;
        text-decoration: none;
        background-color: black;
        border-radius: 6px;
        box-shadow: 0 0 4px #d9d9d9;
    }
    .rc-tooltip-arrow {
        position: absolute;
        width: 0;
        height: 0;
        border-color: transparent;
        border-style: solid;
    }
    .rc-tooltip-placement-top .rc-tooltip-arrow {
        bottom: 4px;
        left: 50%;
        margin-left: -4px;
        border-width: 4px 4px 0;
        border-top-color: #6c6c6c;
    }
}

[data-theme='dark'] .customSliderBlock {
    .form-label {
        color: #fff;
    }
    .rc-slider {
        margin-top: 18px;
        position: relative;
        height: 10px;
        width: 100%;
        border-radius: 6px;
        background-color: #1E202D;
        box-sizing: border-box;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }
    .rc-slider * {
        box-sizing: border-box;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }
    .rc-slider-track {
        position: absolute;
        left: 0;
        height: 10px;
        border-radius: 6px;
        background-color: #FF8B02;
        z-index: 1;
    }
    .rc-slider-handle {
        position: absolute;
        margin-top: -4px;
        width: 17px;
        height: 17px;
        cursor: pointer;
        border-radius: 50%;
        border: solid 4px #fff;
        background-color: #C4C4C4;
        z-index: 2;
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
    }
    .rc-slider-handle:hover {
        border-color: #FF8B02;
    }
    .rc-slider-handle-active:active {
        border-color: #57c5f7;
        box-shadow: 0 0 5px #57c5f7;
    }
    .rc-slider-mark {
        position: absolute;
        top: 10px;
        left: 0;
        width: 100%;
        font-size: 12px;
        z-index: 3;
        span {
            transform: translateX(-50%) translateY(-32px) !important;
        }
        span:first-child {
            transform: translateX(-25%) translateY(-32px) !important;
        }
    }
    .rc-slider-mark-text {
        position: absolute;
        display: inline-block;
        vertical-align: middle;
        text-align: center;
        cursor: pointer;
        color: #999;
    }
    .rc-slider-mark-text-active {
        color: #555555;
    }
    .rc-slider-step {
        position: absolute;
        width: 100%;
        height: 10px;
        background: transparent;
        z-index: 1;
    }
    .rc-slider-dot {
        position: absolute;
        width: 0px;
        height: 0px;
        background: none;
        cursor: pointer;
    }
    .rc-slider-dot:first-child {
        margin-left: -4px;
    }
    .rc-slider-dot:last-child {
        margin-left: -4px;
    }
    .rc-slider-dot-active {
        border-color: blue;
    }
    .rc-slider-disabled {
        background-color: #e9e9e9;
    }
    .rc-slider-disabled .rc-slider-track {
        background: linear-gradient(0deg, #FF8B02, #FF8B02), #F1F6F5;
    }
    .rc-slider-disabled .rc-slider-handle,
    .rc-slider-disabled .rc-slider-dot {
        border-color: #ccc;
        background-color: #fff;
        cursor: not-allowed;
    }
    .rc-slider-disabled .rc-slider-mark-text,
    .rc-slider-disabled .rc-slider-dot {
        cursor: not-allowed !important;
    }
    .rc-slider-tooltip-zoom-down-enter,
    .rc-slider-tooltip-zoom-down-appear {
        -webkit-animation-duration: .3s;
        animation-duration: .3s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
        display: block !important;
        -webkit-animation-play-state: paused;
        animation-play-state: paused;
    }
    .rc-slider-tooltip-zoom-down-leave {
        -webkit-animation-duration: .3s;
        animation-duration: .3s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
        display: block !important;
        -webkit-animation-play-state: paused;
        animation-play-state: paused;
    }
    .rc-slider-tooltip-zoom-down-enter.rc-slider-tooltip-zoom-down-enter-active,
    .rc-slider-tooltip-zoom-down-appear.rc-slider-tooltip-zoom-down-appear-active {
        -webkit-animation-name: rcSliderTooltipZoomDownIn;
        animation-name: rcSliderTooltipZoomDownIn;
        -webkit-animation-play-state: running;
        animation-play-state: running;
    }
    .rc-slider-tooltip-zoom-down-leave.rc-slider-tooltip-zoom-down-leave-active {
        -webkit-animation-name: rcSliderTooltipZoomDownOut;
        animation-name: rcSliderTooltipZoomDownOut;
        -webkit-animation-play-state: running;
        animation-play-state: running;
    }
    .rc-slider-tooltip-zoom-down-enter,
    .rc-slider-tooltip-zoom-down-appear {
        -webkit-transform: scale(0, 0);
        transform: scale(0, 0);
        -webkit-animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
        animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
    }
    .rc-slider-tooltip-zoom-down-leave {
        -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    }
    @-webkit-keyframes rcSliderTooltipZoomDownIn {
        0% {
            opacity: 0;
            -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
            -webkit-transform: scale(0, 0);
            transform: scale(0, 0);
        }
        100% {
            -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
            -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
        }
    }
    @keyframes rcSliderTooltipZoomDownIn {
        0% {
            opacity: 0;
            -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
            -webkit-transform: scale(0, 0);
            transform: scale(0, 0);
        }
        100% {
            -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
            -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
        }
    }
    @-webkit-keyframes rcSliderTooltipZoomDownOut {
        0% {
            -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
            -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
        }
        100% {
            opacity: 0;
            -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
            -webkit-transform: scale(0, 0);
            transform: scale(0, 0);
        }
    }
    @keyframes rcSliderTooltipZoomDownOut {
        0% {
            -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
            -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
        }
        100% {
            opacity: 0;
            -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
            -webkit-transform: scale(0, 0);
            transform: scale(0, 0);
        }
    }
    .rc-tooltip {
        position: absolute;
        left: -9999px;
        top: -9999px;
        z-index: 4;
        visibility: visible;
        box-sizing: border-box;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }
    .rc-tooltip * {
        box-sizing: border-box;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }
    .rc-tooltip-hidden {
        display: none;
    }
    .rc-tooltip-placement-top {
        padding: 4px 0 8px 0;
    }
    .rc-tooltip-inner {
        padding: 6px 2px;
        min-width: 24px;
        height: 24px;
        font-size: 12px;
        line-height: 1;
        color: #fff;
        text-align: center;
        text-decoration: none;
        background-color: black;
        border-radius: 6px;
        box-shadow: 0 0 4px #d9d9d9;
    }
    .rc-tooltip-arrow {
        position: absolute;
        width: 0;
        height: 0;
        border-color: transparent;
        border-style: solid;
    }
    .rc-tooltip-placement-top .rc-tooltip-arrow {
        bottom: 4px;
        left: 50%;
        margin-left: -4px;
        border-width: 4px 4px 0;
        border-top-color: #6c6c6c;
    }
}