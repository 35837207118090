.showName {
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    margin-top: 0.75rem;
    .label {
        width: 100%;
        color: #1D4370;
        font-weight: 600;
    }
    .data {
        padding-left: 0.625rem;
        width: 100%;
        font-size: 1.5rem;
        color: #1D4370;
    }
}

[data-theme='dark'] .showName {
    .label {
        width: 100%;
        color: #A0A0C2;
    }
    .data {
        color: #1D4370;
    }
}