@import './../../../../scss/variableCustom.scss';
.tableCellTrainingsStatusChange {
    display: flex;
    justify-content: center;
    &.toLeft {
        justify-content: flex-start;
    }
    &.center {
        justify-content: center;
    }
    &.toRight {
        justify-content: flex-end;
    }
    .status {
        margin: 0 2px;
        display: flex;
        flex-flow: row wrap;
        align-items: flex-end;
        .labelStatus {
            width: 100%;
            display: flex;
            flex-flow: row nowrap;
            color: #555;
            font-size: 0.625rem;
            justify-content: center;
            margin-bottom: 3px;
            text-align: center;
            flex-grow: 1;
            align-items: center;
        }
        .boxStatus {
            width: 100%;
            display: flex;
            flex-flow: row nowrap;
            justify-content: center;
            padding-bottom: 4px;
            .circleBox {
                display: flex;
                width: 32px;
                height: 32px;
                border-radius: 16px;
                justify-content: center;
                align-items: center;
                margin: 0 2px;
                margin: auto;
                &.accepted {
                    background-color: #1D4370;
                    opacity: 0.2;
                    &.selected {
                        opacity: 1;
                    }
                    &:hover {
                        opacity: 1;
                    }
                }
                &.rejected {
                    background-color: #BF3E3E;
                    opacity: 0.2;
                    &.selected {
                        opacity: 1;
                    }
                    &:hover {
                        opacity: 1;
                    }
                }
                &.verify {
                    background-color: #C4C4C4;
                    opacity: 0.3;
                    &.selected {
                        opacity: 1;
                    }
                    &:hover {
                        opacity: 1;
                    }
                }
                &.NONE {
                    background-color: none;
                }
                path {
                    fill: #fff;
                }
            }
        }
    }
}