.splashScreenRoot {
    height: 100vh;
    display: flex;
    background: radial-gradient(43.49% 86.3% at 50% 50.05%, #93B7E1 2.3%, #1D4370 100%);
    .splashScreenBox {
        display: flex;
        align-items: center;
        justify-content: center;
        transition: opacity ease-in-out 2000ms;
        height: 100%;
        width: 100%;
        & g {
            path {
                fill: #1D4370;
            }
            opacity: 0;
            animation: fadeInAndOut 3.5s infinite ease-in-out;
            &:nth-child(2) {
                animation-Delay: .4s
            }
            &:nth-child(3) {
                animation-Delay: .8s
            }
            &:nth-child(4) {
                animation-Delay: .12s
            }
            @keyframes fadeInAndOut {
                60%,
                100% {
                    opacity: 0;
                }
                0% {
                    opacity: 0;
                }
                40% {
                    opacity: 1;
                }
            }
        }
        .svg {
            fill: green;
            height: '50%',
        }
    }
}

[data-theme='dark'] .splashScreenRoot {
    background: radial-gradient(43.49% 86.3% at 50% 50.05%, #3C3E6C 2.3%, #121421 100%);
    .splashScreenBox {
        & g {
            path {
                fill: #fff;
            }
        }
    }
}