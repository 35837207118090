#formIDPEmployeeArea {
    #formIDPEmployeeAreaBox {
        #boxContent-formIDPEmployeeAreaBox {
            padding: 0;
            #formIDPEmployeeAreaFirstRow {
                display: flex;
                flex-flow: row;
                padding: 10px 20px;
                border-bottom: 1px solid #DCDCDC;
                #formIDPEmployeeAreaFirstRowIcon {
                    margin-right: 20px;
                    justify-content: center;
                    align-items: center;
                    display: flex;
                }
                #formIDPEmployeeAreaFirstRowData {
                    width: 100%;
                }
            }
            #formIDPEmployeeAreaBlock {
                padding: 20px 20px 0px;
                #formIDPEmployeeAreaBlockLabel {
                    width: 100%;
                    font-size: 1.125rem;
                    color: #074B32;
                    margin-bottom: 10px;
                }
                .formIDPEmployeeAreaBlockContent {
                    display: flex;
                    flex-flow: row nowrap;
                    margin-bottom: 15px;
                    border-bottom: 1px solid #DCDCDC;
                    &.noMarginBottom {
                        margin-bottom: 0;
                        border-bottom: none;
                    }
                    .formIDPEmployeeAreaBlockPerecent {
                        width: 120px;
                        height: 120px;
                        background-color: #FF8B02;
                        margin: 0 10px 10px;
                        border-radius: 10px;
                        padding: 10px;
                        .CustomAnimatedPrograssBar {
                            svg {
                                .CircularProgressbar-trail {
                                    stroke: #FF8B02;
                                }
                                .CircularProgressbar-path {
                                    stroke: #ffa841;
                                }
                                .CircularProgressbar-text {
                                    fill: #fff;
                                }
                            }
                        }
                        &#formIDPEmployeeAreaBlock20Percent {
                            background-color: #00965E;
                            .CustomAnimatedPrograssBar {
                                svg {
                                    .CircularProgressbar-trail {
                                        stroke: #00965E;
                                    }
                                    .CircularProgressbar-path {
                                        stroke: #40b086;
                                    }
                                    .CircularProgressbar-text {
                                        fill: #fff;
                                    }
                                }
                            }
                        }
                        &#formIDPEmployeeAreaBlock10Percent {
                            background-color: #99D6BF;
                            .CustomAnimatedPrograssBar {
                                svg {
                                    .CircularProgressbar-trail {
                                        stroke: #99D6BF;
                                    }
                                    .CircularProgressbar-path {
                                        stroke: #b3e0cf;
                                    }
                                    .CircularProgressbar-text {
                                        fill: #fff;
                                    }
                                }
                            }
                        }
                    }
                    .formIDPEmployeeAreaBlockName {
                        width: 220px;
                        margin-right: 15px;
                    }
                    .formIDPEmployeeAreaBlockAdditional {
                        display: flex;
                        flex-flow: column;
                        margin-left: 15px;
                        .formIDPEmployeeAreaBlockAdditionalDateAndMeasure {
                            display: flex;
                            flex-flow: row;
                        }
                        .formIDPEmployeeAreaBlockAdditionalProgressData {
                            padding: 0 20px 0 0;
                        }
                        .formIDPEmployeeAreaBlockAdditionalProgress {
                            .customSliderBlock {
                                padding: 0 40px 0 0;
                            }
                            &#formIDPEmployeeAreaBlock20AdditionalProgress {
                                .customSliderBlock {
                                    .blockInputWithInfo {
                                        .rc-slider {
                                            .rc-slider-track {
                                                background-color: #00965E;
                                            }
                                            .rc-slider-handle {
                                                &:hover {
                                                    border-color: #00965E;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            &#formIDPEmployeeAreaBlock10AdditionalProgress {
                                .customSliderBlock {
                                    .blockInputWithInfo {
                                        .rc-slider {
                                            .rc-slider-track {
                                                background-color: #99D6BF;
                                            }
                                            .rc-slider-handle {
                                                &:hover {
                                                    border-color: #99D6BF;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .formIDPEmployeeAreaBlockComment {
                        width: 200px;
                    }
                }
            }
        }
    }
}

[data-theme='dark'] #formIDPEmployeeArea {
    #formIDPEmployeeAreaBox {
        #boxContent-formIDPEmployeeAreaBox {
            #formIDPEmployeeAreaFirstRow {
                border-bottom: 1px solid #3D3E58;
                #formIDPEmployeeAreaFirstRowIcon {
                    svg {
                        path {
                            fill: #d6d6f1;
                        }
                    }
                }
            }
            #formIDPEmployeeAreaBlock {
                #formIDPEmployeeAreaBlockLabel {
                    color: #A0A0C2;
                }
                .formIDPEmployeeAreaBlockContent {
                    border-bottom: 1px solid #3D3E58;
                    .formIDPEmployeeAreaBlockPerecent {
                        background-color: #FF8B02;
                        .CustomAnimatedPrograssBar {
                            svg {
                                .CircularProgressbar-trail {
                                    stroke: #FF8B02;
                                }
                                .CircularProgressbar-path {
                                    stroke: #ffa841;
                                }
                                .CircularProgressbar-text {
                                    fill: #fff;
                                }
                            }
                        }
                        &#formIDPEmployeeAreaBlock20Percent {
                            background-color: #00965E;
                            .CustomAnimatedPrograssBar {
                                svg {
                                    .CircularProgressbar-trail {
                                        stroke: #00965E;
                                    }
                                    .CircularProgressbar-path {
                                        stroke: #40b086;
                                    }
                                    .CircularProgressbar-text {
                                        fill: #fff;
                                    }
                                }
                            }
                        }
                        &#formIDPEmployeeAreaBlock10Percent {
                            background-color: #99D6BF;
                            .CustomAnimatedPrograssBar {
                                svg {
                                    .CircularProgressbar-trail {
                                        stroke: #99D6BF;
                                    }
                                    .CircularProgressbar-path {
                                        stroke: #b3e0cf;
                                    }
                                    .CircularProgressbar-text {
                                        fill: #fff;
                                    }
                                }
                            }
                        }
                    }
                    .formIDPEmployeeAreaBlockAdditional {
                        display: flex;
                        flex-flow: column;
                        margin-left: 15px;
                        .formIDPEmployeeAreaBlockAdditionalDateAndMeasure {
                            display: flex;
                            flex-flow: row;
                        }
                        .formIDPEmployeeAreaBlockAdditionalProgressData {
                            padding: 0 20px 0 0;
                        }
                        .formIDPEmployeeAreaBlockAdditionalProgress {
                            .customSliderBlock {
                                padding: 0 40px 0 0;
                            }
                            &#formIDPEmployeeAreaBlock20AdditionalProgress {
                                .customSliderBlock {
                                    .blockInputWithInfo {
                                        .rc-slider {
                                            .rc-slider-track {
                                                background-color: #00965E;
                                            }
                                            .rc-slider-handle {
                                                &:hover {
                                                    border-color: #00965E;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            &#formIDPEmployeeAreaBlock10AdditionalProgress {
                                .customSliderBlock {
                                    .blockInputWithInfo {
                                        .rc-slider {
                                            .rc-slider-track {
                                                background-color: #99D6BF;
                                            }
                                            .rc-slider-handle {
                                                &:hover {
                                                    border-color: #99D6BF;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .formIDPEmployeeAreaBlockComment {
                        .formIDPEmployeeAreaBlockCommentLabel {
                            color: #fff;
                        }
                    }
                }
            }
        }
    }
}