#trainingsGroupBox {
    .boxContent {
        #sendEmailNotifications {
            padding: 10px 20px;
            display: flex;
            flex-flow: row wrap;
            #sendBox {
                display: flex;
                flex-flow: row;
                max-width: 370px;
                background-color: #d3ece3;
                border: 1px solid #bee1d4;
                padding: 14px 10px;
                border-radius: 10px;
                align-items: center;
                margin-right: 20px;
                #sendBoxIcon {
                    margin-right: 20px;
                }
                #sendBoxInfo {
                    margin-right: 10px;
                    color: #1D4370;
                }
                #sendBoxAction {
                    justify-content: center;
                    button {
                        min-width: 108px;
                    }
                }
            }
        }
        #lastSendEmailNotyfication {
            display: flex;
            flex-flow: column;
            justify-content: space-between;
            #lastSendEmailNotyficationDataBox {
                display: flex;
                flex-flow: row;
                #lastSendEmailNotyficationData {
                    font-size: 1.5rem;
                    color: #000;
                    align-items: center;
                    margin-left: 20px;
                    display: flex;
                }
            }
        }
    }
}

[data-theme='dark'] #trainingsGroupBox {
    .boxContent {
        #lastSendEmailNotyfication {
            #lastSendEmailNotyficationDataBox {
                color: #A0A0C2;
                #lastSendEmailNotyficationIcon {
                    svg {
                        path {
                            fill: #A0A0C2;
                        }
                    }
                }
                #lastSendEmailNotyficationData {
                    color: #49BACA;
                }
            }
        }
    }
}