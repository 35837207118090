#FormCatalogsTrainingsBasicInformation {
    padding: 0 10px;
    .subTitleTable {
        color: #1D4370;
        margin-bottom: 0.625rem;
    }
}

[data-theme='dark'] #FormCatalogsTrainingsBasicInformation {
    padding: 0 10px;
    .subTitleTable {
        color: #A0A0C2;
        margin-bottom: 0.625rem;
    }
}