#formLoyaltyContainer {
    #loyaltyTableBox {
        #formLoyaltyHeaderContent {
            padding-bottom: 10px;
            padding-top: 10px;
            div {
                width: 100%;
                &.titleEmployee {
                    color: #555;
                }
                &.valueEmployee {
                    color: #FF8B02;
                    font-size: 1.5rem;
                }
            }
        }
        #formLoyalty {
            #formLoyaltyFirstContent {
                display: flex;
                flex-flow: row wrap;
                width: 100%;
                padding-bottom: 10px;
                padding-top: 15px;
                .loyaltyDateBox {
                    display: flex;
                    flex-flow: row wrap;
                }
                .loyaltyEmployeeDataBox {
                    display: flex;
                    flex-flow: row wrap;
                    flex-grow: 1;
                    justify-content: flex-end;
                    .idCardBox {
                        .blockInputWithError {
                            .blockInput {
                                padding: 0;
                            }
                        }
                    }
                    .financingAmountBox {
                        display: flex;
                        flex-flow: row;
                        .blockNumberWithError {
                            .blockNumber {
                                padding: 0;
                            }
                        }
                        span {
                            margin-left: 4px;
                            font-weight: 400;
                            color: #555555;
                            align-items: flex-end;
                            display: flex;
                            padding-bottom: 8px;
                        }
                    }
                }
            }
            #formLoyaltySecondContent {
                display: flex;
                flex-flow: row;
                .editorBlock {
                    width: 100%;
                }
            }
        }
    }
}

[data-theme='dark'] #formLoyaltyContainer {
    #loyaltyTableBox {
        #formLoyaltyHeaderContent {
            div {
                width: 100%;
                &.titleEmployee {
                    color: #A0A0C2;
                }
                &.valueEmployee {
                    color: #FF8B02;
                }
            }
        }
        #formLoyalty {
            #formLoyaltyFirstContent {
                .loyaltyEmployeeDataBox {
                    .financingAmountBox {
                        span {
                            color: #A0A0C2;
                        }
                    }
                }
            }
        }
    }
}