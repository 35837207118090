#formEmployeeAppraisalFeedbackBox {
    #formEmployeeAppraisalFeedback {
        #formEmployeeAppraisalFeedbackFirstRow {
            #formEmployeeAppraisalFeedbackFirstRowBox {
                display: flex;
                flex-flow: row nowrap;
                padding: 12px 15px 0;
                #formEmployeeAppraisalFeedbackFirstRowBoxIcon {
                    margin-right: 20px;
                    justify-content: center;
                    align-items: center;
                    display: flex;
                }
                #formEmployeeAppraisalFeedbackFirstRowBoxInfo {
                    #formEmployeeAppraisalFeedbackFirstRowBoxInfoLabel {
                        font-size: 1.125rem;
                        font-weight: 700;
                        margin-bottom: 6px
                    }
                }
            }
        }
        #IDPFeedbackBox {
            #boxContent-IDPFeedbackBox {
                display: flex;
                .feedbackBoxItem {
                    max-width: 350px;
                    display: flex;
                    flex-flow: column;
                    background: #FFFFFF;
                    border: 1px solid #93B7E1;
                    box-shadow: 0px 0px 8px #C3CDDB;
                    border-radius: 0px 0px 10px 10px;
                    margin: 10px 20px;
                    min-width: 350px;
                    &:hover {
                        .boxAccent {
                            background-color: #FF8B02;
                        }
                    }
                    .boxAccent {
                        height: 5px;
                        border-radius: 0 0 10px 10px;
                        background-color: #93B7E1;
                    }
                    .boxTitleBox {
                        padding: 5px 20px 10px;
                        border-bottom: 1px solid #DCDCDC;
                        min-height: 83px;
                        .boxTitle {
                            font-size: 1.5rem;
                            font-weight: 700;
                            color: #1D4370;
                        }
                        .boxSubTitle {
                            color: #618299;
                        }
                    }
                    .boxForm {
                        .editorBlock {
                            margin: 10px;
                            label {
                                color: #1D4370;
                                font-weight: 600;
                            }
                        }
                    }
                }
            }
        }
    }
}

[data-theme='dark'] #formEmployeeAppraisalFeedbackBox {
    #formEmployeeAppraisalFeedback {
        #formEmployeeAppraisalFeedbackFirstRow {
            #formEmployeeAppraisalFeedbackFirstRowBox {
                color: #A0A0C2;
                #formEmployeeAppraisalFeedbackFirstRowBoxIcon {
                    svg {
                        path {
                            fill: #A0A0C2;
                        }
                    }
                }
            }
        }
        #IDPFeedbackBox {
            #boxContent-IDPFeedbackBox {
                .feedbackBoxItem {
                    background: #2c2d44;
                    border: 1px solid #13151e;
                    box-shadow: 0px 0px 8px #13151e;
                    &:hover {
                        .boxAccent {
                            background-color: #FF8B02;
                        }
                    }
                    .boxAccent {
                        background-color: #A0A0C2;
                    }
                    .boxTitleBox {
                        border-bottom: 1px solid #494B6D;
                        .boxTitle {
                            color: #fff;
                        }
                        .boxSubTitle {
                            color: #A0A0C2;
                        }
                    }
                    .boxForm {
                        .editorBlock {
                            label {
                                color: #fff;
                            }
                        }
                    }
                }
            }
        }
    }
}