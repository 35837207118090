.multiSelectBlock {
    &.withLabel {
        margin-right: calc(0.65rem + 18px);
        margin-top: 5px;
        margin-bottom: 5px;
        .form-label {
            color: #1D4370;
        }
    }
    .blockInputWithInfo {
        .multiSelect {
            .multiSelect__control {
                background: #FFFFFF;
                border: 1px solid #93B7E1;
                box-sizing: border-box;
                border-radius: 5px;
                min-height: 30px;
                &:hover {
                    border: 1px solid #93B7E1;
                    box-shadow: 0px 0px 8px #CBD4DF;
                }
                &.multiSelect__control--is-focused {
                    border: 1px solid #7c96be !important;
                    box-shadow: 0px 0px 8px #CBD4DF;
                    .multiSelect__indicators {
                        .multiSelect__indicator {
                            svg {
                                path {
                                    fill: #7c96be;
                                }
                            }
                        }
                    }
                }
                .multiSelect__value-container {
                    padding: 0px 8px;
                    .multiSelect__multi-value {
                        background-color: #FFFFFF;
                        border: 1px solid #93B7E1;
                        border-radius: 5px;
                        margin: 0 2px;
                        .multiSelect__multi-value__label {
                            color: #555;
                            font-size: 0.875rem;
                            padding: 1px 7px;
                        }
                        .multiSelect__multi-value__remove {
                            svg {
                                fill: #93B7E1;
                            }
                            &:hover {
                                background-color: #7CBEA5;
                                svg {
                                    fill: #000;
                                }
                            }
                        }
                    }
                }
                .multiSelect__indicators {
                    .multiSelect__indicator-separator {
                        background-color: #93B7E1;
                    }
                    .multiSelect__dropdown-indicator {
                        padding: 4px;
                        svg {
                            fill: #93B7E1;
                        }
                    }
                    .multiSelect__clear-indicator {
                        padding: 4px;
                        svg {
                            fill: #93B7E1;
                        }
                    }
                }
            }
        }
    }
}

[data-theme='dark'] .multiSelectBlock {
    &.withLabel {
        .form-label {
            color: #fff;
        }
    }
    .blockInputWithInfo {
        .multiSelect {
            .multiSelect__control {
                background: #2C2D44;
                border: 1px solid #6A6A91;
                &:hover {
                    border: 1px solid #9A9CD4;
                    box-shadow: 0px 0px 8px rgba(255, 255, 255, 0.75);
                }
                &.multiSelect__control--is-focused {
                    border: 1px solid #CBCCE5 !important;
                    box-shadow: 0px 0px 8px rgba(255, 255, 255, 0.75);
                    .multiSelect__indicators {
                        .multiSelect__indicator {
                            svg {
                                path {
                                    fill: #CBCCE5;
                                }
                            }
                        }
                        .multiSelect__indicator-separator {
                            background-color: #CBCCE5;
                        }
                        .multiSelect__clear-indicator {
                            svg {
                                fill: #CBCCE5;
                            }
                        }
                    }
                }
                .multiSelect__value-container {
                    .multiSelect__multi-value {
                        background-color: #36374F;
                        border: 1px solid #A0A0C2;
                        .multiSelect__multi-value__label {
                            color: #fff;
                        }
                        .multiSelect__multi-value__remove {
                            svg {
                                fill: #A0A0C2;
                            }
                            &:hover {
                                background-color: #4c4d6d;
                                svg {
                                    fill: #CBCCE5;
                                }
                            }
                        }
                        .multiSelect__placeholder {
                            color: #A0A0C2;
                        }
                    }
                }
                .multiSelect__indicators {
                    .multiSelect__indicator-separator {
                        background-color: #A0A0C2;
                    }
                    .multiSelect__dropdown-indicator {
                        padding: 4px;
                        svg {
                            fill: #A0A0C2;
                        }
                    }
                    .multiSelect__clear-indicator {
                        svg {
                            fill: #A0A0C2;
                        }
                    }
                }
            }
            .multiSelect__menu {
                background-color: #36374F;
                .multiSelect__menu-list {
                    .multiSelect__option {
                        color: #fff;
                        &.multiSelect__option--is-focused {
                            background-color: #1E202D;
                        }
                        &.multiSelect__option--is-selected {
                            background-color: #AF5555 !important;
                        }
                    }
                }
            }
        }
    }
}