@import './../../../../scss/variableCustom.scss';
.tableCellInputBox {
    display: flex;
    .form-control {
        text-align: center;
        border-radius: 5px;
        border: 1px solid #93B7E1;
        color: #555555;
        &:hover {
            border: 1px solid #93B7E1;
            box-shadow: 0px 0px 8px #CBD4DF;
        }
        &:focus {
            border: 1px solid #7c96be;
            box-shadow: 0px 0px 8px #CBD4DF;
        }
        &:disabled {
            background: none;
            border: none;
        }
        &:focus-visible {
            text-shadow: none;
        }
    }
    &.toLeft {
        justify-content: flex-start;
    }
    &.center {
        justify-content: center;
    }
    &.toRight {
        justify-content: flex-end;
    }
}

[data-theme='dark'] .tableCellInputBox {
    display: flex;
    .form-control {
        text-align: center;
        background-color: #2C2D44;
        color: #A0A0C2;
        border-color: #6A6A91;
        &:hover {
            border: 1px solid #6A6A90;
            box-shadow: 0px 0px 8px rgba(255, 255, 255, 0.25);
        }
        &:focus {
            color: #C2C2E2;
            border: 1px solid #8787AC;
            box-shadow: 0px 0px 8px rgba(255, 255, 255, 0.25);
        }
    }
    &.toLeft {
        justify-content: flex-start;
    }
    &.center {
        justify-content: center;
    }
    &.toRight {
        justify-content: flex-end;
    }
}