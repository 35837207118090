$fontBold: 600;
//$accent: #af5555;
$accent: #ff8b02;
$accentDark: #AF5555;
//LEFT MENU
$background: #6182AA;
$backgroundDark: #2c2d44;
$column: #1D4370;
$columnDark: #121421;
$logoIconBox: #000;
$logoIconBoxDark: #000;
$iconMenuActiveBackground: #fff;
$iconMenuActiveBackgroundDark: #30314c;
$iconMenuActiveColor: #1D4370;
$iconMenuActiveColorDark: #fff;
$menuItem: #fff;
$menuItemDark: $menuItem;
$menuActiveBackground: #ecf6f3;
$menuActiveBackgroundDark: #202331;
$menuActiveColor: #6182AA;
$menuActiveColorDark: #fff;
$menuActiveAccentColor: $accent;
$menuActiveAccentColorDark: $accentDark;
// $configIconActiveBackground: #64b5f6;
// $configIconActiveBackgroundDark: #7e7e9b;
$configBackground: #3167a8;
$configBackgroundDark: #4d4e68;
$configFontColor: #1D4370;
$configFontColorDark: #d6d6f1;
$configIconColorLight: #044087;
$configIconColorLightDark: #2f3145;
$configIconColorDark: #1D4370;
$configIconColorDarkDark: #191b27;
$configBorder: #044087;
$configBorderDark: #37394a;
$pin: #B8D8FF;
$pinDark: #9596a1;
$fontColor: #0d47a1;
$fontColorDark: #fff;
$bacgroundIconActive: #6182AA;
$bacgroundIconActiveDark: #7e7e9b;
$wavesColor: rgba(255, 255, 255, 0.1);
$wavesColorDark: rgba(77, 79, 112, 0.3);
$subIconColor: rgba(255, 255, 255, 0.2);
$subIconColorDark: rgba(77, 79, 112, 0.5);
$subLogoColor: #fff;
$subLogoColorDark: #fff;
$subLogoAccent: #f97474;
$subLogoAccentDark: $accentDark;
// ENDLEFT MENU
//BLOCK
$blockBackgroundColor: #ecf6f3;
$blockBackgroundColorDark: #202331;
$blockTextColor: #1D4370;
$blockTextColorDark: #fff;
////TOPMENU
$topMenuBackgroundColor: #6182AA;
$topMenuBackgroundColorDark: #30314c;
$topMenuColor: #93B7E1;
$topMenuColorDark: #49BACA;
$topMenuColorActive: #1D4370;
$topMenuColorActiveDark: #fff;
$topMenuItemActiveBackground: $menuActiveBackground;
$topMenuItemActiveBackgroundDark: $menuActiveBackgroundDark;
$topMenuAccent: $accent;
$topMenuAccentDark: $accentDark;
$userMenuBackgroundColor: #1D4370;
$userMenuBackgroundColorDark: rgba(255, 255, 255, 0.07);
$userMenuTextColor: #fff;
$userMenuTextColorDark: #a0a0c2;
$logoutMenuBackgroundColor: #000;
$logoutMenuBackgroundColorDark: $logoutMenuBackgroundColor;
$logoutMenuTextColor: #fff;
$logoutMenuTextColorDark: $logoutMenuTextColor;
////ENDTOPMENU
//BREADCRUMB
$topBreadcrumbColor: #1D4370;
$topBreadcrumbColorDark: #fff;
$topBreadcrumbColorActive: $accent;
$topBreadcrumbColorActiveDark: $accentDark;
//END BREADCRUMB
//footer
$footerBackgroundColor: #ecf6f3;
$footerBackgroundColorDark: #202331;
$footerTextColor: #6182AA;
$footerTextColorDark: #6b6b83;
//END BLOCK
//COMPONENT
//Switch
$switchLabelColour: #bbb;
$switchDisabledColour: #ddd;
$switchToggleColour: #36639b;
$switchWhite: #fff;
$switchFocusColor: #ff0;
$switchColorNo: #fff;
$switchColorNoDark: #fff;
$switchBackgroudColorNo: #bf3e3e;
$switchBackgroundColorNoDark: #bf3e3e;
$switchColorYes: #fff;
$switchColorYesDark: #fff;
$switchBackgroundColorYes: $configIconColorDark;
$switchBackgroundColorYesDark: $configIconColorDarkDark;
$switchToogleSwitchColor: $configIconColorLight;
$switchToogleSwitchColorDark: $configIconColorLightDark;
//END SWITCH
//END COMPONENT