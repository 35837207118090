#ShowIDPEmployeeBox {
    .boxContent {
        #IDPEmployeeTabs {
            margin-bottom: 0;
            margin-top: 9px;
        }
        #IDPEmployeeContentBox {
            box-shadow: 0px -12px 20px rgba(7, 75, 50, 0.15);
        }
    }
    #IDPEmployeeContentBoxEmpty {
        padding: 20px;
        font-size: 1rem;
        text-align: center;
    }
}

[data-theme='dark'] #ShowIDPEmployeeBox {
    .boxContent {
        #IDPEmployeeContentBox {
            box-shadow: 0px -12px 20px rgba(18, 20, 33, 0.15);
        }
        #IDPEmployeeContentBoxEmpty {
            color: #A0A0C2;
        }
    }
}