@import './../../../../scss/variableCustom.scss';
.actionInFolder {
    .buttonAction {
        display: flex;
        background-color: #C3CDDB;
        width: 32px;
        height: 32px;
        border-radius: 16px;
        justify-content: center;
        align-items: center;
        margin: 0 2px;
        path {
            fill: #FFFFFF;
        }
        &:hover {
            background-color: #1D4370;
            path {
                fill: #FFFFFF;
            }
        }
    }
}

[data-theme='dark'] .actionInFolder {
    .buttonAction {
        background-color: #4d4e68;
        path {
            fill: #d6d6f1;
        }
        &:hover {
            background-color: #7e7e9b;
            path {
                fill: #FFFFFF;
            }
        }
    }
}