.QuestionnaireListItem {
    border: 1px solid #93B7E1;
    box-sizing: border-box;
    box-shadow: 0px 0px 8px #C3CDDB;
    border-radius: 0px 0px 10px 10px;
    display: flex;
    flex-flow: column;
    margin: 0.625rem 0.375rem;
    width: 280px;
    min-height: 280px;
    background-color: #fff;
    &:hover {
        .accent {
            background-color: #93B7E1;
        }
    }
    .accent {
        width: 100%;
        height: 5px;
        border-radius: 0 0 10px 10px;
        background-color: #FF8B02;
    }
    .name {
        margin: 5px 10px;
        text-align: center;
        color: #1D4370;
        font-weight: 600;
        font-size: 1rem;
        flex-grow: 1;
    }
    .datesTraining {
        border: 1px solid #C3CDDB;
        border-radius: 20px;
        margin: 0 15px;
        display: flex;
        flex-flow: row nowrap;
        .icon {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 30%;
            svg {
                margin: 13px 10px;
            }
        }
        .dates {
            display: flex;
            flex-flow: column;
            width: 70%;
            align-items: flex-start;
            div {
                width: 100%;
                padding-left: 6px;
                color: #1D4370;
            }
            .startDateBox {
                margin-top: 11px;
                .startDate {
                    margin-left: 13px;
                    font-size: 1.5rem;
                    color: #7EA1CA;
                }
            }
            .endDateBox {
                .endDate {
                    margin-left: 13px;
                    font-size: 1.5rem;
                    color: #000;
                }
            }
        }
        .singleDate {
            display: flex;
            flex-flow: column;
            width: 70%;
            align-items: flex-start;
            div {
                width: 100%;
                padding-left: 6px;
                color: #1D4370;
            }
            .singleDateBox {
                margin-top: 26px;
                margin-left: 13px;
                font-size: 1.5rem;
                color: #7EA1CA;
            }
        }
    }
    .action {
        margin: 80px 15px 15px;
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        .buttonFill {
            display: flex;
            flex-flow: column;
        }
    }
}

[data-theme='dark'] .QuestionnaireListItem {
    border: 1px solid #13151e;
    box-shadow: 0px 0px 8px #13151e;
    background-color: #2c2d44;
    &:hover {
        .accent {
            background-color: #49BACA;
        }
    }
    .accent {
        background-color: #FF8B02;
    }
    .name {
        color: #fff;
    }
    .datesTraining {
        border: 1px solid #494B6D;
        .icon {
            svg {
                path {
                    fill: #d6d6f1;
                    opacity: 0.5;
                }
            }
        }
        .dates {
            div {
                color: #49BACA;
            }
            .startDateBox {
                .startDate {
                    color: #49BACA;
                }
            }
            .endDateBox {
                .endDate {
                    color: #316e7b;
                }
            }
        }
        .singleDate {
            div {
                color: #49BACA;
            }
            .singleDateBox {
                color: #49BACA;
            }
        }
    }
}