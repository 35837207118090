@import './../../../../scss/variableCustom.scss';
.tableCellYesNoContent {
    display: flex;
    flex-flow: row;
    align-items: center;
    &.toRight {
        justify-content: flex-end;
    }
    &.center {
        justify-content: center;
    }
    &.toLeft {
        justify-content: flex-start;
    }
    .tableCellYesNo {
        font-weight: 600;
        text-transform: uppercase;
        &.yesValue {
            color: #1D4370;
        }
        &.noValue {
            color: #BF3E3E;
        }
    }
    .customHelpBlock {
        margin-left: 5px;
    }
}

[data-theme='dark'] .tableCellYesNoContent {
    .tableCellYesNo {
        &.yesValue {
            color: #1D4370;
        }
        &.noValue {
            color: #BF3E3E;
        }
    }
    .customHelpBlock {
        svg {
            path {
                fill: #9a9cd4;
            }
        }
    }
}