#IDPEmployeeAreaBox {
    #IDPEmployeeAreaBoxFirstRow {
        #IDPEmployeeAreaBoxFirstRowBox {
            display: flex;
            flex-flow: row nowrap;
            padding: 12px 15px 0;
            #IDPEmployeeAreaBoxFirstRowBoxIcon {
                margin-right: 20px;
                justify-content: center;
                align-items: center;
                display: flex;
                svg {
                    width: 60px !important;
                    height: 60px !important;
                    path {
                        fill: #7FA3D0;
                    }
                }
            }
            #IDPEmployeeAreaBoxFirstRowBoxInfo {
                #IDPEmployeeAreaBoxFirstRowBoxInfoLabel {
                    font-size: 1.125rem;
                    font-weight: 700;
                    margin-bottom: 6px
                }
            }
        }
    }
    #IDPEmployeeAreaBoxSecondRow {
        padding: 20px 10px;
        #IDPEmployeeAreaAddArea {
            margin-right: 5px;
        }
    }
}

[data-theme='dark'] #IDPEmployeeAreaBox {
    #IDPEmployeeAreaBoxFirstRow {
        #IDPEmployeeAreaBoxFirstRowBox {
            #IDPEmployeeAreaBoxFirstRowBoxIcon {
                svg {
                    path {
                        fill: #A0A0C2;
                    }
                }
            }
            #IDPEmployeeAreaBoxFirstRowBoxInfo {
                color: #A0A0C2;
            }
        }
    }
    #IDPEmployeeAreaBoxSecondRow {
        padding: 20px 10px;
        #IDPEmployeeAreaAddArea {
            margin-right: 5px;
        }
    }
}

#approveIDP {
    text-align: center;
    padding: 20px 0;
}