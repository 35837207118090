#formGoalRatingFinal {
    #formGoalRatingFinalBox {
        .boxContent {
            padding: 0 0 0 0;
            #formGoalRatingFinalEmployee {
                color: #FF8B02;
                font-size: 1.5rem;
                margin-bottom: 5px;
                padding: 0 0 0 20px;
            }
            #formGoalRatingFinalSelfAppraisal {
                padding: 10px 20px;
                border-bottom: 1px solid #DCDCDC;
                font-weight: 600;
                span {
                    font-weight: 400;
                }
                #formGoalRatingFinalSelfAppraisalComment {
                    display: flex;
                    flex-flow: row wrap;
                    font-weight: 600;
                    div {
                        font-weight: 400;
                        width: 100%;
                    }
                }
            }
            #formGoalRatingFinalSuperiorAppraisal {
                padding: 10px 20px;
                border-bottom: 1px solid #DCDCDC;
                font-weight: 600;
                span {
                    font-weight: 400;
                }
                #formGoalRatingFinalSelfAppraisalComment {
                    display: flex;
                    flex-flow: row wrap;
                    font-weight: 600;
                    div {
                        width: 100%;
                        font-weight: 400;
                    }
                }
            }
            #formGoalRatingFinalAppraisal {
                padding: 10px 20px;
            }
        }
    }
}

[data-theme='dark'] #formGoalRatingFinal {
    #formGoalRatingFinalBox {
        .boxContent {
            #formGoalRatingFinalEmployee {
                color: #FF8B02;
            }
            #formGoalRatingFinalSelfAppraisal {
                border-bottom: 1px solid #494B6D;
                color: #A0A0C2;
            }
            #formGoalRatingFinalSuperiorAppraisal {
                border-bottom: 1px solid #494B6D;
                color: #A0A0C2;
            }
            #formGoalRatingFinalAppraisal {
                color: #A0A0C2;
            }
        }
    }
}